import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../auth/auth.service";
import { OrganizationService } from "../../shared/services/organization.service";
import { ToastService } from "../../shared/services/toast.service";
import { ConfirmationService, SelectItem } from "primeng/primeng";
import { TableUtilityService } from '../../shared/services/table-utility.service';
import { CookieService } from 'ngx-cookie';

import { Organization } from "../../shared/models/organization.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {
  organizations: Organization[] = [];
  isAnim: boolean = true;

   /** Column Toggling */
   columnOptions: SelectItem[];
   selectedCols: any[];              // - Contains the table columns that should be visible
   allSelectableCols: any[] = [];    // - Contains all table columns that may be toggled on or off
 
   selectedColsDefault: any[] = [];  // - Contains the columns that are shown by default if no cookie is stored
 
  constructor(
    public authService: AuthService,
    private organizationService: OrganizationService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cookieService: CookieService,    
    private tableUtilService: TableUtilityService
  ) { }

  ngOnInit() {
    this.onGetOrganizations();    
    this.initColumnOptions();

    // Listen to changes made to organizations
    this.organizationService.typesChanged.subscribe(
      () => {
        this.onGetOrganizations();
      }
    );
  }

  initColumnOptions() {
    this.allSelectableCols = this.tableUtilService.getAllSelectableOrganizationCols();
    this.selectedColsDefault = this.tableUtilService.getSelectedOrganizationColsDefault();
    this.columnOptions = this.tableUtilService.getColumnOptions(this.columnOptions, this.allSelectableCols);

    let selectedColsCookie = this.cookieService.getObject("crm_selectedOrganizationCols") as any[];
    if (selectedColsCookie)
      this.selectedCols = selectedColsCookie;
    else
      this.selectedCols = this.selectedColsDefault;
  }

  // - Saves options in a cookie whenever they are changed
  onColumnOptionsChange() {
    this.cookieService.putObject("crm_selectedOrganizationCols", this.selectedCols);
  }

  isColVisible(colName: string) {
    if (this.selectedCols.find(sC => sC.value === colName))
      return true;

    return false;
  }

  onGetOrganizations() {
    this.organizationService.getOrganizations().subscribe(
      (organizations: Organization[]) => {
        this.organizations = organizations;
        console.log(this.organizations);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving organizations", error);
      }
    );
  }

  onDeleteOrganization(organization: Organization) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the organization ' + organization.name + '?',
      accept: () => {
        organization.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.organizationService.deleteOrganization(organization).subscribe(
          (response: Response) => {
            this.onGetOrganizations();
            this.toastService.createSuccessMessage("Success", "The organization " + organization.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting organization", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for organization " + organization.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

}

