import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";

import { OpportunityFilesComponent } from './opportunity-files.component';
import { OpportunityFileListComponent } from './opportunity-file-list/opportunity-file-list.component';
import { OpportunityFileManageComponent } from './opportunity-file-manage/opportunity-file-manage.component';

const opportunityFilesRoutes: Routes = [
  {
    path: 'opportunities',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id/files', component: OpportunityFilesComponent,
        children: [
          {
            path: '', component: OpportunityFileListComponent,
            children: [
              { path: ':id/edit', component: OpportunityFileManageComponent }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(opportunityFilesRoutes)],
  exports: [RouterModule]
})
export class OpportunityFilesRoutingModule { }
