import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms/src/model';

@Injectable()
export class FormUtilityService {

  constructor() { }

  /**
   * Iterates through each control of the passed form and marks them as touched
   * to trigger form validation.
   */
  markFormControlsAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  markFormControlAsTouched(control: FormControl) {
      control.markAsTouched({ onlySelf: true });
      return control;
  }

  /**
   * Iterates through each control of the passed form and marks them as untouched.
   */
  markFormControlsAsUntouched(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsUntouched({ onlySelf: true });
      control.markAsPristine({ onlySelf: true });
    });
  }

  markFormControlAsUntouched(control: FormControl) {
    // Object.keys(form.controls).forEach(field => {
      //const control = form.get(field);
      control.markAsUntouched({ onlySelf: true });
      control.markAsPristine({ onlySelf: true });
      return control;
    // });
  }

  /**
   * Trims string values and catches empty string values and converts them to null.
   */
  sanitizeFormValues(form: FormGroup): FormGroup {
    for (var property in form.value) {
      if (typeof form.value[property] === "string") {
        form.value[property] = form.value[property].trim(); // - Always trim string values

        if (form.value[property] === "")
          form.value[property] = null; // - Set value to null if value is empty after trimming
      }
    }
    return form;
  }

}
