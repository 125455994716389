import { TableUtilityService } from './../../shared/services/table-utility.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Response, URLSearchParams } from "@angular/http";

import { AuthService } from "../../auth/auth.service";
import { OpportunityService } from "../../shared/services/opportunity.service";
import { ConfirmationService } from "primeng/components/common/confirmationservice";
import { ToastService } from "../../shared/services/toast.service";
import { UserService } from '../../shared/services/user.service';

import { CookieService } from 'ngx-cookie';
import { Opportunity, LandPurchaseOpportunity } from "../../shared/models/opportunity.model";
import { SelectItem, LazyLoadEvent, DataTable } from "primeng/primeng";


import { forkJoin } from 'rxjs/observable/forkJoin';
import { OpportunitiesSegment } from '../../shared/models/opportunities-segment.model';
import { OpportunityTypeService } from '../../shared/services/opportunity-type.service';
import { HttpParams } from '@angular/common/http';
import { ContactService } from 'app/views/shared/services/contact.service';
import { Contact } from 'app/views/shared/models/contact.model';
import { AllOpportunityCategoryTypesQueryGQL } from 'app/graphql/opportunity-category-type/opportunity-category-types.graphql';
import { map } from 'rxjs/operators';
import { OpportunityCategoryType } from 'app/views/shared/models/opportunity-category-type/opportunity-category-type.model';
import { combineLatest, of } from 'rxjs';
import { BaseComponent } from 'app/views/base/base.component';
import { DeleteLandPurchaseOpportunityMutation } from 'app/graphql/land-purchase-opportunity/land-purchase-opportunity-delete.mutation';
import { SystemComponent } from 'app/views/base/system.component';
import { SystemModeHelper, SYSTEM_KEYS } from 'app/views/shared/system-config.service';

@Component({
  selector: 'app-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styleUrls: ['./opportunity-list.component.scss']
})
export class OpportunityListComponent extends SystemComponent implements OnInit, OnDestroy {
  public SYSTEM_KEYS : SYSTEM_KEYS = SYSTEM_KEYS;

  opportunities: Opportunity[] = [];

  contactsOptions: SelectItem[] = [];           // - Stores Contact Dropdown Options
  selectedContactOption: any;

  opportunityTypesOptions: SelectItem[] = [];   // - Stores Opp. Type Dropdown Options
  selectedOpportunityTypeOption: any;

  opportunityCategoryTypeOptions: SelectItem[] = []; // - Stores Opp. Category Type Dropdown Options
  selectedOpportunityCategoryTypeOption: any;

  probabilityFilter: number;

  exportFileName: string = "PotenzaCRM-Opportunities-Export-" + new Date().toLocaleDateString("en-GB");

    //** DatePicker */
    todayDate: Date = new Date();
    yearRange: string = this.todayDate.getFullYear() - 10 + ':' + (this.todayDate.getFullYear() + 10); // - 20 Year Range

    /** Lazy Loading */
    totalRecords: number = 0;
    latestLazyLoadEvent: LazyLoadEvent;

  /** Column Toggling */
  columnOptions: SelectItem[];
  selectedCols: any[];              // - Contains the table columns that should be visible
  allSelectableCols: any[] = [];    // - Contains all table columns that may be toggled on or off

  selectedColsDefault: any[] = [];  // - Contains the columns that are shown by default if no cookie is stored

  /** Advanced Options **/

  userOptions: SelectItem[] = [];
  selectedAssignedToOption: any;
  selectedCreatedByOption: any;

  selectedContactName: any;
  selectedCreatedOnSince: any;
  selectedCreatedOnBefore: any;
  selectedName:any;
  selectedDescription:any;
  selectedProbability:any;
  selectedProbabilityMin: any;
  selectedProbabilityMax: any;
  selectedValue:any;

  isAdvancedSearchCollapsed: boolean = false;
  allOppContacts: any;

  constructor(
    public authService: AuthService,
    private opportunityService: OpportunityService,
    private opportunityTypeService: OpportunityTypeService,
    private contactsService: ContactService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cookieService: CookieService,
    private tableUtilService: TableUtilityService,
    private userService: UserService,

    private _allOppCategoryTypes: AllOpportunityCategoryTypesQueryGQL,
    private _deleteLandPurchaseOpportunityMutation: DeleteLandPurchaseOpportunityMutation,
    protected systemModeHelper : SystemModeHelper
  )
  {
    super(systemModeHelper);
  }

  ngOnInit() {
    super.ngOnInit();
    this.populateSearchOptions();
    //this.onGetOpportunities();
    this.initColumnOptions();
    this.ngOnInitDropDown();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInitDropDown(): void {
    let oppCategoryTypes$ =
      this._allOppCategoryTypes
      .watch()
      .valueChanges
      .pipe(
        map(result => {
          const item = (<any>result).data;
          return item ? (<any>item).opportunityCategoryTypes.data as OpportunityCategoryType[] : [];
        })
      );

    let oppContacts$ = this.contactsService.getOpportunityContacts();

    this.subscriptions$.push(
      combineLatest(oppCategoryTypes$, oppContacts$)
      .pipe(
        map((items: any[]) => {
        debugger;

          return {
            categoryTypes: items[0] as OpportunityCategoryType[],
            contacts: items[1] as Contact[]
          }
        })
      )
      .subscribe(result => {
        //populate category types
        this.populateCategoryTypeDropdowns(result.categoryTypes);

        //populate contacts
        this.populeContactDropdowns(result.contacts);
      })
    );

    // this.contactsService.getOpportunityContacts().subscribe(
    //   (contacts: Contact[]) => {
    //     this.contactsOptions = [];

    //     this.allOppContacts = JSON.parse(JSON.stringify(contacts));

    //     this.contactsOptions.push({ label: "All Contacts", value: null });
    //     contacts.map(c => {
    //       this.contactsOptions.push({ label: c.fullName, value: c.fullName });
    //     });
    //   }
    // );
  }

  private populateCategoryTypeDropdowns(categoryTypes: OpportunityCategoryType[]): void {
    //populate category types
    this.opportunityCategoryTypeOptions = [];
    this.opportunityCategoryTypeOptions.push({ label: "All Category Types", value: null });

    categoryTypes.map(c => {
      this.opportunityCategoryTypeOptions.push({ label: c.name, value: c.id });
    });

    this.selectedOpportunityCategoryTypeOption = null;
  }

  private populeContactDropdowns(contacts: Contact[]): void {
    this.contactsOptions = [];
    this.allOppContacts = JSON.parse(JSON.stringify(contacts));

    this.contactsOptions.push({ label: "All Contacts", value: null });
    contacts.map(c => {
      this.contactsOptions.push({ label: c.fullName, value: c.fullName });
    });

    this.selectedContactOption = null;
  }

  initColumnOptions() {
    this.allSelectableCols = this.tableUtilService.getAllSelectableOpportunityCols();
    this.selectedColsDefault = this.tableUtilService.getSelectedOpportunityColsDefault();
    this.columnOptions = this.tableUtilService.getColumnOptions(this.columnOptions, this.allSelectableCols);

    let selectedColsCookie = this.cookieService.getObject("crm_selectedOpportunityCols") as any[];
    if (selectedColsCookie)
      this.selectedCols = selectedColsCookie;
    else
      this.selectedCols = this.selectedColsDefault;
  }

  // - Saves options in a cookie whenever they are changed
  onColumnOptionsChange() {
    this.cookieService.putObject("crm_selectedOpportunityCols", this.selectedCols);
  }

  onSearchOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    dt.filter($event ? $event.value : null, field, matchMode);
  }
  // onContactOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
  //   this.selectedContactName = $event.value;
  //   this.onSearchOptionChange(dt, $event, field, matchMode);
  // }
  // onProbabilityMinOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
  //   this.selectedProbabilityMin = $event.target.value;
  //   this.onSearchOptionChange(dt, $event, field, matchMode);
  // }
  // onProbabilityMaxOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
  //   this.selectedProbabilityMax = $event.target.value;
  //   this.onSearchOptionChange(dt, $event, field, matchMode);
  // }

  onContactOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedContactName = $event.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }

  onProbabilityMinOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedProbabilityMin = $event.target.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }

  onProbabilityMaxOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedProbabilityMax = $event.target.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }

  onResetSearch(dt: DataTable) {
    this.selectedAssignedToOption = null;
    this.selectedCreatedByOption = null;
    this.selectedContactName = null;
    this.selectedCreatedOnSince = null;
    this.selectedCreatedOnBefore = null;

    this.selectedName=null;
    this.selectedDescription=null;
    // this.selectedProbability=null;
    this.selectedValue=null;

    this.selectedProbabilityMin = null;
    this.selectedProbabilityMax = null;

    dt.filters = {};

    dt.filter(null, null, null);
  }

  loadData(event: LazyLoadEvent) {
    debugger;
    this.latestLazyLoadEvent = event;

    let opportunityTypeId = this.selectedOpportunityTypeOption;
    let opportunityCategoryTypeId = this.selectedOpportunityCategoryTypeOption;
    let assignedToId = this.selectedAssignedToOption;
    let createdById = this.selectedCreatedByOption;


    let params = new HttpParams();
    params = params.set("from", event.first.toString());
    params = params.set("to", event.rows.toString());
    params = params.set("sortBy", event.sortField);
    if(event.sortOrder)
    params = params.set("order", event.sortOrder.toString());
    if(event.globalFilter)
    params = params.set("searchTerm", event.globalFilter);

    if(assignedToId)
    params = params.set("assignedToId", assignedToId);

    if(createdById)
    params = params.set("createdById", createdById);

    if(opportunityTypeId) {
    params = params.set("opportunityTypeId", opportunityTypeId);
    }
    if(opportunityCategoryTypeId) {
      params = params.set("opportunityCategoryTypeId", opportunityCategoryTypeId);
    }
    if(this.selectedContactName) {
    params = params.set("fullName", this.selectedContactName ? this.selectedContactName.trim() : null);
    }
    // params.set("lastName", this.selectedContactFirstName ? this.selectedContactFirstName.trim() : this.selectedContactFirstName);

    // params.set("fullName", this.selectedContactLastName ? this.selectedContactLastName.trim() : this.selectedContactLastName);


    if(this.selectedName) {
    params = params.set("name", this.selectedName ? this.selectedName.trim() : null);
    }
    if(this.selectedDescription) {
    params = params.set("description", this.selectedDescription ? this.selectedDescription.trim() : null);
    }

    if(this.selectedProbability) {
      params = params.set("probability", this.selectedProbability ? this.selectedProbability.trim() : null);
    }

    if (this.selectedProbabilityMin) {
      params = params.set("probabilityMin", this.selectedProbabilityMin ? this.selectedProbabilityMin.trim() : null);
    }
    if (this.selectedProbabilityMax) {
      params = params.set("probabilityMax", this.selectedProbabilityMax ? this.selectedProbabilityMax.trim() : null);
    }

    if(this.selectedValue) {
    params = params.set("value", this.selectedValue ? this.selectedValue.trim() : null);
    }

    if(this.selectedCreatedOnSince) {
    params = params.set("createdOnSince", this.selectedCreatedOnSince ? this.selectedCreatedOnSince : null);
    }
    if(this.selectedCreatedOnBefore) {
    params = params.set("createdOnBefore", this.selectedCreatedOnBefore ? this.selectedCreatedOnBefore : null);
    }

    this.onGetOpportunities(params);
  }

  populateSearchOptions() {
    let opportunityTypes = this.opportunityTypeService.getOpportunityTypes();
    let users = this.userService.getUsers();

    forkJoin( opportunityTypes, users).subscribe(
      ([opportunityTypes, users ]) => {

        this.opportunityTypesOptions.push({ label: "All Types", value: null });
        opportunityTypes.map(cT => {
          this.opportunityTypesOptions.push({ label: cT.name, value: cT.id });
        });

        this.userOptions.push({ label: "All Agents", value: null }); // todo: Unassigned option?
        users.map(u => {
          this.userOptions.push({ label: u.fullName, value: u.id });
        });

        this.selectedOpportunityTypeOption = null;
        this.selectedOpportunityCategoryTypeOption = null;
        this.selectedAssignedToOption = null;
        this.selectedCreatedByOption = null;
      }
    );

  }

  isColVisible(colName: string) {
    if (this.selectedCols.find(sC => sC.value === colName))
      return true;

    return false;
  }

  onGetOpportunities(params: HttpParams) {

    this.opportunityService.getOpportunitiesSegment(params, false).subscribe(
      (opportunitiesSegment: OpportunitiesSegment) => {

        console.log("opportunitiesSegment ---> ", opportunitiesSegment);

        this.opportunities = opportunitiesSegment.opportunities;

        console.log("this.opportunities ====> ", this.opportunities);
        this.totalRecords = opportunitiesSegment.totalOpportunityCount;

        this.populateContactsOptions(opportunitiesSegment.opportunities);
        //this.populateOpportunityTypesOptions(opportunitiesSegment.opportunities);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunities", error);
      }
    );
  }

  populateContactsOptions(opportunities: Opportunity[]) {
    // this.contactsOptions = [];

    // this.contactsOptions.push({ label: "All Contacts", value: null });
    // opportunities.map(o => {
    //   // If Opp. has an Assigned Contact and Contact has not already been added
    //   if (o.assignedContact && !this.contactsOptions.find(c => c.value === o.assignedContact.fullName))
    //     this.contactsOptions.push({ label: o.assignedContact.fullName, value: o.assignedContact.fullName });
    // });

    // this.contactsOptions.push({ label: "All Contacts", value: null });
    // opportunities.map(o => {
    //   // If Opp. has an Assigned Contact and Contact has not already been added
    //   if (o.assignedContact && !this.contactsOptions.find(c => c.value === o.assignedContact.fullName))
    //     this.contactsOptions.push({ label: o.assignedContact.fullName, value: o.assignedContact.fullName });
    // });

    // this.selectedContactOption = null;
    // this.selectedContactOption = null;
  }

  populateOpportunityTypesOptions(opportunities: Opportunity[]) {
    this.opportunityTypesOptions.push({ label: "All Types", value: null });
    opportunities.map(o => {
      if (!this.opportunityTypesOptions.find(oT => oT.value === o.opportunityType.name))
        this.opportunityTypesOptions.push({ label: o.opportunityType.name, value: o.opportunityType.name });
    });
    this.selectedOpportunityTypeOption = null;
  }

  onDeleteOpportunity(opportunity: Opportunity, oppTypeName: string) {
    debugger;

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the opportunity ' + opportunity.name + '?',
      accept: () => {
        opportunity.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        switch(oppTypeName) {
          case "Sales Opportunity":
            this.deleteOpportunity(opportunity);
          break;

          case "Land Purchases Opportunity":
            this.deleteLandPurchaseOpportunity(opportunity.id);
          break;
        };

      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for opportunity " + opportunity.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

  private deleteOpportunity(opportunity: Opportunity): void {
    this.opportunityService.deleteOpportunity(opportunity).subscribe(
      (response: Response) => {
        //this.onGetOpportunities();
        this.loadData(this.latestLazyLoadEvent);
        this.toastService.createSuccessMessage("Success", "The opportunity " + opportunity.name + " has been deleted.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error deleting opportunity", error);
      }
    );
  }

  private deleteLandPurchaseOpportunity(id: number): void {
    const MUT_VARS = {
      id: id
    };

    let mutation$ = this._deleteLandPurchaseOpportunityMutation.mutate(MUT_VARS);

    this.subscriptions$.push(
      mutation$
      .pipe(
        map(result => {
          const item = (<any>result).data;
          return item ? (<any>item).deleteLandPurchaseOpportunity.data as LandPurchaseOpportunity : new LandPurchaseOpportunity();
        })
      )
      .subscribe((deletedPurchaseOpp: LandPurchaseOpportunity) => {
        this.loadData(this.latestLazyLoadEvent);

        this.toastService.createSuccessMessage("Success", `The land purchase opportunity ${deletedPurchaseOpp.code} has been deleted.`);
      })
    );
  }
}
