import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { ComplaintTypesComponent } from "../complaint-types/complaint-types.component";
import { ComplaintTypeListComponent } from "../complaint-types/complaint-type-list/complaint-type-list.component";
import { ComplaintTypeManageComponent } from "../complaint-types/complaint-type-manage/complaint-type-manage.component";

const typesRoutes: Routes = [
    {
        path: 'complaints',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'types', component: ComplaintTypesComponent,
                children: [
                    {
                        path: '', component: ComplaintTypeListComponent,
                        children: [
                            { path: ':id/edit', component: ComplaintTypeManageComponent, canActivate: [RoleGuard] },
                            { path: 'new', component: ComplaintTypeManageComponent, canActivate: [RoleGuard] }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(typesRoutes)],
    exports: [RouterModule]
})
export class ComplaintTypesRoutingModule { }