import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { ContactService } from '../../shared/services/contact.service';
import { SmsWarriorsService } from '../../shared/services/sms-warriors.service';
import { MessageTemplateService } from '../../shared/services/message-template.service';
import { ToastService } from '../../shared/services/toast.service';

import { ConfirmationService } from 'primeng/primeng';

import { MessageTemplate } from 'app/views/shared/models/message-template.model';

@Component({
  selector: 'app-message-centre-templates',
  templateUrl: './message-centre-templates.component.html',
  styleUrls: ['./message-centre-templates.component.scss']
})
export class MessageCentreTemplatesComponent implements OnInit {
  templates: MessageTemplate[] = [];
  
  constructor(
    public authService: AuthService,
    private messageTemplateService: MessageTemplateService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetMessageTemplates();

    // Listen to changes made to templates
    this.messageTemplateService.messageTemplatesChanged.subscribe(
      () => {
        this.onGetMessageTemplates();
      }
    );
  }

  onGetMessageTemplates() {
    this.messageTemplateService.getMessageTemplates().subscribe(
      (templates: MessageTemplate[]) => {
        this.templates = templates;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving templates", error);
      }
    );
  }

  onDeleteMessageTemplate(messageTemplate: MessageTemplate) {
    this.confirmationService.confirm({
      header: 'Delete Template',
      message: 'Are you sure you want to delete the template ' + messageTemplate.name + '?',
      accept: () => {
        messageTemplate.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.messageTemplateService.deleteMessageTemplate(messageTemplate).subscribe(
          (response: Response) => {
            this.onGetMessageTemplates();
            this.toastService.createSuccessMessage("Success", "The template " + messageTemplate.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting template", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for template " + messageTemplate.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

}
