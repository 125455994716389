import { User } from "oidc-client";
import { Profile, AppUserRole } from 'app/shared/profile/_models/profile.model';

export class CrmUser extends Profile {
  public fullName: string = this.firstName + ' ' + this.lastName;
    constructor(
      public subjectId: string = null,
        public id: number = null,
        public profileExists: boolean = null,
        public appUserRoles: AppUserRole[] = [], 
        public applicationUserId: string = null,
         firstName: string = null,
         lastName: string = null,
        
        public email: string = null,
        public roles: string[] = [],
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null
    ) { 
      super(subjectId, id, '', firstName, lastName, email, '', profileExists, '', appUserRoles)
    }
}