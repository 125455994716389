import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { ContactSavedSearchesRoutingModule } from "./contact-saved-searches-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { ContactSavedSearchesComponent } from './contact-saved-searches.component';
import { ContactSavedSearchListComponent } from './contact-saved-search-list/contact-saved-search-list.component';
import { ContactSavedSearchManageComponent } from './contact-saved-search-manage/contact-saved-search-manage.component';

@NgModule({
  declarations: [
    ContactSavedSearchesComponent,
    ContactSavedSearchListComponent,
    ContactSavedSearchManageComponent
  ],
  imports: [
    SharedModule,
    ContactSavedSearchesRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class ContactSavedSearchesModule { }
