import { CrmUser } from "../user.model";

export class LeadDeskConfig {
  constructor(
      public id: number = 0,
      public apiKey: string = null,
      public createdByUserAccount: CrmUser = null,
      public createdByUserAccountId: number= null,
      public createdOn: string = null,
      public updatedByUserAccount: CrmUser = null,
      public updatedByUserAccountId: number = null,
      public updatedOn: string = null,
      public status: string = null
  ) { }
}

export class CallingList {
  constructor(
      public id: number = 0,
      public name: string = null,
      public created_at: string = null
  ) { }
}