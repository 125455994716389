import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})

export class DealTypeOpportunitiesQueryGQL extends Query {
  document = DEAL_TYPE_OPPORTUNITIES;
}

const DEAL_TYPE_OPPORTUNITIES =
gql`
  query ($filters: DealTypeOpportunityListFilterInput) {
    dealTypeOpportunities (filters: $filters) {
      data {
        id
        name
      }
    }
  }
`;
