import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";
import { RoleGuard } from "../auth/role-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { OrganizationsComponent } from "../organizations/organizations.component";
import { OrganizationListComponent } from "../organizations/organization-list/organization-list.component";
import { OrganizationManageComponent } from "../organizations/organization-manage/organization-manage.component";

const organizationsRoutes: Routes = [
    {
        path: '',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'organizations', component: OrganizationsComponent,
            children: [
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: 'list', component: OrganizationListComponent },
              { path: ':id/edit', component: OrganizationManageComponent },
              { path: 'new', component: OrganizationManageComponent }
            ]
          }
        ]
      }
];

@NgModule({
    imports: [RouterModule.forChild(organizationsRoutes)],
    exports: [RouterModule]
})
export class OrganizationsRoutingModule { }