import { FormBuilder, FormGroup } from "@angular/forms";

export class CustomFormBuilder extends FormBuilder {
    //** Trims string values and catches empty string values and converts them to null
    public sanitizeFormValues(form: FormGroup): FormGroup {
        for (var property in form.value) {
            if (typeof form.value[property] === "string") {
                form.value[property] = form.value[property].trim(); // - Always trim string values

                if (form.value[property] === "")
                    form.value[property] = null; // - Set value to null if value is empty after trimming
            }
        }
        return form;
    }
}