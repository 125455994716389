import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../../auth/auth.service";
import { PaymentTermService } from "../../../shared/services/payment-term.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { PaymentTerm } from "../../../shared/models/payment-term.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-payment-term-list',
  templateUrl: './payment-term-list.component.html',
  styleUrls: ['./payment-term-list.component.scss']
})
export class PaymentTermListComponent implements OnInit {
  paymentTerms: PaymentTerm[] = [];
  isAnim: boolean = true;

  constructor(
    public authService: AuthService,
    private paymentTermService: PaymentTermService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetPaymentTerms();

    // Listen to changes made to paymentTerms
    this.paymentTermService.typesChanged.subscribe(
      () => {
        this.onGetPaymentTerms();
      }
    );
  }

  onGetPaymentTerms() {
    this.paymentTermService.getPaymentTerms().subscribe(
      (paymentTerms: PaymentTerm[]) => {
        this.paymentTerms = paymentTerms;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving Payment Terms", error);
      }
    );
  }

  onDeletePaymentTerm(paymentTerm: PaymentTerm) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the Payment Term ' + paymentTerm.name + '?',
      accept: () => {
        paymentTerm.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.paymentTermService.deletePaymentTerm(paymentTerm).subscribe(
          (response: Response) => {
            this.onGetPaymentTerms();
            this.toastService.createSuccessMessage("Success", "The Payment Term " + paymentTerm.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting Payment Term", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for Payment Term " + paymentTerm.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
