import { CrmUser } from "./user.model";

export class SavedContactSearch {
    constructor(
        public id: number = 0,
        public name: string = null,

        public contactTypeId: number = null,
        public contactSourceId: number = null,
        public assignedToId: number = null,
        public createdById: number = null,

        public firstName: string = null,
        public lastName: string = null,
        public fullName: string = firstName + ' ' + lastName,
        public companyName: string = null,
        public email: string = null,
        public phoneNumber: string = null,
        public idCard: string = null,
        public vat: string = null,

        public createdOnSince: string = null,
        public createdOnBefore: string = null,

        public contactTagIds: string = null,
        public contactGroupIds: string = null,

        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null
    ) { }
}