import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager, PrepopulatedEntities } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { OrganizationService } from '../../shared/services/organization.service';

import { Organization } from '../../shared/models/organization.model';

import { CustomValidators } from 'ng2-validation';
import { SelectItem, ConfirmationService, AutoComplete } from "primeng/primeng";
import { Observable } from "rxjs/Rx";
import { ContactService } from '../../shared/services/contact.service';
import { Contact } from '../../shared/models/contact.model';
import { ContactSearchItem } from 'app/views/shared/models/contact-search-item.model';
import { PaymentTermService } from 'app/views/shared/services/payment-term.service';
import { PaymentTerm } from 'app/views/shared/models/payment-term.model';


@Component({
  selector: 'app-organization-manage',
  templateUrl: './organization-manage.component.html',
  styleUrls: ['./organization-manage.component.scss']
})
export class OrganizationManageComponent implements OnInit {

  organizationForm: FormGroup;                         // - Stores the organization form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update a organization if true and add a new organization if false

  organizations: Organization[] = [];             // - Stores all organizations retrieved from db to validate unique values
  organization: Organization = new Organization();// - Stores Organization to populate form with a new / existing organization's values
  defaultContactOptions: SelectItem[] = [];      // - Stores Contacts to select the Assigned Contact of this opportunity
  paymentTermOptions: SelectItem[] = [];     // - Stores Complaint Types

  paymentTermId: number = 0;

  constructor(
    public authService: AuthService,
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private organizationService: OrganizationService,
    public paymentTermService: PaymentTermService,
    private confirmationService: ConfirmationService,
    private contactService: ContactService,
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  initForm() {
    this.organizationForm = this.formBuilder.group({
      name: this.formBuilder.control(this.organization.name),
      email: this.formBuilder.control(this.organization.email, [Validators.required]),
      status: this.formBuilder.control(this.organization.status === AuditManager.StatusActive || !this.organization.status ? true : false),
      mobilePhoneNumber: this.formBuilder.control(this.organization.mobilePhoneNumber, [Validators.required]),
      officePhoneNumber: this.formBuilder.control(this.organization.officePhoneNumber),
      addressLine1: this.formBuilder.control(this.organization.addressLine1),
      addressLine2: this.formBuilder.control(this.organization.addressLine2),
      addressLine3: this.formBuilder.control(this.organization.addressLine3),
      addressLine4: this.formBuilder.control(this.organization.addressLine4),
      defaultContactId: this.formBuilder.control(this.organization.defaultContactId),
      paymentTermId: this.formBuilder.control(this.organization.paymentTermId),
      });

    this.onGetPaymentTerms();
    this.onFormValueChanges();


    if (this.isEdit && this.organization.defaultContact) {
      let c = this.organization.defaultContact;
      let labelText = c.fullName.trim().length > 0 ? c.fullName : "NO-NAME";
      c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
      c.companyName ? labelText += " / Company: " + c.companyName : "";
      c.homePhoneNumber ? labelText += " / Tel: " + c.homePhoneNumber : "";
      c.mobilePhoneNumber ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
      c.email ? labelText += " / Email: " + c.email : "";

      let defaultSelectItem: SelectItem = {
        label: labelText,
        value: this.organization.defaultContactId
      };

      let defaultValue = this.organization.defaultContactId ? defaultSelectItem : null;

      this.organizationForm.patchValue({
        defaultContactId: defaultValue
      });
    }

    this.route.queryParams.subscribe(
      (queryParam: Params) => {
        let contactId = queryParam['contact'];
        if (contactId != null) {

          this.contactService.getContact(+contactId).subscribe(
            (contact: Contact) => {
              let defaultValue: SelectItem = {
                label: contact.fullName,
                value: +contactId
              };

              this.organizationForm.patchValue({
                defaultContactId: defaultValue
              });
            }
          );
        }
      }
    );


    this.isFormInit = true;
  }

  onFormValueChanges() {
    this.onEmailChange();
    this.onMobilePhoneNumberChange();
  }


  onEmailChange() {
    this.organizationForm.controls["email"].valueChanges.subscribe(
      (email: string) => {
        // If email field is empty then Mobile field is set to required
        if (email.trim() === "") {
          this.organizationForm.controls['mobilePhoneNumber'].setValidators([
            Validators.required
          ]);
          this.organizationForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
        // If email was provided then Mobile field is no longer required
        else {
          this.organizationForm.controls['mobilePhoneNumber'].clearValidators();
          this.organizationForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
      }
    );
  }

  onMobilePhoneNumberChange() {
    this.organizationForm.controls["mobilePhoneNumber"].valueChanges.subscribe(
      (mobilePhoneNumber: string) => {
        // const organizationEmails = this.organizations.map(c => c.email);
        // if (this.isEdit)
        //   organizationEmails.splice(organizationEmails.indexOf(this.organization.email), 1);

        // If MobilePhoneNumber field is empty then Email field is set to required
        if (mobilePhoneNumber.trim() === "") {
          // this.organizationForm.controls['email'].setValidators([
          //   Validators.required,
          //   valueExistsValidator(organizationEmails)
          // ]);
          this.organizationForm.controls['email'].setValidators([
            Validators.required
          ]);
          this.organizationForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
        // If MobilePhoneNumber was provided then Email field is no longer required
        else {
          // this.organizationForm.controls['email'].setValidators([
          //   valueExistsValidator(organizationEmails)
          // ]);
          this.organizationForm.controls['email'].clearValidators();
          this.organizationForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
      }
    );
  }


  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const organizationId = params['id'];
        this.isEdit = organizationId != null; // - Returns true if an id was present within the url

        if (this.isEdit) {
          this.organizationService.getOrganization(+organizationId).subscribe(
            (organization: Organization) => {
              this.organization = organization;

              this.initForm();
            },
            (error: Response) => {
              this.toastService.createErrorMessage("Error retrieving organization", error);
            }
          );

          // Redirect to 404 page if the selected organization could not be found
          if (!this.organization) {
            this.router.navigate(['/error/404']);
            return;
          }
        }
        else
          this.initForm();
      }
    );
  }

  onGetPaymentTerms() {
    this.paymentTermService.getPaymentTerms().subscribe(
      (paymentTerms: PaymentTerm[]) => {
        paymentTerms.map(oT => this.paymentTermOptions.push({ label: oT.name, value: oT.id }));

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.paymentTermOptions[0].value;
        else
          defaultValue = this.organization.paymentTerm ? this.organization.paymentTermId : null;

        this.organizationForm.patchValue({
          paymentTermId: defaultValue
        });

      }
    );
  }

  onSubmit() {
    let newOrganization = this.formBuilder.sanitizeFormValues(this.organizationForm).value;

 // This is to pass correct value (contact id) from the autocomplete selection and to sanitize it
    // (Eg: writing random string in search and submit form would return an api error response)
    if (newOrganization.defaultContactId && newOrganization.defaultContactId.value)
    newOrganization.defaultContactId = newOrganization.defaultContactId.value;
    else
    newOrganization.defaultContactId = null;



    // Status is a checkbox value so only true or false can be returned
    // True - Active Organization, False - Hidden Organization
    if (newOrganization.status)
      newOrganization.status = AuditManager.StatusActive;
    else
      newOrganization.status = AuditManager.StatusHidden;

      newOrganization.paymentTermId = 0;

    if (this.isEdit) {
      newOrganization.id = this.organization.id; // - Set id of edited organization to its original id, same with externalId and createdById
      newOrganization.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateOrganization(newOrganization);
    }
    else {
      newOrganization.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddOrganization(newOrganization);
    }
  }

  onAddOrganization(newOrganization: Organization) {

    this.organizationService.addOrganization(newOrganization).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The organization " + newOrganization.name + " has been created.");
        
          this.confirmationService.confirm({
            header: 'New Contact',
            message: 'Would you like to create a new Contact under this Organization?',
            accept: () => {

              this.router.navigate(['/contacts','new',(<any>response).id]);
            },
            reject: () => {
              this.router.navigate(['/organizations/']);
            }
          });


      },
      (error) => {
        this.toastService.createErrorMessage("Error adding organization", error);
      }
    );
  }



  onUpdateOrganization(newOrganization: Organization) {
    this.organizationService.updateOrganization(newOrganization).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The organization " + newOrganization.name  + " has been updated.");

          this.router.navigate(['/organizations/']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating organization", error);
      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let organization = cT["organization"];

          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.telephone ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.defaultContactOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }


}
