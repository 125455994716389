import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";
import { RoleGuard } from "../auth/role-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { AccountsComponent } from "../accounts/accounts.component";
import { AccountListComponent } from "../accounts/account-list/account-list.component";
import { AccountManageComponent } from "../accounts/account-manage/account-manage.component";

const accountsRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'accounts', component: AccountsComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: AccountListComponent },
          { path: ':id/edit', component: AccountManageComponent },
          { path: 'new', component: AccountManageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(accountsRoutes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }