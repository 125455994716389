
export class Property {
    constructor(
        public propertyId: number = 0,
        public propertyPreCode: string = null,
        public defaultPrice: string = null,
        public propertyCategory: string = null,
        public propertyCategoryId: number = 0,
        public propertyLocality: string = null,
        public PropertyLocalityId: number = 0,
        public propertyStatus: string = null,
        public propertyImageUrl: string = null,
        public propertyUrl: string = null,
        public propertyCoords: string[] = [],
        public propertyAddress: string = null,
        public customCode: string = null
    ) { }
}