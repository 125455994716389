import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";

import { IssuesRoutingModule } from "./issues-routing.module";

import { SharedModule } from "../shared/shared.module";
import { CalendarModule, DropdownModule, SliderModule } from "primeng/primeng";

 import { IssuesComponent } from './issues.component';
 import { IssueListComponent } from './issue-list/issue-list.component';
 import { IssueManageComponent } from './issue-manage/issue-manage.component';

@NgModule({
   declarations: [
     IssuesComponent,
     IssueListComponent,
     IssueManageComponent
  ],
  imports: [
    SharedModule,
    IssuesRoutingModule,
    ReactiveFormsModule,
    DropdownModule,
    SliderModule,
    CalendarModule
  ]
})
export class IssuesModule { }
