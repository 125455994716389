import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";

import { ContactFilesComponent } from './contact-files.component';
import { ContactFileListComponent } from './contact-file-list/contact-file-list.component';
import { ContactFileManageComponent } from './contact-file-manage/contact-file-manage.component';

const contactFilesRoutes: Routes = [
  {
    path: 'contacts',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id/files', component: ContactFilesComponent,
        children: [
          {
            path: '', component: ContactFileListComponent,
            children: [
              { path: ':id/edit', component: ContactFileManageComponent }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(contactFilesRoutes)],
  exports: [RouterModule]
})
export class ContactFilesRoutingModule { }
