import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlankLayoutComponent } from "../../components/common/layouts/blankLayout.component";
import { NotFoundComponent } from "../errors/not-found/not-found.component";
import { ForbiddenComponent } from "../errors/forbidden/forbidden.component";

const errorRoutes: Routes = [
    // Error pages
    // {
    //     path: 'error', component: BlankLayoutComponent,
    //     children: [
    //         { path: '', redirectTo: '404', pathMatch: 'full' },
    //         { path: '403', component: ForbiddenComponent },
    //         { path: '404', component: NotFoundComponent }
    //     ]
    // },
    // { path: '**', redirectTo: 'error' }
];

@NgModule({
    imports: [RouterModule.forChild(errorRoutes)],
    exports: [RouterModule]
})
export class ErrorRoutingModule { }