import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { PaymentTermsComponent } from "./payment-terms.component";
import { PaymentTermListComponent } from "./payment-term-list/payment-term-list.component";
import { PaymentTermManageComponent } from "./payment-term-manage/payment-term-manage.component";

const termsRoutes: Routes = [
    {
        path: 'organizations',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'terms', component: PaymentTermsComponent,
                children: [
                    {
                        path: '', component: PaymentTermListComponent,
                        children: [
                            { path: ':id/edit', component: PaymentTermManageComponent },
                            { path: 'new', component: PaymentTermManageComponent }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(termsRoutes)],
    exports: [RouterModule]
})
export class PaymentTermsRoutingModule { }
