import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";
import { RoleGuard } from '../auth/role-guard.service';

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";

import { ReportsComponent } from "./reports.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { TasksComponent } from "./tasks/tasks.component";
import { ConversionsComponent } from "./conversions/conversions.component";
import { OpportunitiesComponent } from "./opportunities/opportunities.component";
import { ComplaintsComponent } from "./complaints/complaints.component";

const reportRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'reports', component: ReportsComponent,
        children: [
          { path: '', redirectTo: 'contact-reports', pathMatch: 'full' },
          { path: 'contact-reports', component: ContactsComponent },
          { path: 'task-reports', component: TasksComponent },
          { path: 'conversion-reports', component: ConversionsComponent },
          { path: 'opportunity-reports', component: OpportunitiesComponent },
          { path: 'complaint-reports', component: ComplaintsComponent, canActivate: [RoleGuard] }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(reportRoutes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
