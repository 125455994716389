import { Subscription } from "rxjs";
import { OnDestroy, OnInit } from "@angular/core";

export interface ILifetimeManagement {
  subscriptions$ : Subscription[];
}

export abstract class LifetimeManagement implements ILifetimeManagement, OnInit, OnDestroy {
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions$.map(elem => {
      if(elem) {
        elem.unsubscribe();
      }
    });
  }
  
  private __subscriptions : Subscription[] = [];

  get subscriptions$(): Subscription[] {
    return this.__subscriptions;
  }

  constructor() { }

}
