import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { ContactSourcesComponent } from "../contact-sources/contact-sources.component";
import { ContactSourceListComponent } from "../contact-sources/contact-source-list/contact-source-list.component";
import { ContactSourceManageComponent } from "../contact-sources/contact-source-manage/contact-source-manage.component";

const sourcesRoutes: Routes = [
    {
        path: 'contacts',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            // {
            //     path: 'sources', component: ContactSourcesComponent,
            //     children: [
            //         { path: '', redirectTo: 'list', pathMatch: 'full' },
            //         { path: 'list', component: ContactSourceListComponent },
            //         { path: ':id/edit', component: ContactSourceManageComponent, canActivate: [RoleGuard] },
            //         { path: 'new', component: ContactSourceManageComponent, canActivate: [RoleGuard] }
            //     ]
            // }
            {
                path: 'sources', component: ContactSourcesComponent,
                children: [
                    {
                        path: '', component: ContactSourceListComponent,
                        children: [
                            { path: ':id/edit', component: ContactSourceManageComponent, canActivate: [RoleGuard] },
                            { path: 'new', component: ContactSourceManageComponent, canActivate: [RoleGuard] }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(sourcesRoutes)],
    exports: [RouterModule]
})
export class ContactSourcesRoutingModule { }