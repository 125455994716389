//import { SharedTitleService } from "@app/shared/services/shared-title.service";
import { IBaseComponent } from "./base.interface.component";
import { Subscription } from 'rxjs';
import { System } from '../shared/system';
import { SystemModeHelper } from '../shared/system-config.service';
//import { DeveloperModeHelper } from '@app/shared/developer/developer-mode.helper';

// declare let mApp: any;

export abstract class BaseComponent implements IBaseComponent {
  ngOnDestroy(): void {
    this.subscriptions$.map(elem => {
      if(elem) {
        elem.unsubscribe();
      }
    })
  }

  public showDeveloperLabel = false;
  public showDeveloperModeLabel = false;

  get isDeveloperModeEnabled() {
    return this.showDeveloperLabel && this.showDeveloperModeLabel;
  }

  isDeveloper$ = null;
  developerMode$ = null;

  subscriptions$ : Subscription[] = [];

 /* ngOnInit() {
    this.isDeveloper$ = this.developer.isDeveloper.subscribe((isDeveloper) => {

      this.showDeveloperLabel = isDeveloper;
    });

    this.subscriptions$.push(this.isDeveloper$);

    this.developerMode$ = this.developer.isDeveloperModeEnabled.subscribe((isDeveloperModeEnabled) => {

      this.showDeveloperModeLabel = isDeveloperModeEnabled;
    });

    this.subscriptions$.push(this.developerMode$);

  }*/

  ngAfterViewInit(): void {
    //this.setTitle();
  }

  //abstract setTitle(): void;

  constructor(
   // protected _sharedTitleService: SharedTitleService,
   // protected developer: DeveloperModeHelper,
  //  protected systemModeHelper : SystemModeHelper
    ) {
    // super(systemModeHelper);
  }

  // blockModal(selector: string = null) {
  //   if(selector) {
  //     this.block(selector);
  //   } else {
  //    this.block(".modal-content");
  //   }
  // }

  // unblockModal(selector: string = null) {
  //   if(selector) {
  //     this.unblock(selector);
  //   } else {
  //     this.unblock(".modal-content");
  //   }
  // }

  // // private block(selector: string) {
  // //   mApp.block(selector, {
  // //     overlayColor: "#000000",
  // //     type: "loader",
  // //     state: "success",
  // //     size: "lg"
  // //   });
  // // }

  // // private unblock(selector: string) {
  // //   mApp.unblock(selector);
  // // }
}
