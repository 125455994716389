import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { ContactGroupsComponent } from "../contact-groups/contact-groups.component";
import { ContactGroupListComponent } from "../contact-groups/contact-group-list/contact-group-list.component";
import { ContactGroupManageComponent } from "../contact-groups/contact-group-manage/contact-group-manage.component";

const groupsRoutes: Routes = [
    {
        path: 'contacts',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'groups', component: ContactGroupsComponent,
                children: [
                    {
                        path: '', component: ContactGroupListComponent,
                        children: [
                            { path: ':id/edit', component: ContactGroupManageComponent },
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(groupsRoutes)],
    exports: [RouterModule]
})
export class ContactGroupsRoutingModule { }