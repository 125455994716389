import { Component, OnInit } from '@angular/core';
import { Response } from "@angular/http";
import { Router } from '@angular/router';

import { AuthService } from "../../../auth/auth.service";
import { SavedContactSearchService } from "../../../shared/services/saved-contact-search.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { SavedContactSearch } from "../../../shared/models/saved-contact-search.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-contact-saved-search-list',
  templateUrl: './contact-saved-search-list.component.html',
  styleUrls: ['./contact-saved-search-list.component.scss']
})
export class ContactSavedSearchListComponent implements OnInit {
  contactSavedContactSearches: SavedContactSearch[] = [];
  isAnim: boolean = true;

  //** Tabs */
  contactListTabIndex: number = 0;
  contactGroupsTabIndex: number = 1;
  savedContactSearchTabIndex: number = 2;

  constructor(
    public authService: AuthService,
    private router: Router,
    private contactSavedContactSearchService: SavedContactSearchService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetSavedContactSearches();

    // Listen to changes made to contactSavedContactSearches
    this.contactSavedContactSearchService.savedContactSearchesChanged.subscribe(
      () => {
        this.onGetSavedContactSearches();
      }
    );
  }

  onGetSavedContactSearches() {
    this.contactSavedContactSearchService.getSavedContactSearches().subscribe(
      (contactSavedContactSearches: SavedContactSearch[]) => {
        this.contactSavedContactSearches = contactSavedContactSearches;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact searches", error);
      }
    );
  }

  onDeleteContactSavedContactSearch(contactSavedContactSearch: SavedContactSearch) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the saved contact search ' + contactSavedContactSearch.name + '?',
      accept: () => {
        contactSavedContactSearch.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactSavedContactSearchService.deleteSavedContactSearch(contactSavedContactSearch).subscribe(
          (response: Response) => {
            this.onGetSavedContactSearches();
            this.toastService.createSuccessMessage("Success", "The saved contact search " + contactSavedContactSearch.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting the saved contact search", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for the contact search " + contactSavedContactSearch.name + " has been aborted.");
      }
    });
  }

  onChangeTabView($event) {
    let tabIndex = $event.index;
    switch ($event.index) {
      case this.contactListTabIndex:
        this.router.navigate(["/contacts/list"]);
        break;
      case this.contactGroupsTabIndex:
        this.router.navigate(["/contacts/groups"]);
        break;
      case this.savedContactSearchTabIndex:
        this.router.navigate(["/contacts/saved-searches"]);
        break;
    }
  }
  
  trackByFn(index: number, row: any) {
    return row.id;
  }
}
