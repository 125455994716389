import { CrmUser } from "../../../shared/models/user.model";

export class Campaign {
    constructor(
        public id: string = null,
        public create_time: string = null,
        public send_time: string = null,
        public status: string = null,
        public emails_sent: number = null,
        public settings: CampaignSettings = null,
        public report_summary: CampaignReportSummary = null
    ) { }
}

export class CampaignSettings {
  constructor(
    public subject_line: string = null,
    public preview_text: string = null,
    public title: string = null,
    public from_name: string = null,
    public reply_to: string = null,
    public use_conversation: boolean = null,
    public to_name: string = null,
    public folder_id: string = null,
    public authenticate: boolean = null,
    public auto_footer: boolean = null,
    public inline_css: boolean = null,
    public auto_tweet: boolean = null,
    public auto_fb_post: string[] = null,
    public fb_comments: boolean = null,
    public timewarp: boolean = null,
    public template_id: number = null,
    public drag_and_drop: boolean = null
  ) { }
}

export class CampaignReportSummary {
  constructor(
    public opens: number = null,
    public unique_opens: number = null,
    public open_rate: number = null,
    public clicks: number = null,
    public subscriber_clicks: number = null,
    public click_rate: number = null,
  ) { }
}

export class List {
  constructor(
      public id: string = null,
      public date_created: string = null,
      public name: string = null,
      public stats: ListStats = null
  ) { }
}

export class ListStats {
  constructor(
    public member_count: number = null,
    public unsubscribe_count: number = null,
    public cleaned_count: number = null,
    public member_count_since_send: number = null,
    public unsubscribe_count_since_send: number = null,
    public cleaned_count_since_send: number = null,
    public campaign_count: number = null,
    public campaign_last_sent: string = null,
    public merge_field_count: number = null,
    public avg_sub_rate: number = null,
    public avg_unsub_rate: number = null,
    public target_sub_rate: number = null,
    public open_rate: number = null,
    public click_rate: number = null,
    public last_sub_date: string = null,
    public last_unsub_date: string = null
  ) { }
}

export class MailChimpConfig {
  constructor(
      public id: number = 0,
      public apiKey: string = null,
      public createdByUserAccount: CrmUser = null,
      public createdByUserAccountId: number= null,
      public createdOn: string = null,
      public updatedByUserAccount: CrmUser = null,
      public updatedByUserAccountId: number = null,
      public updatedOn: string = null,
      public status: string = null
  ) { }
}