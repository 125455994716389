import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { Product } from "../models/product.model";

import { AuthService } from "../../auth/auth.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';
import { PropertyCategory } from '../models/property-category.model';
import { PropertyLocality } from '../models/property-locality.model';
import { PropertyRegion } from '../models/property-region.model';
import { PropertyDealType } from '../models/property-dealtype';
import { PropertyAttribute } from '../models/property-attribute';
import { PropertyDetails } from '../models/property-details.model';
import { LoadingSpinnerService } from './loading-spinner.service';
import { Opportunity } from '../models/opportunity.model';
import { OpportunitySelection } from '../models/opportunity-selection.model';
import { PropertyOppSelection } from '../models/property-opportunity-selection.model';
import { Property } from '../models/property.model';


@Injectable()
export class PropertyService {
  private url: string = AppSettings.API_ENDPOINT + 'api/opportunities/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingSpinnerService
  ) { }

  getMainCategoryTypes(): Observable<PropertyCategory[]> {
    return this.http.get(this.url+'maincategories').map(
      (response: Response) => {
        const propCategories: PropertyCategory[] = (<any>response);
        return propCategories;
      }
    );
  }

  getSubCategoryTypes(selectedMainCat: number[]): Observable<PropertyCategory[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<PropertyCategory[]>(
      this.url+'subcategories', selectedMainCat
      ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getPropertyLocalities(selectedReg: number[]): Observable<PropertyLocality[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<PropertyLocality[]>(
      this.url+'propertylocalitiesbyregionid', selectedReg
      ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getPropertyRegions(): Observable<PropertyRegion[]> {
    return this.http.get(this.url+'propertyregions').map(
      (response: Response) => {
        const propLocalities: PropertyRegion[] = (<any>response);
        return propLocalities;
      }
    );
  }

  getPropertyDealTypes(): Observable<PropertyDealType[]> {
    return this.http.get(this.url+'dealtypes').map(
      (response: Response) => {
        const propDealTypes: PropertyDealType[] = (<any>response);
        return propDealTypes;
      }
    );
  }

  getPropertyRooms(): Observable<PropertyAttribute[]> {
    return this.http.get(this.url+'rooms').map(
      (response: Response) => {
        const propAttributes: PropertyAttribute[] = (<any>response);
        return propAttributes;
      }
    );
  }

  getPropertyFeatures(): Observable<PropertyAttribute[]> {
    return this.http.get(this.url+'features').map(
      (response: Response) => {
        const propAttributes: PropertyAttribute[] = (<any>response);
        return propAttributes;
      }
    );
  }

  postPropertyDetails(opportunitySelection: OpportunitySelection): Observable<Response> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url+'postoppselection', opportunitySelection
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getPropertyDetails(id: number): Observable<PropertyOppSelection> {
    return this.http.get(this.url+'getoppselectionbyid/'+ id).map(
      (response: Response) => {
        let propOppSelection: PropertyOppSelection = (<any>response);
        // let oppSelection: OpportunitySelection = propOppSelection.oppSelectionModel
        // let propDetails: PropertyDetails = propOppSelection.oppSelectionModel.oppSelectionModel
        // let properties: Property[] = propOppSelection.property
        return propOppSelection;
      }
    );
  }

  updatePropertyDetails(id:number, opportunitySelection: OpportunitySelection): Observable<Response> {
    
    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + 'updateoppselection/' + id, opportunitySelection
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  // getProduct(id: number): Observable<Product> {
  //   //this.authService.reauthenticateIfTokenExpired();

  //   return this.http.get(this.url + id).map(
  //     (response: Response) => {
  //       const product: Product = (<any>response);
  //       return product;
  //     }
  //   );
  // }

}
