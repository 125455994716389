import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';

import { DashboardRoutingModule } from "./dashboard-routing.module";

import { SharedModule } from "../shared/shared.module";

import { ChartModule, DropdownModule } from 'primeng/primeng';

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    SharedModule,
    ChartModule,
    DropdownModule,
    DashboardRoutingModule,
  ]
})
export class DashboardModule { }
