import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from "@angular/http";

import { CommunicationHistoryService } from "../../../shared/services/communication-history.service";
import { ContactService } from "../../../shared/services/contact.service";

import { Contact } from "../../../shared/models/contact.model";

@Component({
  selector: 'app-communication-history-detail',
  templateUrl: './communication-history-detail.component.html',
  styleUrls: ['./communication-history-detail.component.scss']
})
export class CommunicationHistoryDetailComponent implements OnInit {
  contact: Contact = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        const contactId = params['contactId'];

        if (contactId != 'all') {
          this.contactService.getContact(contactId).subscribe(
            (contact: Contact) => {
              this.contact = contact;
            },
            (error: Response) => {
              this.router.navigate(['/error/404']);
            }
          );
        }
        else
          this.contact = null;
      }
    );
  }
}
