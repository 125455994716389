import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';
import { CustomFormBuilder } from 'app/views/shared/classes/CustomFormBuilder';
import { AuthService } from 'app/views/auth/auth.service';
import { ToastService } from 'app/views/shared/services/toast.service';
import { map, switchMap } from 'rxjs/operators';
import { QuoteService } from 'app/views/shared/services/quote.service';
import { forkJoin } from 'rxjs';
import { Quote } from 'app/views/shared/models/quote.model';
import { AutoComplete, SelectItem } from 'primeng/primeng';
import { Contact } from 'app/views/shared/models/contact.model';
import { ContactService } from 'app/views/shared/services/contact.service';
import { ContactSearchItem } from '../../../shared/models/contact-search-item.model';


@Component({
  selector: 'app-opportunity-quote-send-email',
  templateUrl: './opportunity-quote-send-email.component.html',
  styleUrls: ['./opportunity-quote-send-email.component.scss']
})
export class OpportunityQuoteSendEmailManageComponent implements OnInit {
  opportunityQuoteSendEmailForm: FormGroup;       // - Stores the opportunityQuoteSendEmail form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a opportunityQuoteSendEmail if true and add a new opportunityQuoteSendEmail if false
  quoteId: number = 0;              // - Quote Id which need to send email
  isModalShown: boolean = false;

  toOptions: SelectItem[] = [];
  toCCOptions: SelectItem[] = [];
  toBCCOptions: SelectItem[] = [];

  recipientsOptions: any;
  newEmailRecipient:string;

  showCC = false;
  showBCC = false;

  @ViewChild('recipientsAutoComplete') private recipientsAutoComplete: AutoComplete;
  @ViewChild('recipientsCCAutoComplete') private recipientsCCAutoComplete: AutoComplete;
  @ViewChild('recipientsBCCAutoComplete') private recipientsBCCAutoComplete: AutoComplete;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private contactService: ContactService,
    public _quoteService: QuoteService,
    private authService: AuthService,
    private toastService: ToastService,

  ) { }

  ngOnInit() {
    this.setupForm();
  }

  onAutoCompleteEnterKey(event, type:string) {

    if(this.newEmailRecipient){

      let  newContactRecipient: Contact = new Contact();
      newContactRecipient.email = this.newEmailRecipient
      newContactRecipient.fullName = this.newEmailRecipient
      let labelText= this.mapContactRecipient(newContactRecipient);
      let newRecipient = {label: labelText, value: newContactRecipient};

      switch(type){
        case "bcc":
            //Checking if there is already an entry for the input
            let currentBCCList = this.opportunityQuoteSendEmailForm.get('bccRecipients').value;
            let bccList: any[]=[];

            if(currentBCCList){
              bccList = currentBCCList;
            }
            bccList.push(newRecipient);

          this.opportunityQuoteSendEmailForm.patchValue({
            bccRecipients: bccList,
          });
          this.recipientsBCCAutoComplete.multiInputEL.nativeElement.value = '';

          break;

        case "cc":
          let currentCCList = this.opportunityQuoteSendEmailForm.get('ccRecipients').value;
          let ccList: any[]=[];

          if(currentCCList){
            ccList = currentCCList;
          }
          ccList.push(newRecipient);

          this.opportunityQuoteSendEmailForm.patchValue({
            ccRecipients: ccList,
          });
          this.recipientsCCAutoComplete.multiInputEL.nativeElement.value = '';

          break;

        case "to":
            let currentToList = this.opportunityQuoteSendEmailForm.get('recipients').value;
            let toList: any[]=[];

            if(currentToList){
              toList = currentToList;
            }
            toList.push(newRecipient);

            this.opportunityQuoteSendEmailForm.patchValue({
              recipients: toList,
            });
            this.recipientsAutoComplete.multiInputEL.nativeElement.value = '';

          break;
      }

      this.handleDropdownView(type);
    }

  }

  searchAutoComplete(event, type:string) {
    if (event.query){
      this.onGetContactsSearch(event.query, type);
    }
    //Setting value for items which are not found
    this.newEmailRecipient = event.query;
  }

  handleDropdown(event, type:string) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();

    this.handleDropdownView(type);
  }

  handleDropdownView(type: string){
    switch(type){
      case "bcc":
          if (this.recipientsBCCAutoComplete.panelVisible) {
            this.recipientsBCCAutoComplete.hide();
          } else {
            this.recipientsBCCAutoComplete.show();
          }
        break;
      case "cc":

          if (this.recipientsCCAutoComplete.panelVisible) {
            this.recipientsCCAutoComplete.hide();
          } else {
            this.recipientsCCAutoComplete.show();
          }
        break;

      case "to":

          if (this.recipientsAutoComplete.panelVisible) {
            this.recipientsAutoComplete.hide();
          } else {
            this.recipientsAutoComplete.show();
          }
        break;
    }
  }


  onSelectRecipient(event: any, type:string) {
    this.newEmailRecipient = null;
  }

  onGetContactsSearch(searchTerm: string, type:string) {
    // this.contactService.getContactsSearch(searchTerm).subscribe(
    //   (contacts: ContactSearchItem[]) => {
    //     let labelText = "";

    //     let retrievedContactsOptions = [];

    //     // Store retrieved contacts in the contactsOptions select list
    //     contacts.map(cT => {

    //       let labelText = this.mapContactRecipient(cT);
    //       retrievedContactsOptions.push(
    //         {
    //           label: labelText,
    //           value: cT
    //         }
    //       );
    //     });

    //     switch(type){
    //       case "bcc":
    //         this.toBCCOptions = retrievedContactsOptions;
    //       break;
    //       case "cc":
    //         this.toCCOptions = retrievedContactsOptions;
    //       break;
    //       case "to":
    //         this.toOptions = retrievedContactsOptions;
    //       break;
    //     }

    //   },
    //   (error: Response) => {
    //     this.toastService.createErrorMessage("Error retrieving contacts", error);
    //   }
    // );
  }


  setupForm() {
    // this.route.parent.params.subscribe(
    //   (queryParam: Params) => {
    //     this.quoteId = queryParam['id'];
    //     this.initForm(null);
    //   }
    // );

    let params$ = this.route.parent.params;
    params$.pipe(
        map((params: Params) => {
          //return params;
          this.quoteId = +params['id'];
          let id = +params['id'];
          return id;
        }), switchMap((param: number) => {
          return forkJoin(this._quoteService.getAssignedContactByQuoteId(param), this._quoteService.getQuote(param));
        })).subscribe((_) => {
          this.initForm(_[0], _[1]);
        });
  }


  mapContactRecipient(cT:Contact){
    let labelText ="";
    let organization = cT["organization"];

    labelText =  cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
    organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";

    //cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
    //cT.companyName ? labelText += " / Company: " + cT.companyName : "";
    //cT.homePhoneNumber ? labelText += " / Tel: " + cT.homePhoneNumber : "";
    // cT.mobilePhoneNumber ? labelText += " / Mob: " + cT.mobilePhoneNumber : "";
    cT.email ? labelText += " / Email: " + cT.email : "";
    return labelText;

  }

  initForm(recipients, quote: Quote) {
    // Get all opportunityQuoteSendEmail names to know if it already exists

      let labelText= this.mapContactRecipient(recipients);

      this.toOptions.push(
        {
          label: labelText,
          value: recipients
        }
      );

    this.opportunityQuoteSendEmailForm = this.formBuilder.group({
      quoteId: this.formBuilder.control(this.quoteId),
      recipients: this.formBuilder.control(this.toOptions),
      ccRecipients: this.formBuilder.control(""),
      bccRecipients: this.formBuilder.control(""),
      subject: this.formBuilder.control(`Quote ${quote.reference} v${quote.version}`),
      message: this.formBuilder.control(`Dear ${recipients.firstName},<br/><br/>Please find attached the quotation that you have requested.<br/><br/>Kind Regards,<br/>`)
    });
    this.isFormInit = true;
  }

  onSubmit() {
    let newOpportunityQuoteSendEmail = this.formBuilder.sanitizeFormValues(this.opportunityQuoteSendEmailForm).getRawValue();

    let toDictionary = [];
    let ccDictionary = [];
    let bccDictionary = [];

    if (newOpportunityQuoteSendEmail.recipients) {
      newOpportunityQuoteSendEmail.recipients.map(
        to =>{
          toDictionary.push({
            fullName: to.value.fullName,
            email: to.value.email
          });
      });
      newOpportunityQuoteSendEmail.recipients = toDictionary;
    }

    if (newOpportunityQuoteSendEmail.ccRecipients) {
      newOpportunityQuoteSendEmail.ccRecipients.map(
        cc =>{
          ccDictionary.push({
            fullName: cc.value.fullName,
            email: cc.value.email
          });
      });
      newOpportunityQuoteSendEmail.ccRecipients = ccDictionary;
    }

    if (newOpportunityQuoteSendEmail.bccRecipients) {
      newOpportunityQuoteSendEmail.bccRecipients.map(
        bcc =>{
          bccDictionary.push({
            fullName: bcc.value.fullName,
            email: bcc.value.email
          });
      });
      newOpportunityQuoteSendEmail.bccRecipients = bccDictionary;
    }
    this._quoteService.scheduleEmailQuote(newOpportunityQuoteSendEmail).subscribe(()=>{});
  }
}
