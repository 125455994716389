import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { ContactGroupsRoutingModule } from "./contact-groups-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { ContactGroupsComponent } from './contact-groups.component';
import { ContactGroupListComponent } from './contact-group-list/contact-group-list.component';
import { ContactGroupManageComponent } from './contact-group-manage/contact-group-manage.component';

@NgModule({
  declarations: [
    ContactGroupsComponent,
    ContactGroupListComponent,
    ContactGroupManageComponent
  ],
  imports: [
    SharedModule,
    ContactGroupsRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class ContactGroupsModule { }
