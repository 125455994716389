import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { MessageTemplateService } from "../../../shared/services/message-template.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { MessageTemplate } from "../../../shared/models/message-template.model";
import { valueExistsValidator } from 'app/views/shared/validators/value-exists.validator';

@Component({
  selector: 'app-message-centre-templates-manage',
  templateUrl: './message-centre-templates-manage.component.html',
  styleUrls: ['./message-centre-templates-manage.component.scss']
})
export class MessageCentreTemplatesManageComponent implements OnInit {
  templateForm: FormGroup;          // - Stores the template form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a message template if true and add a new message template if false

  messageTemplates: MessageTemplate[] = [];           // - Stores all messageTemplates retrieved from db
  template: MessageTemplate = new MessageTemplate();  // - Stores MessageTemplate to populate form with an existing MessageTemplate's values

  messageMaxLength: number = 720;

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private messageTemplateService: MessageTemplateService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.messageTemplateService.getMessageTemplates().subscribe(
      (messageTemplates: MessageTemplate[]) => {
        this.messageTemplates = messageTemplates; // - Store all templates retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const messageTemplateId = params['id'];
            this.isEdit = messageTemplateId != null;

            if (this.isEdit) {
              this.template = this.messageTemplates.find(mT => mT.id === +messageTemplateId); // - Store the selected template

              // Redirect to 404 page if the selected template could not be found
              if (!this.template) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving templates", error);
      }
    );
  }

  initForm() {
    // Get all template names to know if it already exists
    const templateNames = this.messageTemplates.map(mT => mT.name);

    // Remove the current template name if being edited so the name can remain unchanged
    if (this.isEdit)
      templateNames.splice(templateNames.indexOf(this.template.name), 1);

    this.templateForm = this.formBuilder.group({
      name: this.formBuilder.control(this.template.name, [Validators.required, valueExistsValidator(templateNames)]),
      message: this.formBuilder.control(this.template.message, [Validators.required, Validators.maxLength(this.messageMaxLength)])
    });

    this.isFormInit = true;
  }

  onAddMessageTemplate(newMessageTemplate: MessageTemplate) {
    this.messageTemplateService.addMessageTemplate(newMessageTemplate).subscribe(
      (response: Response) => {
        this.messageTemplateService.messageTemplatesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The template " + newMessageTemplate.name + " has been created.");
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding template", error);
      }
    );
  }

  onUpdateMessageTemplate(newMessageTemplate: MessageTemplate) {
    this.messageTemplateService.updateMessageTemplate(newMessageTemplate).subscribe(
      (response: Response) => {
        this.messageTemplateService.messageTemplatesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The template " + newMessageTemplate.name + " has been updated.");
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating template", error);
      }
    );
  }

  onSubmit() {
    let newMessageTemplate = this.formBuilder.sanitizeFormValues(this.templateForm).value;
    
    if (this.isEdit) {
      newMessageTemplate.id = this.template.id; // - Set id of edited messageTemplate to its original id
      newMessageTemplate.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateMessageTemplate(newMessageTemplate);
    }
    else {
      newMessageTemplate.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddMessageTemplate(newMessageTemplate);
    }
  }
}
