import { Component } from '@angular/core';

@Component({
  selector: 'app-opportunity-files',
  templateUrl: './opportunity-files.component.html',
  styleUrls: ['./opportunity-files.component.scss']
})
export class OpportunityFilesComponent {

}
