import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { GroupService } from "../../../shared/services/group.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { Group } from "../../../shared/models/group.model";

@Component({
  selector: 'app-contact-group-manage',
  templateUrl: './contact-group-manage.component.html',
  styleUrls: ['./contact-group-manage.component.scss']
})
export class ContactGroupManageComponent implements OnInit {
  groupForm: FormGroup;       // - Stores the group form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised

  group: Group = new Group(); // - Stores Group to populate form with a new / existing Group's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private groupService: GroupService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const groupId = params['id'];

        this.groupService.getGroup(+groupId).subscribe(
          (group: Group) => {
            this.group = group;

            // Redirect to 404 page if the selected group could not be found
            if (!this.group) {
              this.router.navigate(['/error/404']);
              return;
            }

            this.initForm();
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error retrieving group", error);
          }
        );
      }
    );
  }

  initForm() {
    this.groupForm = this.formBuilder.group({
      name: this.formBuilder.control(this.group.name, [Validators.required])
    });
    this.isFormInit = true;
  }

  onUpdateGroup(newGroup: Group) {
    this.groupService.updateGroup(newGroup).subscribe(
      (response: Response) => {
        this.groupService.groupsChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The group " + newGroup.name + " has been updated.");
        this.router.navigate(['/contacts/groups']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating group", error);
      }
    );
  }

  onSubmit() {
    let newGroup = this.formBuilder.sanitizeFormValues(this.groupForm).value;

    newGroup.id = this.group.id; // - Set id of edited group to its original id
    newGroup.updatedByUserAccountId = this.authService.applicationProfileUser().id;
    this.onUpdateGroup(newGroup);
  }
}
