import { NgModule } from '@angular/core';

import { SharedModule } from "../shared/shared.module";

import { MailchimpComponent } from './mailchimp.component';
import { MailchimpListComponent } from './mailchimp-list/mailchimp-list.component';

import { MailchimpRoutingModule } from './mailchimp-routing.module';
import { MailchimpConfigComponent } from './mailchimp-list/mailchimp-config/mailchimp-config.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    MailchimpComponent,
    MailchimpListComponent,
    MailchimpConfigComponent
  ],
  imports: [
    SharedModule,
    MailchimpRoutingModule,
    ModalModule.forRoot(),
    ReactiveFormsModule
  ]
})
export class MailchimpModule { }
