import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { PaymentTermService } from "../../../shared/services/payment-term.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { PaymentTerm } from "../../../shared/models/payment-term.model";

import { valueExistsValidator } from "../../../shared/validators/value-exists.validator";

@Component({
  selector: 'app-payment-term-manage',
  templateUrl: './payment-term-manage.component.html',
  styleUrls: ['./payment-term-manage.component.scss']
})
export class PaymentTermManageComponent implements OnInit {
  paymentTermForm: FormGroup;       // - Stores the paymentTerm form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a paymentTerm if true and add a new paymentTerm if false

  paymentTerms: PaymentTerm[] = [];             // - Stores all paymentTerms retrieved from db
  paymentTerm: PaymentTerm = new PaymentTerm(); // - Stores PaymentTerm to populate form with a new / existing PaymentTerm's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private paymentTermService: PaymentTermService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.paymentTermService.getPaymentTerms().subscribe(
      (paymentTerms: PaymentTerm[]) => {
        this.paymentTerms = paymentTerms; // - Store all payment terms retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const paymentTermId = params['id'];
            this.isEdit = paymentTermId != null;

            if (this.isEdit) {
              this.paymentTerm = this.paymentTerms.find(cType => cType.id === +paymentTermId); // - Store the selected paymentTerm

              // Redirect to 404 page if the selected paymentTerm could not be found
              if (!this.paymentTerm) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving payment terms", error);
      }
    );
  }

  initForm() {


    if(this.isEdit){

      this.paymentTermForm = this.formBuilder.group({
        name: this.formBuilder.control(this.paymentTerm.name, Validators.required),
        code: this.formBuilder.control({ value: this.paymentTerm.code, disabled:true}, Validators.required),
        description: this.formBuilder.control(this.paymentTerm.description)
      });
    }
    else{


    const paymentCodes = this.paymentTerms.map(p => p.code);

      this.paymentTermForm = this.formBuilder.group({
        name: this.formBuilder.control(this.paymentTerm.name, Validators.required),
        code: this.formBuilder.control(this.paymentTerm.code, [Validators.required, valueExistsValidator(paymentCodes)]),
        description: this.formBuilder.control(this.paymentTerm.description)
      });

    }

    this.isFormInit = true;
  }

  onAddPaymentTerm(newPaymentTerm: PaymentTerm) {
    this.paymentTermService.addPaymentTerm(newPaymentTerm).subscribe(
      (response: Response) => {
        this.paymentTermService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The payment term " + newPaymentTerm.name + " has been created.");
        this.router.navigate(['/organizations/terms']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding payment term", error);
      }
    );
  }

  onUpdatePaymentTerm(newPaymentTerm: PaymentTerm) {
    this.paymentTermService.updatePaymentTerm(newPaymentTerm).subscribe(
      (response: Response) => {
        this.paymentTermService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The payment term " + newPaymentTerm.name + " has been updated.");
        this.router.navigate(['/organizations/terms']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating payment term", error);
      }
    );
  }

  onSubmit() {
    let newPaymentTerm = this.formBuilder.sanitizeFormValues(this.paymentTermForm).value;

    if (this.isEdit) {
      newPaymentTerm.id = this.paymentTerm.id; // - Set id of edited paymentTerm to its original id
      newPaymentTerm.createdByUserAccountId = this.paymentTerm.createdByUserAccountId; // - Need createdById of original payment term to check if it is a prepopulated type
      newPaymentTerm.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdatePaymentTerm(newPaymentTerm);
    }
    else {
      newPaymentTerm.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddPaymentTerm(newPaymentTerm);
    }
  }
}
