import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { EventsComponent } from './events.component';
import { EventsListComponent } from './list/events-list.component';
import { EventsManageComponent } from './manage/events-manage.component';

const contactsRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'events', component: EventsComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: EventsListComponent },
          { path: ':id/edit', component: EventsManageComponent },
          { path: 'new', component: EventsManageComponent },
          // { path: 'new/:organizationId', component: ContactManageComponent },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(contactsRoutes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
