import { Component, OnInit, OnDestroy } from '@angular/core';
import { RetrieveContactQuery } from '../_graphql/retrieve-contacts.query';
import { map } from 'rxjs/operators';
import { Contact } from 'app/views/shared/models/contact.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    // super.ngOnDestroy();
  }

  public _contacts : Contact[] = [];

  public _contacts$ : BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);

  constructor(public _retrieveContactsComponent: RetrieveContactQuery) {

  }

  /**
   * Angular Lifecylce Method
   */
  ngOnInit() {

    this._contacts$.subscribe((_contacts) => {
      /**
        * Initialise my Form, if it is a manage screen;
        */

        // Initiliase Reactive Form.

        // this.form = new FormGroup() { }
    });

    this.appOnInit();


  }

  /**
   * Our App Logic initialization
   */
  appOnInit() {
    /* ContactFilterInput(ObjectGraphType) */
    var VARS = {
      identifiers: []
    };

    var app$ = this._retrieveContactsComponent
    .fetch({
      input: VARS
    }).pipe(map((result) => {
      debugger;
      const item = (<any>result.data);
      let _contacts = item ? (<any>item).contacts.data as Contact[] : [];

      return _contacts;
    }));

    var appSubscription = app$.subscribe((_contacts) => {
      this._contacts = _contacts;

      this._contacts$.next(_contacts);
    });
  }
}
