import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { Tag } from "../models/tag.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class TagService {
  private url: string = AppSettings.API_ENDPOINT + 'api/tags/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getTags(): Observable<Tag[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let tags: Tag[] = (<any>response);
        tags = tags.map(
          t => {
            // t.createdByUserAccount = this.userService.getUser(t.createdByUserAccountId);
            // t.updatedByUserAccount = this.userService.getUser(t.updatedByUserAccountId);
            return t;
          }
        );
        return tags;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getTag(id: number): Observable<Tag> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let tag: Tag = (<any>response);
        // tag.createdByUserAccount = this.userService.getUser(tag.createdByUserAccountId);
        // tag.updatedByUserAccount = this.userService.getUser(tag.updatedByUserAccountId);
        return tag;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addTag(tag: Tag): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      tag
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateTag(tag: Tag): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + tag.id,
      tag
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteTag(tag: Tag): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + tag.id,
      tag
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

}
