import { Component, OnInit } from '@angular/core';
import { Response } from "@angular/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService } from "../auth.service";
import { ToastService } from "../../shared/services/toast.service";

import { CrmUser } from "../../shared/models/user.model";
import { ForgotPasswordModel } from '../../shared/models/forgot-password.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isFormInit: boolean = false;

  forgotPasswordModel: ForgotPasswordModel = new ForgotPasswordModel();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: this.formBuilder.control(this.forgotPasswordModel.email, [Validators.required, Validators.email])
    });

    this.isFormInit = true;
  }

  onSubmit() {
    this.forgotPasswordModel.email = this.forgotPasswordForm.value.email;
    
    this.authService.forgotPassword(this.forgotPasswordModel).subscribe(
      (response: Response) => {
        if (response) {
          this.toastService.createSuccessMessage("Reset Password", "Please check your email to reset your password.");     
          this.router.navigate(["/login"]);               
        }
        else
          console.log("The access token could not be generated.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Forgot Password Failed", error);
      }
    );
  }
}
