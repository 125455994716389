import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from "@angular/http";

import { CommunicationHistoryService } from "../../../shared/services/communication-history.service";
import { ContactService } from "../../../shared/services/contact.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";
import { ConfirmationService } from "primeng/primeng";

import { Contact } from "../../../shared/models/contact.model";
import { CommunicationLog } from "../../../shared/models/communication-log.model";

import { Subject } from "rxjs/Rx";

@Component({
  selector: 'app-communication-history-list',
  templateUrl: './communication-history-list.component.html',
  styleUrls: ['./communication-history-list.component.scss']
})
export class CommunicationHistoryListComponent implements OnInit {
  contactId: number = 0;
  opportunityId: number = 0;
  complaintId: number = 0;
  communicationLogs: CommunicationLog[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private communicationHistoryService: CommunicationHistoryService,
    private contactService: ContactService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        const contactId = params['contactId'];
        const opportunityId = params['opportunityId'];
        const complaintId = params['complaintId'];
        this.setupCommunicationLogs(contactId, opportunityId,complaintId);
      }
    );

    // Listen to changes made to communicationLogs
    this.communicationHistoryService.logsChanged.subscribe(
      () => {
        this.setupCommunicationLogs(this.contactId, this.opportunityId,this.complaintId);
      }
    );
  }

  setupCommunicationLogs(contactId: any, opportunityId: any, complaintId:any) {
    if (contactId !== 'all' && contactId !== 0) {
      this.contactId = +contactId;

      if (opportunityId && opportunityId !== 'general') {
        this.opportunityId = + opportunityId;
        this.complaintId = 0;
        this.onGetCommunicationLogsByOpportunityId(this.opportunityId);
      }
      else if(complaintId && complaintId !== 'select'){
        this.complaintId = + complaintId;
        this.opportunityId = 0;
        this.onGetCommunicationLogsByComplaintId(this.complaintId);
      }
      else {
        this.complaintId = 0;
        this.opportunityId = 0;
        this.onGetCommunicationLogsByContactId(this.contactId);
      }
    }
    else {
      this.contactId = 0;
      this.opportunityId = 0;
      this.complaintId = 0;
      this.onGetCommunicationLogs();
    }
  }

  onGetCommunicationLogsByContactId(contactId: number) {
    this.communicationHistoryService.getCommunicationLogsByContactId(contactId, false).subscribe(
      (communicationLogs: CommunicationLog[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onGetCommunicationLogsByOpportunityId(opportunityId: number) {
    this.communicationHistoryService.getCommunicationLogsByOpportunityId(opportunityId).subscribe(
      (communicationLogs: CommunicationLog[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onGetCommunicationLogsByComplaintId(complaintId: number) {
    this.communicationHistoryService.getCommunicationLogsByComplaintId(complaintId).subscribe(
      (communicationLogs: CommunicationLog[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onGetCommunicationLogs() {
    this.communicationHistoryService.getCommunicationLogs().subscribe(
      (communicationLogs: CommunicationLog[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onDeleteCommunicationLog(communicationLog: CommunicationLog) {
    let name = communicationLog.opportunity ? communicationLog.opportunity.name : communicationLog.contact.fullName;

    this.confirmationService.confirm({
      key: 'deleteLog',
      message: 'Are you sure you want to delete this communication log assigned to ' + name + '?',
      accept: () => {
        communicationLog.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.communicationHistoryService.deleteCommunicationLog(communicationLog).subscribe(
          (response: Response) => {
            this.communicationHistoryService.logsChanged.next();
            this.toastService.createSuccessMessage(
              "Success",
              "The communication log that was assigned to " + name + " has been deleted."
            );
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting communication log", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage(
          "Aborted",
          "The delete for the communication log assigned to " + name + " has been aborted."
        );
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}