import { Opportunity } from './opportunity.model';
import { CrmUser } from './user.model';
import { QuoteItem } from './quote-item.model';

export class Quote {
    constructor(


        public id: number = 0,
        public opportunity: Opportunity = null,
        public opportunityId: number = 0,

        public reference: string = null,
        public version: number = 0,

        public validUntil: string = null,

        public quoteItems: QuoteItem[] = [],

        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = 0,
        public updatedOn: string = null,
        public status: string = null,

        public isSelectedInvoice: boolean = false,
        public subTotal : number= 0,
        public discount : number= 0,
        public subTotalLessDiscount : number= 0,
        public vat : number= 0,
        public totalVat : number= 0,
        public grandTotal : number= 0,
    ) { }
}
