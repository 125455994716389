import { Component, OnInit } from '@angular/core';
import { Response } from "@angular/http";
import { Router } from '@angular/router';

import { AuthService } from "../../../auth/auth.service";
import { GroupService } from "../../../shared/services/group.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { Group } from "../../../shared/models/group.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-contact-group-list',
  templateUrl: './contact-group-list.component.html',
  styleUrls: ['./contact-group-list.component.scss']
})
export class ContactGroupListComponent implements OnInit {
  contactGroups: Group[] = [];
  isAnim: boolean = true;

  //** Tabs */
  contactListTabIndex: number = 0;
  contactGroupsTabIndex: number = 1;
  savedContactSearchTabIndex: number = 2;

  constructor(
    public authService: AuthService,
    private router: Router,
    private contactGroupService: GroupService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetGroups();

    // Listen to changes made to contactGroups
    this.contactGroupService.groupsChanged.subscribe(
      () => {
        this.onGetGroups();
      }
    );
  }

  onGetGroups() {
    this.contactGroupService.getGroups().subscribe(
      (contactGroups: Group[]) => {
        this.contactGroups = contactGroups;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact groups", error);
      }
    );
  }

  onDeleteContactGroup(contactGroup: Group) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the contact group ' + contactGroup.name + '?',
      accept: () => {
        contactGroup.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactGroupService.deleteGroup(contactGroup).subscribe(
          (response: Response) => {
            this.onGetGroups();
            this.toastService.createSuccessMessage("Success", "The contact group " + contactGroup.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting contact group", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for contact group " + contactGroup.name + " has been aborted.");
      }
    });
  }

  onChangeTabView($event) {
    let tabIndex = $event.index;
    switch ($event.index) {
      case this.contactListTabIndex:
        this.router.navigate(["/contacts/list"]);
        break;
      case this.contactGroupsTabIndex:
        this.router.navigate(["/contacts/groups"]);
        break;
      case this.savedContactSearchTabIndex:
        this.router.navigate(["/contacts/saved-searches"]);
        break;
    }
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
