import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { ContactService } from '../../shared/services/contact.service';
import { EmailProfileService } from '../../shared/services/email-profile.service';
import { ToastService } from '../../shared/services/toast.service';

import { ConfirmationService } from 'primeng/primeng';

import { EmailProfile } from '../../shared/models/email-profile.model';

@Component({
  selector: 'app-message-centre-config-email',
  templateUrl: './message-centre-config-email.component.html',
  styleUrls: ['./message-centre-config-email.component.scss']
})
export class MessageCentreConfigEmailComponent implements OnInit {
  profiles: EmailProfile[] = [];

  constructor(
    public authService: AuthService,
    private emailProfileService: EmailProfileService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetEmailProfiles();

    // Listen to changes made to profiles
    this.emailProfileService.emailProfilesChanged.subscribe(
      () => {
        this.onGetEmailProfiles();
      }
    );
  }

  onGetEmailProfiles() {
    this.emailProfileService.getEmailProfiles().subscribe(
      (profiles: EmailProfile[]) => {
        this.profiles = profiles;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving profiles", error);
      }
    );
  }

  onDeleteEmailProfile(emailProfile: EmailProfile) {
    this.confirmationService.confirm({
      header: 'Delete Profile',
      message: 'Are you sure you want to delete the profile ' + emailProfile.name + '?',
      accept: () => {
        emailProfile.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.emailProfileService.deleteEmailProfile(emailProfile).subscribe(
          (response: Response) => {
            this.onGetEmailProfiles();
            this.toastService.createSuccessMessage("Success", "The profile " + emailProfile.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting profile", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for profile " + emailProfile.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

}