import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from "@angular/http";

import { AppSettings } from 'app.settings'

import { AuthService } from "../../../auth/auth.service";
import { OpportunityService } from "../../../shared/services/opportunity.service";
import { OpportunityFileService } from "../../../shared/services/opportunity-file.service";
import { ToastService } from "../../../shared/services/toast.service";

import { Opportunity } from "../../../shared/models/opportunity.model";
import { OpportunityFile } from "../../../shared/models/opportunity-file.model";

import { DropzoneConfigInterface } from "ngx-dropzone-wrapper/dist";
import { ConfirmationService } from "primeng/primeng";

@Component({
  selector: 'app-opportunity-file-list',
  templateUrl: './opportunity-file-list.component.html',
  styleUrls: ['./opportunity-file-list.component.scss']
})
export class OpportunityFileListComponent implements OnInit {
  opportunityFiles: OpportunityFile[] = [];
  opportunity: Opportunity = new Opportunity();

  headingText: string = "File Uploads for ";

  crmApiEndPoint: string = AppSettings.API_ENDPOINT;

  //** Dropzone */
  DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: this.crmApiEndPoint + 'api/opportunityfiles/',
    headers: {
      'Authorization': 'Bearer ' + this.authService.getUser().access_token
    },
    paramName: "file",
    parallelUploads: 1,
    errorReset: 3,
    acceptedFiles: "image/*, application/pdf, application/msword, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.template, application/vnd.ms-word.document.macroEnabled.12, application/vnd.ms-word.template.macroEnabled.12, application/vnd.ms-excel, application/vnd.ms-excel, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.template, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-powerpoint, application/vnd.ms-powerpoint, application/vnd.ms-powerpoint, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.template, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.ms-powerpoint.addin.macroEnabled.12, application/vnd.ms-powerpoint.presentation.macroEnabled.12, application/vnd.ms-powerpoint.template.macroEnabled.12, application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    maxFilesize: 50 // - 50MB
    // autoReset: 1,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private opportunityService: OpportunityService,
    private opportunityFileService: OpportunityFileService,
    private authService: AuthService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe(
      (parentParams: Params) => {
        const opportunityId = +parentParams['id'];

        if (!opportunityId) {
          this.router.navigate(['/error/404']);
          return;
        }

        this.opportunityService.getOpportunity(opportunityId).subscribe(
          (opportunity: Opportunity) => {
            this.opportunity = opportunity;

            let headingTextSuffix = this.opportunity.name;

            this.headingText += headingTextSuffix;

            this.onGetOpportunityFiles(); // - Get opportunity files after opportunity is retrieved
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error retrieving opportunity", error);
          }
        );

        // - Listen to changes made to opportunity files to update table
        this.opportunityFileService.opportunityFilesChanged.subscribe(
          () => {
            this.onGetOpportunityFiles();
          }
        );
      }
    );
  }

  onGetOpportunityFiles() {
    this.opportunityFileService.getOpportunityFilesByOpportunityId(this.opportunity.id).subscribe(
      (opportunityFiles: OpportunityFile[]) => {
        this.opportunityFiles = opportunityFiles;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunity files", error);
      }
    );
  }

  onDeleteOpportunityFile(opportunityFile: OpportunityFile) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the opportunity file ' + opportunityFile.displayFileName + '?',
      accept: () => {
        opportunityFile.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.opportunityFileService.deleteOpportunityFile(opportunityFile).subscribe(
          (response: Response) => {
            this.opportunityFileService.opportunityFilesChanged.next();
            this.toastService.createSuccessMessage("Success", "The opportunity file " + opportunityFile.displayFileName + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting opportunity file", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for opportunity file " + opportunityFile.displayFileName + " has been aborted.");
      }
    });
  }

  onUploadError($event) {
    this.toastService.createErrorMessage("Error uploading files", $event[2] ? $event[2].response : $event[1]);
  }

  onUploadSuccess($event) {
    this.toastService.createSuccessMessage("Success", "The file has been uploaded.");
  }

  onQueueComplete($event) {
    this.opportunityFileService.opportunityFilesChanged.next();
  }

  onSending($event) {
    let file = $event[0];
    let xmlHttpRequest = $event[1];
    let formData = $event[2];

    formData.append("opportunityId", this.opportunity.id);
    formData.append("createdByUserAccountId", this.authService.applicationProfileUser().id);
  }
}
