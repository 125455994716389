import { Component, OnInit } from '@angular/core';
import { Response } from "@angular/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { AuthService } from "../auth.service";
import { ToastService } from "../../shared/services/toast.service";

import { CrmUser } from "../../shared/models/user.model";
import { ResetPasswordModel } from '../../shared/models/reset-password.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  isFormInit: boolean = false;

  resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.route.queryParams.subscribe(
      (params: Params) => {
        let code = params['code'];

        this.resetPasswordForm = this.formBuilder.group({
          email: this.formBuilder.control(this.resetPasswordModel.email, [Validators.required, Validators.email]),      
          password: this.formBuilder.control(this.resetPasswordModel.password, [Validators.required]),      
          confirmPassword: this.formBuilder.control(this.resetPasswordModel.confirmPassword, [Validators.required]),
          code: this.formBuilder.control(code),
        });
    
        this.isFormInit = true;
       
      }
    );

 
  }

  onSubmit() {  
    
    this.resetPasswordModel.email = this.resetPasswordForm.value.email;
    this.resetPasswordModel.password = this.resetPasswordForm.value.password;
    this.resetPasswordModel.confirmPassword = this.resetPasswordForm.value.confirmPassword;    
    this.resetPasswordModel.code = this.resetPasswordForm.value.code;
    
    this.authService.resetPasswordConfirm(this.resetPasswordModel).subscribe(
      (response: Response) => {
        if (response) {
            this.router.navigate(["/login"]);            
        }
        else
          console.log("The access token could not be generated.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Reset Password Failed", error);
      }
    );
  }
}
