import { Pipe, PipeTransform } from '@angular/core';

import { Task } from "../models/task.model";
import { TaskService } from "app/views/shared/services/task.service";

@Pipe({
    name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {
    constructor(private taskService: TaskService) { }

    transform(task: Task) {
        if (task.isTaskComplete)
            return "Complete";
        else if (this.taskService.isTaskForToday(task))
            return "Today";
        else if (this.taskService.isTaskLate(task))
            return "Late";
        else
            return "Pending";
    }
}