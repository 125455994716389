import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { ContactService } from '../../shared/services/contact.service';
import { SmsWarriorsService } from '../../shared/services/sms-warriors.service';
import { SmsWarriorsProfileService } from '../../shared/services/sms-warriors-profile.service';
import { ToastService } from '../../shared/services/toast.service';

import { ConfirmationService } from 'primeng/primeng';

import { SmsWarriorsProfile } from '../../shared/models/sms-warriors-profile.model';

@Component({
  selector: 'app-message-centre-config-smswarriors',
  templateUrl: './message-centre-config-smswarriors.component.html',
  styleUrls: ['./message-centre-config-smswarriors.component.scss']
})
export class MessageCentreConfigSmswarriorsComponent implements OnInit {
  profiles: SmsWarriorsProfile[] = [];

  constructor(
    public authService: AuthService,
    private smsWarriorsProfileService: SmsWarriorsProfileService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetSmsWarriorsProfiles();

    // Listen to changes made to profiles
    this.smsWarriorsProfileService.smsWarriorsProfilesChanged.subscribe(
      () => {
        this.onGetSmsWarriorsProfiles();
      }
    );
  }

  onGetSmsWarriorsProfiles() {
    this.smsWarriorsProfileService.getSmsWarriorProfiles().subscribe(
      (profiles: SmsWarriorsProfile[]) => {
        this.profiles = profiles;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving profiles", error);
      }
    );
  }

  onDeleteSmsWarriorsProfile(smsWarriorsProfile: SmsWarriorsProfile) {
    this.confirmationService.confirm({
      header: 'Delete Profile',
      message: 'Are you sure you want to delete the profile ' + smsWarriorsProfile.name + '?',
      accept: () => {
        smsWarriorsProfile.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.smsWarriorsProfileService.deleteSmsWarriorProfile(smsWarriorsProfile).subscribe(
          (response: Response) => {
            this.onGetSmsWarriorsProfiles();
            this.toastService.createSuccessMessage("Success", "The profile " + smsWarriorsProfile.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting profile", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for profile " + smsWarriorsProfile.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

}