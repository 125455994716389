import { CrmUser } from "./user.model";
import { Contact } from "./contact.model";
import { Task } from "./task.model";

export class Email {
    constructor(
        public userReceiver: CrmUser = null,
        public contactReceiver: Contact = null,

        public contactIds: number[] = [],
        public sentByUserId: number = null,

        public subject: string = null,
        public body: string = null,
        public task: Task = null,
        public calendarDuration: number = null
    ) { }
}