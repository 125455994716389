
export class Address {
  constructor(
    public id: string = null,
    public addressLine1: string = null,
    public addressLine2: string = null,
    public addressLine3: string = null,
    public addressLine4: string = null,
    public postCode: string = null,
    public country: string = null,
    public locationLat: string = null,
    public locationLng: string = null,
  ) { }
}
