import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { AuthHttp } from 'angular2-jwt';
import { AuthService } from '../../auth/auth.service';
import { LoadingSpinnerService } from './loading-spinner.service';

import { Campaign, List, MailChimpConfig } from '../../shared/models/mailchimp/campaign.model';

import { ApiEndPoints } from 'app.constant';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { AppSettings } from 'app.settings';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class MailchimpService {
  private url: string = AppSettings.API_ENDPOINT + 'api/MailChimp/';

  public configChanged = new Subject();
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingSpinnerService
  ) { }

  getMailChimpConfig(): Observable<MailChimpConfig> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Config").map(
      (response: Response) => {
        let mailChimpConfig: MailChimpConfig = (<any>response);

        return mailChimpConfig;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addMailChimpConfig(mailChimpConfig: MailChimpConfig): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url + "Config",
      mailChimpConfig
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateMailChimpConfig(mailChimpConfig: MailChimpConfig): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Config/" + mailChimpConfig.id,
      mailChimpConfig
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getLists(count: number = 99999): Observable<List[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Lists?count=" + count).map(
      (response: Response) => {
        let lists: List[] = (<any>response);

        return lists;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateCommunicationLogsFromList(id: string): Observable<number> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Lists/" + id).map(
      (response: Response) => {
        return +(<any>response).value;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCampaigns(count: number = 99999): Observable<Campaign[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Campaigns?count=" + count).map(
      (response: Response) => {
        let campaigns: Campaign[] = (<any>response);

        return campaigns;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateCommunicationLogsFromCampaign(id: string): Observable<Campaign[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Campaigns/" + id).map(
      (response: Response) => {
        return (<any>response);
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
