import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})

export class LandPurchaseOpportunityByIdOppIdQueryGQL extends Query {
  document = LAND_PURCHASE_OPP_BY_OPP_ID_QUERY;
}

const LAND_PURCHASE_OPP_BY_OPP_ID_QUERY =
gql`
query ($id: Int!) {
  landPurchaseOpportunityByOppId (id: $id) {
    data {
      id
      code
      details
      plotDescription
      sellingPrice
      address {
        id
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        postCode
        country
        locationLat
        locationLng
      }
      seller {
        id
        fullName

        organization {
          id
          name
          email
        }
      }

      opportunity {
        id
        value
        probability
        name
        lostTypeDescription

        opportunityLostType {
          id
          name
        }
        opportunityCategoryType {
          id
          name
        }
        opportunityType {
          id
          name
        }

        assignedUserAccount {
          id
          fullName
        }
      }

      project {
        id
        code
        name
        startDate
        dueDate

        projectManager {
          id
          subjectId
          firstName
          lastName
        }

      }

      dealTypeValue {
        id
        barterValue
        cashValue
        partCashValue
        partBarterValue

        dealTypeOpportunity {
          id
          name
        }
      }

    }
  }
}
`;
