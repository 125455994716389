import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-desk',
  templateUrl: './lead-desk.component.html',
  styleUrls: ['./lead-desk.component.scss']
})
export class LeadDeskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
