import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { ContactSourceService } from "../../../shared/services/contact-source.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { ContactSource } from "../../../shared/models/contact-source.model";

import { valueExistsValidator } from "../../../shared/validators/value-exists.validator";

@Component({
  selector: 'app-contact-source-manage',
  templateUrl: './contact-source-manage.component.html',
  styleUrls: ['./contact-source-manage.component.scss']
})
export class ContactSourceManageComponent implements OnInit {
  contactSourceForm: FormGroup;       // - Stores the contactSource form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a contactSource if true and add a new contactSource if false

  contactSources: ContactSource[] = [];             // - Stores all contactSources retrieved from db
  contactSource: ContactSource = new ContactSource(); // - Stores ContactSource to populate form with a new / existing ContactSource's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private contactSourceService: ContactSourceService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.contactSourceService.getContactSources().subscribe(
      (contactSources: ContactSource[]) => {
        this.contactSources = contactSources; // - Store all contact sources retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const contactSourceId = params['id'];
            this.isEdit = contactSourceId != null;

            if (this.isEdit) {
              this.contactSource = this.contactSources.find(cSource => cSource.id === +contactSourceId); // - Store the selected contactSource

              // Redirect to 404 page if the selected contactSource could not be found
              if (!this.contactSource) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact sources", error);
      }
    );
  }

  initForm() {
    // Get all contactSource names to know if it already exists
    const contactSourceNames = this.contactSources.map(cSource => cSource.name);

    this.contactSourceForm = this.formBuilder.group({
      name: this.formBuilder.control(this.contactSource.name, [Validators.required, valueExistsValidator(contactSourceNames)])
    });
    this.isFormInit = true;
  }

  onAddContactSource(newContactSource: ContactSource) {
    this.contactSourceService.addContactSource(newContactSource).subscribe(
      (response: Response) => {
        this.contactSourceService.sourcesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The contact source " + newContactSource.name + " has been created.");
        this.router.navigate(['/contacts/sources']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding contact source", error);
      }
    );
  }

  onUpdateContactSource(newContactSource: ContactSource) {
    this.contactSourceService.updateContactSource(newContactSource).subscribe(
      (response: Response) => {
        this.contactSourceService.sourcesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The contact source " + newContactSource.name + " has been updated.");
        this.router.navigate(['/contacts/sources']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating contact source", error);
      }
    );
  }

  onSubmit() {
    let newContactSource = this.formBuilder.sanitizeFormValues(this.contactSourceForm).value;

    if (this.isEdit) {
      newContactSource.id = this.contactSource.id; // - Set id of edited contactSource to its original id
      newContactSource.createdByUserAccountId = this.contactSource.createdByUserAccountId; // - Need createdById of original contact source to check if it is a prepopulated source
      newContactSource.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateContactSource(newContactSource);
    }
    else {
      newContactSource.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddContactSource(newContactSource);
    }
  }
}
