import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { SmsWarriorsProfile } from '../models/sms-warriors-profile.model';

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Rx";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SmsWarriorsProfileService {
  private url: string = AppSettings.API_ENDPOINT + 'api/smswarriorprofiles/';

  public smsWarriorsProfilesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getSmsWarriorProfiles(): Observable<SmsWarriorsProfile[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let smsWarriorProfiles: SmsWarriorsProfile[] = (<any>response);
        smsWarriorProfiles = smsWarriorProfiles.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return smsWarriorProfiles;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getSmsWarriorProfile(id: number): Observable<SmsWarriorsProfile> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let smsWarriorProfile: SmsWarriorsProfile = (<any>response);
        // smsWarriorProfile.createdByUserAccount = this.userService.getUser(smsWarriorProfile.createdByUserAccountId);
        // smsWarriorProfile.updatedByUserAccount = this.userService.getUser(smsWarriorProfile.updatedByUserAccountId);
        return smsWarriorProfile;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addSmsWarriorProfile(smsWarriorProfile: SmsWarriorsProfile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      smsWarriorProfile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateSmsWarriorProfile(smsWarriorProfile: SmsWarriorsProfile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + smsWarriorProfile.id,
      smsWarriorProfile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteSmsWarriorProfile(smsWarriorProfile: SmsWarriorsProfile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + smsWarriorProfile.id,
      smsWarriorProfile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
