import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { AuthRoutingModule } from "./auth-routing.module";
import { ReactiveFormsModule } from "@angular/forms";

import { SigninComponent } from "./signin/signin.component";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


import { Http, RequestOptions } from '@angular/http';
import { AuthHttp, AuthConfig, JwtHelper } from 'angular2-jwt';

import { CrmUser } from "../shared/models/user.model";
import { AuthService } from "../auth/auth.service";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// export function authHttpServiceFactory(http: Http, options: RequestOptions) {
//   return new AuthHttp(new AuthConfig({
//     tokenName: 'accessToken',
//     tokenGetter: (() => localStorage.getItem('accessToken')),
//     globalHeaders: [{ 'Content-Type': 'application/json' }]
//   }), http, options);
// }

@NgModule({
  declarations: [
    SigninComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    // {
    //   provide: AuthHttp,
    //   useFactory: authHttpServiceFactory,
    //   deps: [Http, RequestOptions]
    // }
  ]
})
export class AuthModule { }
