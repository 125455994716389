import { Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from 'app/shared/api.service';

export class SYSTEM_KEYS {
  static ACQUISITION: string = "ACQUISITION";
  static SALES: string = "SALES";
}

export class SystemConfiguration {
  constructor(public keyConfiguration:string) {

  }
}

@Injectable()
export class SystemConfigurationService {

  constructor(
    private _apiService: ApiService
  ) { }

  public getConfiguration(): Observable<SystemConfiguration> {
    return this._apiService.get<SystemConfiguration>(`api/system`);
  }
}



@Injectable()
export class SystemModeHelper {
  public systemKey: BehaviorSubject<string> = new BehaviorSubject("");
  public SystemConfiguration: BehaviorSubject<string> = new BehaviorSubject(<string><any>localStorage.getItem("system-configuration"));

  constructor() {
   }

   next(val: string) {     
    localStorage.setItem('system-configuration', val);// JSON.stringify(val));
    this.SystemConfiguration.next(val);
   }
  }