
import { Subscription } from "rxjs";
import { OnDestroy, OnInit } from "@angular/core";
import { LifetimeManagement } from "./lifetime-management";
import { SystemModeHelper, SystemConfiguration } from './system-config.service';



export interface ISystem {

  // Property / Field
  // __systemKey : string;
  // showDeveloperModeLabel : boolean;

  // Getter
  // isDeveloperModeEnabled : boolean;

  systemKey$ : Subscription;
  // developerMode$ : Subscription;
}

export abstract class System extends LifetimeManagement implements ISystem, OnInit, OnDestroy {
  constructor(protected system: SystemModeHelper) {
    super();
   };
  
  ngOnInit(): void {
    super.ngOnInit();

    this.systemKey$ = this.system.systemKey.subscribe((systemKey) => {
       this.__systemKey = systemKey;
    });

    this.systemKey$ = this.system.SystemConfiguration.subscribe((systemKey : string) => {
      this.__systemKey = systemKey;
    });

    this.subscriptions$.push(this.systemKey$);
    
    // this.subscriptions$.push(this.developerMode$);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private __systemKey = "";
  // public showDeveloperModeLabel = false;

  get systemKey() {
    return this.__systemKey;
  }

  systemKey$ = null;
  // developerMode$ = null;

}
