import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Observable } from 'rxjs/Observable';
import { AppSettings } from 'app.settings';


@Injectable()
export class ApiService {
  private endPoint: string = `${AppSettings.API_ENDPOINT}`;

  constructor(
    private _http: HttpClient
  ) { }

  public get<T>(url: string): Observable<T> {
    return this._http.get<T>(`${this.endPoint}${url}`).map(
      (results: T) => {
        return results;
      }
    );
  }

  public postWithoutRequestMessage<T, U = T>(url: string, object: T): Observable<U> {
    return this._http.post<U>(`${this.endPoint}${url}`, object);
  }
}
