import { Injectable } from '@angular/core';
import { DataProxy } from 'apollo-cache';
import { DocumentNode } from 'apollo-link';

interface Query<TVariables> {
  query: DocumentNode;
  variables?: TVariables;
}

@Injectable({
  providedIn: 'root'
})
export class DataProxyExtensions {

  public readQuery<QueryType, TVariables = any>(proxy: DataProxy, options: DataProxy.Query<TVariables>): QueryType | undefined {
    try {
      const data = proxy.readQuery<QueryType>({ query: options.query, variables: options.variables });
      return data;
    } catch {
      return undefined;
    }
  }
}