import { Injectable } from '@angular/core';
import { Response, Http, Headers, Request, RequestMethod, RequestOptions, ResponseContentType, Jsonp } from "@angular/http";

import { LoadingSpinnerService } from './loading-spinner.service';

import { SmsWarriors, ApiEndPoints } from 'app.constant';
import { Observable } from 'rxjs/Observable';
import { SmsWarriorsResponse } from '../models/sms-warriors-response.model';
import { AuthHttp } from 'angular2-jwt';
import { AppSettings } from 'app.settings';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SmsWarriorsService {
  // private url: string = `${SmsWarriors.ApiEndPoint}`;
  private url: string = AppSettings.API_ENDPOINT + 'api/externalapi/';


  constructor(
    private http: HttpClient,
    private loadingService: LoadingSpinnerService
  ) { }

  requestSMS(apiKey: string, countryCode: string, contactIds: any[], sentByUserId: number, senderId: string, msgBody: string, notification: number) {
    senderId = encodeURIComponent(senderId);        // - Max: 18 numbers OR 11 text characters
    msgBody = encodeURIComponent(msgBody);          // - Max: 500 chars

    let smsUrl = `${this.url}`;

    this.loadingService.displayLoadingSpinner();


    let postObj = {
      key: apiKey,
      countryCode: countryCode,
      contactIds: contactIds,
      contacts: null,
      sentByUserId: sentByUserId,
      senderid: senderId,
      msg: msgBody,
      notification: 0
    };

    return this.http.post<Response>(smsUrl, postObj).map(
      (response: Response) => {

        let smsWarriorsResponse: SmsWarriorsResponse = (<any>response);
        return smsWarriorsResponse;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  // requestSMS(apiKey: string, contactNumbers: string, senderId: string, msgBody: string, notification: number) {
  //   senderId = encodeURIComponent(senderId);        // - Max: 18 numbers OR 11 text characters
  //   msgBody = encodeURIComponent(msgBody);          // - Max: 500 chars

  //   let smsUrl = `${this.url}?key=${apiKey}&contacts=${contactNumbers}&senderid=${senderId}&msg=${msgBody}&notification=${notification}`;

  //   this.loadingService.displayLoadingSpinner();


  //   return this.http.get(smsUrl).map(
  //     (response: Response) => {
  //       let smsWarriorsResponse: SmsWarriorsResponse = (<any>response);
  //       return smsWarriorsResponse;
  //     }
  //   ).finally(() => this.loadingService.hideLoadingSpinner());
  // }

}
