import { NgModule } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { BasicLayoutComponent } from './basicLayout.component';
import { BlankLayoutComponent } from './blankLayout.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { TopNavigationLayoutComponent } from './topNavigationLayout.component';
import { TopNavbarComponent } from '../topnavbar/topnavbar.component';
import { TopNavigationNavbarComponent } from '../topnavbar/topnavigationnavbar.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap';
import { SharedModule } from 'app/views/shared/shared.module';

@NgModule({
  declarations: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent
  ],
  imports: [
    RouterModule,
    BsDropdownModule.forRoot(),
    SharedModule
  ],
  exports: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent
  ],
})

export class LayoutsModule { }
