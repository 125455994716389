import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../auth/auth.service";
import { ComplaintService } from "../../shared/services/complaint.service";
import { ToastService } from "../../shared/services/toast.service";
import { ConfirmationService, SelectItem } from "primeng/primeng";
import { TableUtilityService } from '../../shared/services/table-utility.service';
import { CookieService } from 'ngx-cookie';

import { Complaint } from "../../shared/models/complaint.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-complaint-list',
  templateUrl: './complaint-list.component.html',
  styleUrls: ['./complaint-list.component.scss']
})
export class ComplaintListComponent implements OnInit {
  complaints: Complaint[] = [];
  isAnim: boolean = true;

   /** Column Toggling */
   columnOptions: SelectItem[];
   selectedCols: any[];              // - Contains the table columns that should be visible
   allSelectableCols: any[] = [];    // - Contains all table columns that may be toggled on or off
 
   selectedColsDefault: any[] = [];  // - Contains the columns that are shown by default if no cookie is stored
 
  constructor(
    public authService: AuthService,
    private complaintService: ComplaintService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cookieService: CookieService,    
    private tableUtilService: TableUtilityService
  ) { }

  ngOnInit() {
    this.onGetComplaints();    
   
    // Listen to changes made to complaints
    this.complaintService.typesChanged.subscribe(
      () => {
        this.onGetComplaints();
      }
    );
  }  
 
  isColVisible(colName: string) {
    if (this.selectedCols.find(sC => sC.value === colName))
      return true;

    return false;
  }

  onGetComplaints() {
    this.complaintService.getComplaints().subscribe(
      (complaints: Complaint[]) => {
        this.complaints = complaints;
        console.log(this.complaints);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving complaints", error);
      }
    );
  }

  onDeleteComplaint(complaint: Complaint) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the complaint ' + complaint.code + '?',
      accept: () => {
        complaint.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.complaintService.deleteComplaint(complaint).subscribe(
          (response: Response) => {
            this.onGetComplaints();
            this.toastService.createSuccessMessage("Success", "The complaint " + complaint.code + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting complaint", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for complaint " + complaint.code + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

}

