import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateLandPurchaseOpportunityMutation extends Mutation {
  document = CREATE_LAND_PURCHASE_MUTATION;
}

export const CREATE_LAND_PURCHASE_MUTATION =
gql`
  mutation ($item: LandPurchaseOpportunityInput!) {
    createLandPurchaseOpportunity (item: $item) {
      data {
        id
        code
        details
        plotDescription
        sellingPrice

        address {
          id
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          postCode
          country
          locationLat
          locationLng
        }
        seller {
          id
          fullName

          organization {
            id
            name
            email
          }
        }

        opportunity {
          id
          value
          probability
          name

          opportunityCategoryType {
            id
            name
          }

          opportunityType {
            id
            name
          }

          assignedUserAccount {
            id
            fullName
          }
        }

        dealTypeValue {
          id
          barterValue
          cashValue
          partCashValue
          partBarterValue

          dealTypeOpportunity {
            id
            name
          }
        }

      }
    }
  }
`;
