import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";
import { RoleGuard } from '../auth/role-guard.service';

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { MailchimpComponent } from './mailchimp.component';
import { MailchimpListComponent } from './mailchimp-list/mailchimp-list.component';
import { MailchimpConfigComponent } from './mailchimp-list/mailchimp-config/mailchimp-config.component';

const mailchimpRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    // children: [
    //   {
    //     path: 'mailchimp', component: MailchimpComponent,
    //     children: [
    //       { path: '', redirectTo: 'list', pathMatch: 'full' },
    //       { path: 'list', component: MailchimpListComponent },
    //       { path: 'config', component: MailchimpConfigComponent },
    //     ]
    //   }
    // ]
    children: [
      {
        path: 'mailchimp', component: MailchimpComponent,
        children: [
          {
            path: '', component: MailchimpListComponent, children: [
              { path: 'config', component: MailchimpConfigComponent }
            ]
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(mailchimpRoutes)],
  exports: [RouterModule]
})
export class MailchimpRoutingModule { }