import { Component, OnInit } from '@angular/core';

import { UserService } from "../../shared/services/user.service";
import { AuthService } from "../../auth/auth.service";
import { ComplaintTypeService } from "../../shared/services/complaint-type.service";
import { ComplaintService } from '../../shared/services/complaint.service';

import { CrmUser } from "../../shared/models/user.model";
import { ComplaintType } from "../../shared/models/complaint-type.model";
import { ComplaintStatus } from 'app/views/shared/models/complaint-status.model';

import { SelectItem } from "primeng/primeng";
import { Response } from "@angular/http";
import { AppSettings } from 'app.settings'


@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss']
})
export class ComplaintsComponent implements OnInit {
  users: CrmUser[] = [];               // - Stores all contacts to populate dropdown options
  usersOptions: SelectItem[] = [];  // - Stores Assign User Dropdown Options
  complaintTypeOptions: SelectItem[] = [];     // - Stores Complaint Types (Closed, Lost, Open)
  complaintStatusOptions: SelectItem[] = []; // - Stores Complaint Statuses

  selectedUserOption: any;          // - Stores the selected dropdown option which can be 'all' or the id of a contact
  complaintTypeId: number = 0;
  complaintStatusId: any;
  fromDate: Date = null;
  toDate: Date = null;

  crmApiEndPoint: string = AppSettings.API_ENDPOINT;

  accessToken = this.authService.currUser().access_token;//localStorage.getItem('accessToken');

  // Datepicker
  dateRange: string = (new Date().getFullYear() - 150) + ':' + new Date().getFullYear(); // - Range: 150 Years Ago until Today

  constructor(
    public authService: AuthService,
    public complaintTypeService: ComplaintTypeService,
    public complaintService: ComplaintService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (this.authService.isAdmin()) {
      this.usersOptions.push({ label: 'All', value: 'all' });
      this.complaintStatusOptions.push({ label: 'All', value: 'all' });
      this.complaintTypeOptions.push({ label: 'All', value: 'all' });
    }



    this.onGetUsers();
    this.onGetComplaintTypes();
    this.onGetComplaintStatuses();
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Only Admins can get reports about all users
        if (this.authService.isAdmin())
          users.map(u => this.usersOptions.push({ label: u.fullName, value: u.id }));
        else {
          let user = users.find(u => u.id === this.authService.applicationProfileUser().id);
          this.usersOptions.push({ label: user.fullName, value: user.id });
          this.selectedUserOption = user.id;
        }
      }
    );
  }

  onGetComplaintTypes() {
    this.complaintTypeService.getComplaintTypes().subscribe(
      (complaintTypes: ComplaintType[]) => {
        complaintTypes.map(oT => this.complaintTypeOptions.push({ label: oT.name, value: oT.id }));
        //this.complaintTypeId = complaintTypes[0].id; // - First option as default option
      }
    );
  }

  onGetComplaintStatuses() {
    this.complaintService.getComplaintStatuses().subscribe(
      (complaintStatuses: ComplaintStatus[]) => {
        complaintStatuses.map(oT => this.complaintStatusOptions.push({ label: oT.name, value: oT.id }));
        //this.complaintStatusId = complaintStatuses[0].id; // - First option as default option
      }
    );
  }
}
