import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-files',
  templateUrl: './contact-files.component.html',
  styleUrls: ['./contact-files.component.scss']
})
export class ContactFilesComponent {
  
}
