import { NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";

import { HomeComponent } from "./home.component";

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    BrowserModule
  ]
})
export class HomeModule { }
