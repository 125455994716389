import { Injectable } from '@angular/core';
import { Http, Response } from "@angular/http";

import { LoadingSpinnerService } from "./loading-spinner.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";

import { CrmUser } from "../models/user.model";
import { RegisterModel } from "../models/register.model";
import { ResetPasswordModel } from '../models/reset-password.model';

import { AppSettings } from 'app.settings'
import { AuthHttp } from "angular2-jwt/angular2-jwt";
import { ForgotPasswordModel } from '../models/forgot-password.model';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class UserService {
  private usersUrl: string = AppSettings.API_ENDPOINT + 'api/account/';

  private users: CrmUser[] = [];

  constructor(
    private http: HttpClient,
    private loadingService: LoadingSpinnerService
  ) { }

  getUsers() {
    return this.http.get(this.usersUrl).map(
      (response: Response) => {
        let users: CrmUser[] = (<any>response);

        this.users = users.map(
          u => {
            u.createdByUserAccount = this.getUser(u.createdByUserAccountId);
            u.updatedByUserAccount = this.getUser(u.updatedByUserAccountId);
            return u;
          }
        );
        return this.users;
      }
    );
  }

  getUsersAvailable() {
    return this.http.get(this.usersUrl + 'getUsersAvailable').map(
      (response: Response) => {
        let users: CrmUser[] = (<any>response);

        this.users = users.map(
          u => {
            //u.createdByUserAccount = this.getUser(u.createdByUserAccountId);
            //u.updatedByUserAccount = this.getUser(u.updatedByUserAccountId);
            return u;
          }
        );
        return this.users;
      }
    );
  }

  getUser(id: number): CrmUser {
    return this.users.find(u => u.id === id);
  }

  getUserByApplicationUserId(applicationUserId: string): CrmUser {
    return this.users.find(u => u.applicationUserId === applicationUserId);
  }

  // setUsers(users: User[]): void {
  //   this.users = users;
  // }

  registerUser(registerModel: RegisterModel) {
    return this.http.post<Response>(
      this.usersUrl + "register",
      registerModel
    );
  }

  updateUser(registerModel: RegisterModel) {
    return this.http.put<Response>(
      this.usersUrl + "update",
      registerModel
    );
  }

  isUserAdmin(user: CrmUser) {
    return (user.roles.includes('Admin'));
  }

  isUserSuperAdmin(user: CrmUser) {
    return (user.roles.includes('Super Admin'));
  }

  deleteUser(registerModel: RegisterModel) {
    return this.http.put<Response>(
      this.usersUrl + "delete",
      registerModel
    );
  }


}
