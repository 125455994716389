import { CrmUser } from "./user.model";
import { Contact } from "./contact.model";
import { NullTemplateVisitor } from '@angular/compiler';
import { PaymentTerm } from './payment-term.model';

export class IAuditableExtended {
  constructor(
    public createdByUserAccount: CrmUser = null,
    public createdByUserAccountId: number= null,
    public updatedByUserAccount: CrmUser = null,
    public updatedByUserAccountId: number = null,) {

  }

}
export class Organization extends IAuditableExtended {
    constructor(
        public id: number = 0,
        public name : string = null,
        public email : string = null,
        public mobilePhoneNumber : string = null,
        public officePhoneNumber : string = null,
        public addressLine1 : string = null,
        public addressLine2  : string = null,
        public addressLine3 : string = null,
        public addressLine4  : string = null,
        public defaultContact: Contact = new Contact(),
        public defaultContactId: number = null,
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null,
        public paymentTerm: PaymentTerm = new PaymentTerm,
        public paymentTermId: number = null,
    ) {
      super(createdByUserAccount,
       createdByUserAccountId,
       updatedByUserAccount,
       updatedByUserAccountId)
      }
}
