import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { EmailProfile } from '../models/email-profile.model';

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Rx";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class EmailProfileService {
  private url: string = AppSettings.API_ENDPOINT + 'api/emailprofiles/';

  public emailProfilesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getEmailProfiles(): Observable<EmailProfile[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();

    let getUrl = this.url;

    if (!this.authService.isAdmin())
      getUrl += "UserParty/" + this.authService.applicationProfileUser().id;

    return this.http.get(getUrl).map(
      (response: Response) => {
        let emailProfiles: EmailProfile[] = (<any>response);
        emailProfiles = emailProfiles.map(
          cT => {
            // cT.userParty = this.userService.getUser(cT.userPartyId);
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return emailProfiles;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getEmailProfile(id: number): Observable<EmailProfile> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let emailProfile: EmailProfile = (<any>response);
        // emailProfile.userParty = this.userService.getUser(emailProfile.userPartyId);
        // emailProfile.createdByUserAccount = this.userService.getUser(emailProfile.createdByUserAccountId);
        // emailProfile.updatedByUserAccount = this.userService.getUser(emailProfile.updatedByUserAccountId);
        return emailProfile;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addEmailProfile(emailProfile: EmailProfile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      emailProfile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateEmailProfile(emailProfile: EmailProfile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + emailProfile.id,
      emailProfile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteEmailProfile(emailProfile: EmailProfile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + emailProfile.id,
      emailProfile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
