import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { UserService } from "../../shared/services/user.service";
import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";

import { CrmUser } from "../../shared/models/user.model";
import { RegisterModel } from "../../shared/models/register.model";

import { valueExistsValidator } from "../../shared/validators/value-exists.validator";
import { CustomValidators } from 'ng2-validation';
import { Observable } from "rxjs/Rx";
import { UpdateModel } from "app/views/shared/models/update.model";
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { AppSettings } from 'app.settings';

@Component({
  selector: 'app-account-manage',
  templateUrl: './account-manage.component.html',
  styleUrls: ['./account-manage.component.scss']
})
export class AccountManageComponent implements OnInit {
  userForm: FormGroup;                   // - Stores the user form's values
  isFormInit: boolean = false;              // - The form is not created until it is initialised
  isEdit: boolean = false;                  // - The form is set to update a user if true and add a new user if false

  users: CrmUser[] = [];                 // - Stores all users retrieved from db to validate unique values
  user: CrmUser = new CrmUser();         // - Stores User to populate form with a new / existing user's values

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        this.users = users; // - Store all users retrieved from db


        this.route.params.subscribe(
          (params: Params) => {
            const userId = params['id'];
            this.isEdit = userId != null; // - Returns true if an id was present within the url


            if (this.isEdit) {
              // this.user = this.users.find(c => c.id === +userId); // - Store the selected user
              this.user = this.users.find(c => c.id === userId); // - Store the selected user

              // Redirect to 404 page if the selected user could not be found
              if (!this.user) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving users", error);
      }
    );
  }

  initForm() {
    // Get all emails to know if a user with that email already exists
    const userEmails = this.users.map(c => c.email);

    // Remove the user to edit's email if being edited so the email can remain unchanged
    if (this.isEdit)
      userEmails.splice(userEmails.indexOf(this.user.email), 1);

    this.userForm = this.formBuilder.group({
      firstName: this.formBuilder.control(this.user.firstName, [Validators.required]),
      lastName: this.formBuilder.control(this.user.lastName, [Validators.required]),
      email: this.formBuilder.control(this.user.email, [valueExistsValidator(userEmails)]),
      password: this.formBuilder.control(null),
      confirmPassword: this.formBuilder.control(null),
      adminRole: this.formBuilder.control(this.user.appUserRoles.length > 0 ? this.authService.isProfileAdmin() : false)
    });

    this.isFormInit = true;
  }

  onRegisterUser(newUser: RegisterModel) {
    let fullName = newUser.firstName + " " + newUser.lastName;
    this.userService.registerUser(newUser).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The user " + fullName + " has been created.");
        this.router.navigate(['/accounts/']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding user", error);
      }
    );
  }

  onUpdateUser(newUser: UpdateModel) {
    let fullName = newUser.firstName + " " + newUser.lastName;
    this.userService.updateUser(newUser).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The user " + fullName + " has been updated.");
        this.router.navigate(['/accounts/']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating user", error);
      }
    );
  }

  onSubmit() {
    let newRegisterModel = this.formBuilder.sanitizeFormValues(this.userForm).value;

    if (this.isEdit) {
      newRegisterModel.roles = this.user.roles;
    }
    else
      newRegisterModel.roles = []; // - Initialise roles array or it wont be posted

    // If Admin checkbox was ticked add Admin Role (unless that role was already assigned)
    if (newRegisterModel.adminRole && !newRegisterModel.roles.includes("Admin")) {
      newRegisterModel.roles.push("Admin");
    }

    // If Admin checkbox was unticked then remove Admin Role if that role was previously assigned
    if (!newRegisterModel.adminRole && newRegisterModel.roles.includes("Admin")) {
      newRegisterModel.roles = newRegisterModel.roles.filter(r => r !== "Admin"); // - Filter out admin role
    }

    if (this.isEdit) {
      let updateModel: UpdateModel = newRegisterModel;
      updateModel.applicationUserId = this.user.applicationUserId;
      updateModel.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateUser(updateModel);
    }
    else {
      newRegisterModel.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onRegisterUser(newRegisterModel);
    }
  }

  inviteUserUrl : string = `${AppSettings.AUTHORITY_ENDPOINT}/account/invite?returnUrl=${AppSettings.PORTAL_URI}`
}
