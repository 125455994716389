import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";

import { ComplaintsRoutingModule } from "./complaints-routing.module";

import { SharedModule } from "../shared/shared.module";
import { CalendarModule, DropdownModule, SliderModule } from "primeng/primeng";

import { ComplaintsComponent } from './complaints.component';
import { ComplaintListComponent } from './complaint-list/complaint-list.component';
import { ComplaintManageComponent } from './complaint-manage/complaint-manage.component';
import { ComplaintTypesComponent } from './complaint-types/complaint-types.component';
import { ComplaintTypesModule } from './complaint-types/complaint-types.module';
import { ComplaintTimelineComponent } from './complaint-timeline/complaint-timeline.component';

@NgModule({
   declarations: [
     ComplaintsComponent,
     ComplaintListComponent,
     ComplaintManageComponent,
     ComplaintTimelineComponent
  ],
  imports: [
    SharedModule,
    ComplaintsRoutingModule,
    ComplaintTypesModule,
    ReactiveFormsModule,
    DropdownModule,
    SliderModule,
    CalendarModule
  ]
})
export class ComplaintsModule { }
