import { Injectable } from '@angular/core';
import { Message } from "primeng/primeng";
import { Response } from "@angular/http";

@Injectable()
export class ToastService {

  toastMsgs: Message[] = [];

  constructor() { }

  private createNewMessage(type: string, header: string, body: string) {
    this.createNewMessageWithTimer(type, header, body, 3000);
  }

  // Creates a new Message and pushes it to the toastMsgs list (expires after specified duration ends)
  private createNewMessageWithTimer(type: string, header: string, body: string, duration: number) {
    let newMessage = { severity: type, summary: header, detail: body };
    this.toastMsgs.push(newMessage);
    setTimeout(() => {
      this.toastMsgs.splice(this.toastMsgs.indexOf(newMessage), 1);
    }, duration);
  }

  createSuccessMessage(header: string, body: string) {
    this.createNewMessage('success', header, body);
  }

  createInfoMessage(header: string, body: string) {
    this.createNewMessage('info', header, body);
  }

  createWarningMessage(header: string, body: string) {
    this.createNewMessage('warn', header, body);
  }

  createErrorMessage(header: string, error: Response) {
    //let output = typeof error["_body"] === 'string' ? error["_body"] : error;

    let output =  error["error"];
    this.createNewMessageWithTimer('error', header, "The following error has occured: " + output, 8000); // - Error message should last longer
  }

  getMessages(): Message[] {
    return this.toastMsgs;
  }

  clearMessages(msgs) {
    msgs = [];
  }
}
