import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from '../../auth/auth.service';
import { ToastService } from "../../shared/services/toast.service";

import { ComplaintLogService } from '../../shared/services/complaint-log.service';
import { ComplaintLog } from "../../shared/models/complaint-log.model";

import { ActivatedRoute } from "@angular/router";

import { ComplaintService } from '../../shared/services/complaint.service';
import { Complaint } from '../../shared/models/complaint.model';

@Component({
  selector: 'app-complaint-timeline',
  templateUrl: './complaint-timeline.component.html',
  styleUrls: ['./complaint-timeline.component.scss']
})
export class ComplaintTimelineComponent implements OnInit {

  complaintLogs: ComplaintLog[] = [];
  orientation: boolean = false;
  complaint: Complaint;

  constructor(
    public authService: AuthService,
    private complaintLogService: ComplaintLogService,
    private complaintService:ComplaintService,
    private toastService: ToastService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const complaintId = this.route.snapshot.params['id'];
    this.onGetComplaint(complaintId);    
    this.onGetComplaintLogs(complaintId);    
  }


  onGetComplaintLogs(id : number) {
    this.complaintLogService.getComplaintLogByComplaintId(id).subscribe(
      (complaintLogs: ComplaintLog[]) => {
        this.complaintLogs = complaintLogs;
        console.log(this.complaintLogs);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving complaint logs", error);
      }
    );
  }

  onGetComplaint(id:number){
    this.complaintService.getComplaint(id).subscribe(
      (complaint: Complaint) => {
        this.complaint = complaint;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving complaint", error);
      }
    );
  }


  changeOrientation(){    
    this.orientation = !this.orientation;    
  }

}




