import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { ContactService } from '../../shared/services/contact.service';
import { SmsWarriorsService } from '../../shared/services/sms-warriors.service';
import { MessageSignatureService } from '../../shared/services/message-signature.service';
import { ToastService } from '../../shared/services/toast.service';

import { ConfirmationService } from 'primeng/primeng';

import { MessageSignature } from 'app/views/shared/models/message-signature.model';

@Component({
  selector: 'app-message-centre-signatures',
  templateUrl: './message-centre-signatures.component.html',
  styleUrls: ['./message-centre-signatures.component.scss']
})
export class MessageCentreSignaturesComponent implements OnInit {
  signatures: MessageSignature[] = [];
  
  constructor(
    public authService: AuthService,
    private messageSignatureService: MessageSignatureService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetMessageSignatures();

    // Listen to changes made to signatures
    this.messageSignatureService.messageSignaturesChanged.subscribe(
      () => {
        this.onGetMessageSignatures();
      }
    );
  }

  onGetMessageSignatures() {
    this.messageSignatureService.getMessageSignatures().subscribe(
      (signatures: MessageSignature[]) => {
        this.signatures = signatures;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving signatures", error);
      }
    );
  }

  onDeleteMessageSignature(messageSignature: MessageSignature) {
    this.confirmationService.confirm({
      header: 'Delete Signature',
      message: 'Are you sure you want to delete the signature ' + messageSignature.name + '?',
      accept: () => {
        messageSignature.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.messageSignatureService.deleteMessageSignature(messageSignature).subscribe(
          (response: Response) => {
            this.onGetMessageSignatures();
            this.toastService.createSuccessMessage("Success", "The signature " + messageSignature.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting signature", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for signature " + messageSignature.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

}
