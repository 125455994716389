import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './shared/authentication';
import { SilentComponent } from './shared/authentication/silent/silent.component';
import { UnauthorizedComponent } from './shared/authentication/unauthorized';

const routes: Routes = [
  //Profile
  {
    "path": "profile",
    "loadChildren": ".\/shared\/profile\/profile.module#ProfileModule"
  },
  { path: 'auth', component: AuthComponent },
  { path: 'silent', component: SilentComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrmRoutingModule { }