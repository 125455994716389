import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { OpportunityQuotesRoutingModule } from "./opportunity-quotes-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';
import { OpportunityQuotesComponent } from './opportunity-quotes.component';
import { OpportunityQuoteManageComponent } from './opportunity-quote-manage/opportunity-quote-manage.component';
import { CalendarModule, DropdownModule, EditorModule } from 'primeng/primeng';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OpportunityQuoteSendEmailManageComponent } from './opportunity-quote-send-email/opportunity-quote-send-email.component';


@NgModule({
  declarations: [
    OpportunityQuotesComponent,
    OpportunityQuoteManageComponent,
    OpportunityQuoteSendEmailManageComponent
  ],
  imports: [
    SharedModule,
    OpportunityQuotesRoutingModule,
    ReactiveFormsModule,
    CalendarModule,
    BrowserAnimationsModule,
    DropdownModule,
    ModalModule.forRoot(),
    EditorModule
  ]
})
export class OpportunityQuotesModule { }
