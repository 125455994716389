import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactFilesComponent } from './contact-files.component';
import { ContactFileListComponent } from './contact-file-list/contact-file-list.component';
import { ContactFileManageComponent } from './contact-file-manage/contact-file-manage.component';

import { SharedModule } from "../../shared/shared.module";
import { ContactFilesRoutingModule } from "./contact-files-routing.module";

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ModalModule } from "ngx-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    ContactFilesComponent,
    ContactFileListComponent,
    ContactFileManageComponent
  ],
  imports: [
    SharedModule,
    ModalModule.forRoot(),
    ContactFilesRoutingModule,
    ReactiveFormsModule,
    DropzoneModule
  ]
})
export class ContactFilesModule { }
