import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { OpportunityFileService } from "../../../shared/services/opportunity-file.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { OpportunityFile } from "../../../shared/models/opportunity-file.model";

@Component({
  selector: 'app-opportunity-file-manage',
  templateUrl: './opportunity-file-manage.component.html',
  styleUrls: ['./opportunity-file-manage.component.scss']
})
export class OpportunityFileManageComponent implements OnInit {
  opportunityFileForm: FormGroup;       // - Stores the opportunityFile form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised

  opportunityFiles: OpportunityFile[] = [];             // - Stores all opportunityFiles retrieved from db
  opportunityFile: OpportunityFile = new OpportunityFile(); // - Stores OpportunityFile to populate form with an existing OpportunityFile's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private opportunityFileService: OpportunityFileService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.opportunityFileService.getOpportunityFiles().subscribe(
      (opportunityFiles: OpportunityFile[]) => {
        this.opportunityFiles = opportunityFiles; // - Store all opportunity files retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const opportunityFileId = params['id'];

            if (!opportunityFileId) {
              this.router.navigate(['/error/404']);
              return;
            }

            this.opportunityFile = this.opportunityFiles.find(cF => cF.id === +opportunityFileId); // - Store the selected opportunityFile

            // Redirect to 404 page if the selected opportunityFile could not be found
            if (!this.opportunityFile) {
              this.router.navigate(['/error/404']);
              return;
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunity files", error);
      }
    );
  }

  initForm() {
    this.opportunityFileForm = this.formBuilder.group({
      displayFileName: this.formBuilder.control(this.opportunityFile.displayFileName, [Validators.required]),
      description: this.formBuilder.control(this.opportunityFile.description)
    });
    this.isFormInit = true;
  }

  onUpdateOpportunityFile(newOpportunityFile: OpportunityFile) {
    this.opportunityFileService.updateOpportunityFile(newOpportunityFile).subscribe(
      (response: Response) => {
        this.opportunityFileService.opportunityFilesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The opportunity file " + newOpportunityFile.displayFileName + " has been updated.");
        this.router.navigate(['/opportunities', this.opportunityFile.opportunityId, 'files']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating opportunity file", error);
      }
    );
  }

  onSubmit() {
    let newOpportunityFile = this.formBuilder.sanitizeFormValues(this.opportunityFileForm).value;

    newOpportunityFile.id = this.opportunityFile.id; // - Set id of edited opportunityFile to its original id
    newOpportunityFile.updatedByUserAccountId = this.authService.applicationProfileUser().id;
    this.onUpdateOpportunityFile(newOpportunityFile);
  }
}
