import { Component, OnInit } from '@angular/core';
import { AuthService } from "../auth/auth.service";

import { CrmUser } from "../shared/models/user.model";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: CrmUser;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.applicationProfileUser() as CrmUser;
  }
}
