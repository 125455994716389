import { AcquisitionOpportunityManageComponent } from './opportunity-manage-acquisition.component';

export class AcquisitionOpportunityValidation {
  constructor(private _opportunityManageItem: AcquisitionOpportunityManageComponent) { }

  //the client input fields
  input = {
    NAME: "name",
    CODE: "code",
    OPP_PROBABILITY: "probability",
    OPP_TYPE: "opportunityTypeId",
    OPP_CATEGORY_TYPE: "opportunityCategoryTypeId",

    OPP_SELLER: "sellerId",
    PLOT_DESC: "plotDescription",

    PROJECT_MANAGER: "projectManagerId",
    START_DATE: "startDate",
    DUE_DATE: "dueDate"
  }

  //opportunity category type validation
  get opportunityCategoryType() { return this._opportunityManageItem.opportunityForm.get(this.input.OPP_CATEGORY_TYPE); }
  get isOpportunityCategoryTypeInvalid() { return (this.opportunityCategoryType.invalid && (this.opportunityCategoryType.dirty || this.opportunityCategoryType.touched)); }

  //opportunity type validation
  get opportunityType() { return this._opportunityManageItem.opportunityForm.get(this.input.OPP_TYPE); }
  get isOpportunityTypeInvalid() { return (this.opportunityType.invalid && (this.opportunityType.dirty || this.opportunityType.touched)); }

  //probabillity validation
  get probability() { return this._opportunityManageItem.opportunityForm.get(this.input.OPP_PROBABILITY); }
  get isProbabilityInvalid() { return (this.probability.invalid && (this.probability.dirty || this.probability.touched)); }

  //name validation
  get name() { return this._opportunityManageItem.opportunityForm.get(this.input.NAME); }
  get isNameInvalid() { return (this.name.invalid && (this.name.dirty || this.name.touched)); }

  //seller validation
  get seller() { return this._opportunityManageItem.opportunityForm.get(this.input.OPP_SELLER); }
  get isSellerInvalid() { return (this.seller.invalid && (this.seller.dirty || this.seller.touched)); }

  //plot description validation
  get plotDescription() { return this._opportunityManageItem.opportunityForm.get(this.input.PLOT_DESC); }
  get isPlotDescriptionInvalid() { return (this.plotDescription.invalid && (this.plotDescription.dirty || this.plotDescription.touched)); }

  //project manager validation
  get projectManager() { return this._opportunityManageItem.opportunityForm.get('project').get(this.input.PROJECT_MANAGER); }
  get isProjectManagerInvalid() { return (this.projectManager.invalid && (this.projectManager.dirty || this.projectManager.touched)); }

  //project name validation
  get projectName() { return this._opportunityManageItem.opportunityForm.get('project').get(this.input.NAME); }
  get isProjectNameInvalid() { return (this.projectName.invalid && (this.projectName.dirty || this.projectName.touched)); }

   //project name validation
   get projectCode() { return this._opportunityManageItem.opportunityForm.get('project').get(this.input.CODE); }
   get isProjectCodeInvalid() { return (this.projectCode.invalid && (this.projectCode.dirty || this.projectCode.touched)); }
 
  //project start date validation
  get projectStartDate() { return this._opportunityManageItem.opportunityForm.get('project').get(this.input.START_DATE); }
  get isProjectStartDateInvalid() { return (this.projectStartDate.invalid && (this.projectStartDate.dirty || this.projectStartDate.touched)); }

  //project due date validation
  get projectDueDate() { return this._opportunityManageItem.opportunityForm.get('project').get(this.input.DUE_DATE); }
  get isProjectDueDateInvalid() { return (this.projectDueDate.invalid && (this.projectDueDate.dirty || this.projectDueDate.touched)); }
}
