import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from 'rxjs/Subject';


import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';
import { Quote }  from "../models/quote.model";
import { QuoteItem } from '../models/quote-item.model';
import { Contact } from '../models/contact.model';


@Injectable()
export class QuoteService {

  public url: string = AppSettings.API_ENDPOINT + 'api/quotes/';

  public typesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getQuotes(): Observable<Quote[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let quotes: Quote[] = (<any>response);
        quotes = quotes.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return quotes;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getQuotesByOpportunityId(opportunityId: number): Observable<Quote[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+"Opportunity/"+opportunityId).map(
      (response: Response) => {
        let quotes: Quote[] = (<any>response);
        quotes = quotes.map(
          cT => {
            return cT;
          }
        );
        return quotes;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getAssignedContactByQuoteId(quoteId: number) : Observable<Contact> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+quoteId+"/Contact").map(
      (response: Response) => {
        let contact: Contact = (<any>response);

        return contact;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getQuote(id: number): Observable<Quote> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let quote: Quote = (<any>response);
        // quote.createdByUserAccount = this.userService.getUser(quote.createdByUserAccountId);
        // quote.updatedByUserAccount = this.userService.getUser(quote.updatedByUserAccountId);
        return quote;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addQuote(quote: Quote): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      quote
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateQuote(quote: Quote): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + quote.id,
      quote
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  cloneQuote(quote: Quote): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url + "Clone",
      quote
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  quoteToInvoice(quote: Quote): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url +"Invoice/" + quote.id,
      quote
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  generatePdf(quoteId: number): Observable<Response> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get<Response>(
      this.url + quoteId+"/generate",

    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  view(quoteId: number): Observable<Response> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get<Response>(
      this.url +"/" + quoteId + "/pdf",
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  scheduleEmailQuote(newOpportunityQuoteSendEmail: any) {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url + newOpportunityQuoteSendEmail.quoteId + "/email", newOpportunityQuoteSendEmail
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteQuote(quote: Quote): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + quote.id,
      quote
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteQuoteItem(quoteItem: QuoteItem): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "DeleteItem/" + quoteItem.id,
      quoteItem
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }



}
