import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from "@angular/router";
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/filter';
import { AuthService } from 'app/views/auth/auth.service';
import { CookieService } from 'ngx-cookie';


@Component({
  selector: 'app-unauthorized',
  providers: [ CookieService ],
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  //, private location:Location
  constructor(private location:Location, private service: AuthService, public router: Router, private cookieService : CookieService)  {

  }

  ngOnInit() {
    this.login();
  }

  login() {
     // var previousUrl;// : [Event, Event];
    this.service.startSigninMainWindow();
  }

  goback() {
    // this.location.back();
  }
}
