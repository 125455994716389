import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { ContactTypeService } from "../../../shared/services/contact-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { ContactType } from "../../../shared/models/contact-type.model";

import { valueExistsValidator } from "../../../shared/validators/value-exists.validator";

@Component({
  selector: 'app-contact-type-manage',
  templateUrl: './contact-type-manage.component.html',
  styleUrls: ['./contact-type-manage.component.scss']
})
export class ContactTypeManageComponent implements OnInit {
  contactTypeForm: FormGroup;       // - Stores the contactType form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a contactType if true and add a new contactType if false

  contactTypes: ContactType[] = [];             // - Stores all contactTypes retrieved from db
  contactType: ContactType = new ContactType(); // - Stores ContactType to populate form with a new / existing ContactType's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private contactTypeService: ContactTypeService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.contactTypeService.getTypeContacts().subscribe(
      (contactTypes: ContactType[]) => {
        this.contactTypes = contactTypes; // - Store all contact types retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const contactTypeId = params['id'];
            this.isEdit = contactTypeId != null;

            if (this.isEdit) {
              this.contactType = this.contactTypes.find(cType => cType.id === +contactTypeId); // - Store the selected contactType

              // Redirect to 404 page if the selected contactType could not be found
              if (!this.contactType) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }

  initForm() {
    // Get all contactType names to know if it already exists
    const contactTypeNames = this.contactTypes.map(cType => cType.name);

    this.contactTypeForm = this.formBuilder.group({
      name: this.formBuilder.control(this.contactType.name, [Validators.required, valueExistsValidator(contactTypeNames)])
    });
    this.isFormInit = true;
  }

  onAddContactType(newContactType: ContactType) {
    this.contactTypeService.addTypeContact(newContactType).subscribe(
      (response: Response) => {
        this.contactTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The contact type " + newContactType.name + " has been created.");
        this.router.navigate(['/contacts/types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding contact type", error);
      }
    );
  }

  onUpdateContactType(newContactType: ContactType) {
    this.contactTypeService.updateTypeContact(newContactType).subscribe(
      (response: Response) => {
        this.contactTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The contact type " + newContactType.name + " has been updated.");
        this.router.navigate(['/contacts/types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating contact type", error);
      }
    );
  }

  onSubmit() {
    let newContactType = this.formBuilder.sanitizeFormValues(this.contactTypeForm).value;

    if (this.isEdit) {
      newContactType.id = this.contactType.id; // - Set id of edited contactType to its original id
      newContactType.createdByUserAccountId = this.contactType.createdByUserAccountId; // - Need createdById of original contact type to check if it is a prepopulated type
      newContactType.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateContactType(newContactType);
    }
    else {
      newContactType.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddContactType(newContactType);
    }
  }
}
