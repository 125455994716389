import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { ComplaintType } from "../models/complaint-type.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from 'rxjs/Subject';

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ComplaintTypeService {
  private url: string = AppSettings.API_ENDPOINT + 'api/complaintstypes/';

  public typesChanged = new Subject();
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getComplaintTypes(): Observable<ComplaintType[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let complaintTypes: ComplaintType[] = (<any>response);
        complaintTypes = complaintTypes.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return complaintTypes;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getComplaintType(id: number): Observable<ComplaintType> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let complaintType: ComplaintType = (<any>response);
        // complaintType.createdByUserAccount = this.userService.getUser(complaintType.createdByUserAccountId);
        // complaintType.updatedByUserAccount = this.userService.getUser(complaintType.updatedByUserAccountId);
        return complaintType;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addComplaintType(complaintType: ComplaintType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      complaintType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateComplaintType(complaintType: ComplaintType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + complaintType.id,
      complaintType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteComplaintType(complaintType: ComplaintType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + complaintType.id,
      complaintType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
