import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { OpportunityFile } from "../../shared/models/opportunity-file.model";
import { Observable } from "rxjs/Observable";

import { Subject } from "rxjs/Rx";
import { HttpClient } from '@angular/common/http';


@Injectable()
export class OpportunityFileService {
  private url: string = AppSettings.API_ENDPOINT + 'api/opportunityfiles/';

  public opportunityFilesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getOpportunityFiles(): Observable<OpportunityFile[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let opportunityFiles: OpportunityFile[] = (<any>response);
        opportunityFiles = opportunityFiles.map(
          cF => {
            // cF.createdByUserAccount = this.userService.getUser(cF.createdByUserAccountId);
            // cF.updatedByUserAccount = this.userService.getUser(cF.updatedByUserAccountId);
            return cF;
          }
        );
        return opportunityFiles;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getOpportunityFilesByOpportunityId(opportunityId: number): Observable<OpportunityFile[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Opportunity/" + opportunityId).map(
      (response: Response) => {
        let opportunityFiles: OpportunityFile[] = (<any>response);
        opportunityFiles = opportunityFiles.map(
          cF => {
            // cF.createdByUserAccount = this.userService.getUser(cF.createdByUserAccountId);
            // cF.updatedByUserAccount = this.userService.getUser(cF.updatedByUserAccountId);
            return cF;
          }
        );
        return opportunityFiles;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getOpportunityFile(id: number): Observable<OpportunityFile> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let opportunityFile: OpportunityFile = (<any>response);
        // opportunityFile.createdByUserAccount = this.userService.getUser(opportunityFile.createdByUserAccountId);
        // opportunityFile.updatedByUserAccount = this.userService.getUser(opportunityFile.updatedByUserAccountId);
        return opportunityFile;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateOpportunityFile(opportunityFile: OpportunityFile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + opportunityFile.id,
      opportunityFile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteOpportunityFile(opportunityFile: OpportunityFile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + opportunityFile.id,
      opportunityFile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
