import { NgModule } from '@angular/core';

import { ErrorRoutingModule } from "./errors-routing.module";

import { NotFoundComponent } from "./not-found/not-found.component";
import { ForbiddenComponent } from './forbidden/forbidden.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    ForbiddenComponent
  ],
  imports: [
    ErrorRoutingModule
  ]
})
export class ErrorsModule { }
