import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class RetrieveContactQuery extends Query {
  document = RETRIEVE_CONTACTS_QUERY;
}

export const RETRIEVE_CONTACTS_QUERY =  gql`
query ($input: ContactFilterInput!) {
  contacts(input: $input) {
      data {
       id
       firstName
       lastName
      }
  }
}
`
