import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { OpportunityLostTypesComponent } from "../opportunity-lost-types/opportunity-lost-types.component";
import { OpportunityLostTypeListComponent } from "../opportunity-lost-types/opportunity-lost-type-list/opportunity-lost-type-list.component";
import { OpportunityLostTypeManageComponent } from "../opportunity-lost-types/opportunity-lost-type-manage/opportunity-lost-type-manage.component";

const typesRoutes: Routes = [
    {
        path: 'opportunities',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'lost-types', component: OpportunityLostTypesComponent,
                children: [
                    {
                        path: '', component: OpportunityLostTypeListComponent,
                        children: [
                            { path: ':id/edit', component: OpportunityLostTypeManageComponent, canActivate: [RoleGuard] },
                            { path: 'new', component: OpportunityLostTypeManageComponent, canActivate: [RoleGuard] }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(typesRoutes)],
    exports: [RouterModule]
})
export class OpportunityLostTypesRoutingModule { }