import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CalendarModule, DropdownModule, GMapModule, PanelModule, TooltipModule } from 'primeng/primeng';
import { ContactsRoutingModule } from 'app/views/contacts/contacts-routing.module';
import { ContactTypesModule } from 'app/views/contacts/contact-types/contact-types.module';
import { ContactSourcesModule } from 'app/views/contacts/contact-sources/contact-sources.module';
import { ContactFilesModule } from 'app/views/contacts/contact-files/contact-files.module';
import { ContactGroupsModule } from 'app/views/contacts/contact-groups/contact-groups.module';
import { ContactSavedSearchesModule } from 'app/views/contacts/contact-saved-searches/contact-saved-searches.module';
import { CommunicationHistoryModule } from 'app/views/contacts/communication-history/communication-history.module';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { EventsListComponent } from './events-list.component';
import { SharedModule } from 'app/views/shared/shared.module';



@NgModule({
  imports: [
    SharedModule,
    ContactsRoutingModule,
    ContactTypesModule,
    ContactSourcesModule,
    ContactFilesModule,
    ContactGroupsModule,
    ContactSavedSearchesModule,
    CommunicationHistoryModule,
    ReactiveFormsModule,
    CollapseModule,
    CalendarModule,
    BrowserAnimationsModule,
    DropdownModule,
    DropzoneModule,
    GMapModule,
    PanelModule,
    TooltipModule

  ],
  declarations: [
    EventsListComponent,
  ]
})
export class EventsListModule { }
