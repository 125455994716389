import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../../auth/auth.service";
import { TaskTypeService } from "../../../shared/services/task-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { TaskType } from "../../../shared/models/task-type.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-task-type-list',
  templateUrl: './task-type-list.component.html',
  styleUrls: ['./task-type-list.component.scss']
})
export class TaskTypeListComponent implements OnInit {
  taskTypes: TaskType[] = [];
  isAnim: boolean = true;

  systemCreatedById = PrepopulatedEntities.SystemTaskTypeCreatedById; // - Id of System used to check if task type was prepopulated (Added by system)

  constructor(
    public authService: AuthService,
    private taskTypeService: TaskTypeService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetTaskTypes();

    // Listen to changes made to taskTypes
    this.taskTypeService.typesChanged.subscribe(
      () => {
        this.onGetTaskTypes();
      }
    );
  }

  onGetTaskTypes() {
    this.taskTypeService.getTaskTypes().subscribe(
      (taskTypes: TaskType[]) => {
        this.taskTypes = taskTypes;
        console.log(this.taskTypes);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving task types", error);
      }
    );
  }

  onDeleteTaskType(taskType: TaskType) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the task type ' + taskType.name + '?',
      accept: () => {
        taskType.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.taskTypeService.deleteTaskType(taskType).subscribe(
          (response: Response) => {
            this.onGetTaskTypes();
            this.toastService.createSuccessMessage("Success", "The task type " + taskType.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting task type", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for task type " + taskType.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
