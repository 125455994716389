import { CountryCode } from "./app/views/shared/models/country-code.model";
import { AppSettings } from 'app.settings';

export class ApiEndPoints {
  public static readonly CrmApiEndPoint =  AppSettings.API_ENDPOINT;
  //public static readonly CrmApiEndPoint = "http://lbmcrmapi.isb-software.co.uk/";
}

export class CustomConfigurations {
  public static readonly SharedContacts = true;      // - If contacts should be visible to everyone or just their set referrer
  public static readonly SharedCommLogs = true;      // - Same as above but for communication logs
  public static readonly SharedOpportunities = true; // - Same as above but for opportunities
}

export class ComponentStyling {
  public static readonly PrimaryColor = "#005e85";    // - Primary color ($navy in scss) used for the Line chart in Dashboard
}

export class PrepopulatedContactTypes {
  public static readonly Customer = "Client";
  public static readonly Lead = "Lead";
}

export class PrepopulatedTypesContact {
  public static readonly Client = "Client";
  public static readonly Vendor = "Vendor";
  public static readonly Brokers = "Brokers";
  public static readonly Notaries = "Notaries";
  public static readonly Architects = "Architects";
  public static readonly Other_Agents = "Other Agents";
  public static readonly Developers = "Developers";
  public static readonly Prospectors = "Prospectors";
}

export class PrepopulatedContactSources {
  public static readonly Website = "Website";
  public static readonly Referred = "Referred";
}

export class PrepopulatedTaskTypes {
  public static readonly Meeting = "Meeting";
  public static readonly CommunicationLog = "Communication Log Assignment";
  public static readonly Viewing = "Viewing";
}

export class PrepopulatedOpportunityTypes {
  public static readonly Open = "Open";
  public static readonly ClosedPurchase = "Closed Purchase";
  public static readonly ClosedSale = "Closed Sale";
  public static readonly Lost = "Lost";
}

export class PrepopulatedEntities {
  public static readonly PrepopulatedContactTypeNames: string[] = [
    PrepopulatedContactTypes.Customer,
    PrepopulatedContactTypes.Lead
  ];

  public static readonly SystemContactTypeCreatedById: number = -1;

  public static readonly PrepopulatedContactSourceNames: string[] = [
    PrepopulatedContactSources.Website,
    PrepopulatedContactSources.Referred
  ];

  public static readonly SystemContactSourceCreatedById: number = -1;

  public static readonly PrepopulatedTaskTypeNames: string[] = [
    PrepopulatedTaskTypes.Meeting
  ];

  public static readonly SystemTaskTypeCreatedById: number = -1;
}

export class CommunicationLogTypeNames {
  public static readonly Website = "Website";
  public static readonly Phone = "Phone";
  public static readonly Email = "Email";
  public static readonly Meeting = "Meeting";
  public static readonly Skype = "Skype";
  public static readonly SMS = "SMS";
  public static readonly Facebook = "Facebook";
  public static readonly HelpDesk = "Help Desk";
  public static readonly LinkedIn = "LinkedIn";
  public static readonly MailChimp = "MailChimp";
  public static readonly LeadDeskPostCallEvent = "LeadDesk - Post Call Event";  

  // - Log Types that are created manually through migrations
  // - These are stored to be able to differentiate between these manually created log types and the log types
  // which are created from websites integrated with the CRM
  public static readonly LogTypesNotFromWebsite: string[] = [
    CommunicationLogTypeNames.Phone,
    CommunicationLogTypeNames.Email,
    CommunicationLogTypeNames.Meeting,
    CommunicationLogTypeNames.Skype,
    CommunicationLogTypeNames.SMS,
    CommunicationLogTypeNames.Facebook,
    CommunicationLogTypeNames.HelpDesk,
    CommunicationLogTypeNames.LinkedIn,
    CommunicationLogTypeNames.MailChimp,
    CommunicationLogTypeNames.LeadDeskPostCallEvent
  ];
}

export class CommunicationDirection {
  public static readonly Incoming = "I";
  public static readonly Outgoing = "O";
}

export class AuditManager {
  public static readonly StatusActive = "A";
  public static readonly StatusDelete = "X";
  public static readonly StatusHidden = "H";
}

export class SmsWarriors {
  public static readonly CountryCodes: CountryCode[] = [
    { country: "Malta", code: "356" },
    // { country: "Test", code: "123" }
  ];
}

export class GoogleMaps {
  public static readonly DefaultLocation = "Triq P Gagliardi, Ħal Tarxien, Malta";
  public static readonly DefaultLocationLat = 35.86864;
  public static readonly DefaultLocationLng = 14.513333;
  public static readonly DefaultLocationZoom = 18;
}