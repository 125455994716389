export class Profile {
	constructor(
		public subjectId: string = null,
		public id: number = null,
		public code: string = null,
		public firstName: string = null,
    public lastName: string = null,
		public email: string = null,
		public role: string = null,
		public profileExists : boolean = false,
    public jobTitle: string = null,

    public appUserRoles: AppUserRole[] = []
		) { }
}

export class AppUserRole {
  constructor(public id: string = null,
    public appRole: AppRole = null) { }
}

// AppRole Permissions is out of scope for the CRM at the moment as it's based on roles and not permissions for roles, but we'll leave the structure here as it's supported already by the backend.
export class AppRole {
  constructor(public id: string = null,
    public name: string = null) {
    //public appRolePermissions: AppRolePermission[] = []) {
  }
}

export class AppRolePermission {
  constructor(public id: string = null,
    public appPermission: AppPermission = null) {}
}

export class AppPermission {
  constructor(public id: string = null,
    public claimType: string = null,
    public claimValue: string = null) {}
}

// public class AppUserRoleDto
//     {
//         public AppUserRoleDto()
//         {
//           //  this.AppRolePermissions = new AppRolePermissionsDto[0];
//         }

//         public string Id { get; set; }

//         public Guid AppRoleId { get; set; }
//         public AppRoleDto AppRole { get; set; }

//        // public string Name { get; set; }
//        // public AppRolePermissionsDto[] AppRolePermissions { get; set; }
//     }

//     public class AppRolePermissionsDto
//     {
//         public string Id { get;set; }

//         public string AppRoleId { get; set; }
//         public AppRoleDto AppRole { get; set; }

//         public string AppPermissionId { get; set; }
//         public AppPermissionDto AppPermission { get; set; }
//     }

//     public class AppRoleDto
//     {
//         public string Id { get; set; }
//         public string Name { get; set; }

//         public AppRolePermissionsDto[] AppRolePermissions { get; set; }
//     }

//     public class AppPermissionDto
//     {
//         public string Id { get; set; }
//         public string ClaimType { get; set; }
//         public string ClaimValue { get; set; }
//     }
