import { Component, OnInit } from '@angular/core';

import { UserService } from "../../shared/services/user.service";
import { AuthService } from "../../auth/auth.service";
import { Contact } from "../../shared/models/contact.model";
import { CrmUser } from "../../shared/models/user.model";

import { SelectItem } from "primeng/primeng";
import { Response } from "@angular/http";
import { AppSettings } from 'app.settings'


@Component({
  selector: 'app-conversions',
  templateUrl: './conversions.component.html',
  styleUrls: ['./conversions.component.scss']
})
export class ConversionsComponent implements OnInit {
  users: CrmUser[] = [];               // - Stores all contacts to populate dropdown options
  usersOptions: SelectItem[] = [];  // - Stores Assign User Dropdown Options
  convertedOptions: SelectItem[] = [];

  selectedUserOption: any;          // - Stores the selected dropdown option which can be 'all' or the id of a contact
  isConverted: boolean = true;
  fromDate: Date = null;
  toDate: Date = null;

  crmApiEndPoint: string = AppSettings.API_ENDPOINT;

  accessToken = this.authService.currUser().access_token;//localStorage.getItem('accessToken');  
  
  // Datepicker
  dateRange: string = (new Date().getFullYear() - 150) + ':' + new Date().getFullYear(); // - Range: 150 Years Ago until Today

  constructor(
    public authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (this.authService.isAdmin())
      this.usersOptions.push({ label: 'All', value: 'all' });

    this.convertedOptions.push({ label: 'Leads Converted', value: true })
    this.convertedOptions.push({ label: 'Pending Leads', value: false })
    this.onGetUsers();
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Only Admins can get reports about all users
        if (this.authService.isAdmin())
          users.map(u => this.usersOptions.push({ label: u.fullName, value: u.id }));
        else {
          let user = users.find(u => u.id === this.authService.applicationProfileUser().id);
          this.usersOptions.push({ label: user.fullName, value: user.id });
          this.selectedUserOption = user.id;
        }
      }
    );
  }
}
