import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../shared/shared.module";
import { AccountsRoutingModule } from "../accounts/accounts-routing.module";

import { ModalModule } from "ngx-bootstrap";

import { AccountsComponent } from './accounts.component';
import { AccountListComponent } from './account-list/account-list.component';
import { AccountManageComponent } from './account-manage/account-manage.component';

@NgModule({
  declarations: [
    AccountsComponent,
    AccountListComponent,
    AccountManageComponent
  ],
  imports: [
    SharedModule,
    AccountsRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class AccountsModule { }
