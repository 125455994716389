import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { MessageSignatureService } from "../../../shared/services/message-signature.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { MessageSignature } from "../../../shared/models/message-signature.model";
import { valueExistsValidator } from 'app/views/shared/validators/value-exists.validator';

@Component({
  selector: 'app-message-centre-signatures-manage',
  templateUrl: './message-centre-signatures-manage.component.html',
  styleUrls: ['./message-centre-signatures-manage.component.scss']
})
export class MessageCentreSignaturesManageComponent implements OnInit {
  signatureForm: FormGroup;          // - Stores the signature form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a message signature if true and add a new message signature if false

  messageSignatures: MessageSignature[] = [];           // - Stores all messageSignatures retrieved from db
  signature: MessageSignature = new MessageSignature();  // - Stores MessageSignature to populate form with an existing MessageSignature's values

  // messageMaxLength: number = 720;

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private messageSignatureService: MessageSignatureService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.messageSignatureService.getMessageSignatures().subscribe(
      (messageSignatures: MessageSignature[]) => {
        this.messageSignatures = messageSignatures; // - Store all signatures retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const messageSignatureId = params['id'];
            this.isEdit = messageSignatureId != null;

            if (this.isEdit) {
              this.signature = this.messageSignatures.find(mT => mT.id === +messageSignatureId); // - Store the selected signature

              // Redirect to 404 page if the selected signature could not be found
              if (!this.signature) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving signatures", error);
      }
    );
  }

  initForm() {
    // Get all signature names to know if it already exists
    const signatureNames = this.messageSignatures.map(mT => mT.name);

    // Remove the current signature name if being edited so the name can remain unchanged
    if (this.isEdit)
      signatureNames.splice(signatureNames.indexOf(this.signature.name), 1);

    this.signatureForm = this.formBuilder.group({
      name: this.formBuilder.control(this.signature.name, [Validators.required, valueExistsValidator(signatureNames)]),
      signiture: this.formBuilder.control(this.signature.signiture, [Validators.required])
    });

    this.isFormInit = true;
  }

  onAddMessageSignature(newMessageSignature: MessageSignature) {
    this.messageSignatureService.addMessageSignature(newMessageSignature).subscribe(
      (response: Response) => {
        this.messageSignatureService.messageSignaturesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The signature " + newMessageSignature.name + " has been created.");
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding signature", error);
      }
    );
  }

  onUpdateMessageSignature(newMessageSignature: MessageSignature) {
    this.messageSignatureService.updateMessageSignature(newMessageSignature).subscribe(
      (response: Response) => {
        this.messageSignatureService.messageSignaturesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The signature " + newMessageSignature.name + " has been updated.");
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating signature", error);
      }
    );
  }

  onSubmit() {
    let newMessageSignature = this.formBuilder.sanitizeFormValues(this.signatureForm).value;
    
    if (this.isEdit) {
      newMessageSignature.id = this.signature.id; // - Set id of edited messageSignature to its original id
      newMessageSignature.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateMessageSignature(newMessageSignature);
    }
    else {
      newMessageSignature.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddMessageSignature(newMessageSignature);
    }
  }
}
