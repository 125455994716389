import { Contact } from "./contact.model";
import { CommunicationLogType } from "./communication-log-type.model";
import { CrmUser } from "./user.model";
import { Task } from "./task.model";
import { Opportunity } from "./opportunity.model";

export class CommunicationLog {
    constructor(
        public id: number = 0,
        public communicationLogTypeId: number = null,
        public communicationLogTypeName: string = null,
        public communicationLogType: CommunicationLogType = new CommunicationLogType(),
        public direction: string = null,
        public contact: Contact = new Contact(),
        public contactId: number = 0,
        public opportunity: Opportunity = new Opportunity(),
        public opportunityId: number = 0,
        public assignedUser: CrmUser = new CrmUser(),
        public assignedUserAccountId: number = null,
        public task: Task = new Task(),
        public taskId: number = 0,
        public description: string = null,
        public occurredOn: string = null,        
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null,
        public assignedUserAccount: CrmUser = null,

    ) { }
}