import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { MailchimpService } from '../../shared/services/mailchimp.service';
import { ToastService } from '../../shared/services/toast.service';

import { List, MailChimpConfig } from '../../shared/models/mailchimp/campaign.model';

@Component({
  selector: 'app-mailchimp-list',
  templateUrl: './mailchimp-list.component.html',
  styleUrls: ['./mailchimp-list.component.scss']
})
export class MailchimpListComponent implements OnInit {
  lists: List[] = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    public toastService: ToastService,
    private mailchimpService: MailchimpService
  ) { }

  ngOnInit() {
    this.onGetConfig();

    this.mailchimpService.configChanged.subscribe(
      () => {
        this.onGetConfig();
      }
    );
  }

  onGetConfig() {
    this.mailchimpService.getMailChimpConfig().subscribe(
      (mailChimpConfig: MailChimpConfig) => {
        if (!mailChimpConfig) {
          this.router.navigate(["/mailchimp", "config"]);
        } else {
          // - Retrieve lists if API key was found
          this.onGetLists();
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving mailchimp config", error);
      }
    );
  }

  onGetLists() {
    this.mailchimpService.getLists().subscribe(
      (lists: List[]) => {
        this.lists = lists;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lists", error);
      }
    );
  }

  onUpdateCommunicationLogs(listId: string) {
    this.mailchimpService.updateCommunicationLogsFromList(listId).subscribe(
      (newCommLogsCount) => {
        if (newCommLogsCount > 0)
          this.toastService.createSuccessMessage("Success", newCommLogsCount + " new communication logs have been created.");
        else
          this.toastService.createInfoMessage("Info", "No new communication logs were found.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating communication logs", error);
      }
    );
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
