import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { Complaint } from "../models/complaint.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { AppSettings } from 'app.settings'
import { ComplaintStatus } from '../models/complaint-status.model';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ComplaintService {
  private url: string = AppSettings.API_ENDPOINT + 'api/complaints/';

  public typesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getComplaints(): Observable<Complaint[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let complaints: Complaint[] = (<any>response);
        complaints = complaints.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return complaints;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getComplaint(id: number): Observable<Complaint> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let complaint: Complaint = (<any>response);
        // complaint.createdByUserAccount = this.userService.getUser(complaint.createdByUserAccountId);
        // complaint.updatedByUserAccount = this.userService.getUser(complaint.updatedByUserAccountId);
        return complaint;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addComplaint(complaint: Complaint): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      complaint
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateComplaint(complaint: Complaint): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + complaint.id,
      complaint
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteComplaint(complaint: Complaint): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + complaint.id,
      complaint
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  

  getComplaintStatuses(): Observable<ComplaintStatus[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "ComplaintStatuses/").map(
      (response: Response) => {
        let complaintsStatuses: ComplaintStatus[] = (<any>response);
        return complaintsStatuses;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }


  

  getComplaintsByAssignedContactId(id: number): Observable<Complaint[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "AssignedContact/" + id).map(
      (response: Response) => {
        let complaints: Complaint[] = (<any>response);
        complaints = complaints.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return complaints;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

}