import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { ComplaintLog } from "../models/complaint-log.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ComplaintLogService {
  private url: string = AppSettings.API_ENDPOINT + 'api/complaintLogs/';

  public typesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getComplaintLogByComplaintId(id:number): Observable<ComplaintLog[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "ComplaintLogByComplaintId/" + id).map(
      (response: Response) => {
        let complaintLogs: ComplaintLog[] = (<any>response);
        complaintLogs = complaintLogs.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return complaintLogs;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getComplaintLog(id: number): Observable<ComplaintLog> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let complaintLog: ComplaintLog = (<any>response);
        // complaintLog.createdByUserAccount = this.userService.getUser(complaintLog.createdByUserAccountId);
        // complaintLog.updatedByUserAccount = this.userService.getUser(complaintLog.updatedByUserAccountId);
        return complaintLog;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addComplaintLog(complaintLog: ComplaintLog): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      complaintLog
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateComplaintLog(complaintLog: ComplaintLog): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + complaintLog.id,
      complaintLog
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteComplaintLog(complaintLog: ComplaintLog): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + complaintLog.id,
      complaintLog
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

}