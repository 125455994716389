import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";
import { RoleGuard } from '../auth/role-guard.service';

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { LeadDeskComponent } from './lead-desk.component';
import { LeadDeskListComponent } from './lead-desk-list/lead-desk-list.component';
import { LeadDeskConfigComponent } from './lead-desk-list/lead-desk-config/lead-desk-config.component';

const leadDeskRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'lead-desk', component: LeadDeskComponent,
        children: [
          {
            path: '', component: LeadDeskListComponent, children: [
              { path: 'config', component: LeadDeskConfigComponent }
            ]
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(leadDeskRoutes)],
  exports: [RouterModule]
})
export class LeadDeskRoutingModule { }