import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { ContactFileService } from "../../../shared/services/contact-file.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { ContactFile } from "../../../shared/models/contact-file.model";

@Component({
  selector: 'app-contact-file-manage',
  templateUrl: './contact-file-manage.component.html',
  styleUrls: ['./contact-file-manage.component.scss']
})
export class ContactFileManageComponent implements OnInit {
  contactFileForm: FormGroup;       // - Stores the contactFile form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised

  contactFiles: ContactFile[] = [];             // - Stores all contactFiles retrieved from db
  contactFile: ContactFile = new ContactFile(); // - Stores ContactFile to populate form with an existing ContactFile's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private contactFileService: ContactFileService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.contactFileService.getContactFiles().subscribe(
      (contactFiles: ContactFile[]) => {
        this.contactFiles = contactFiles; // - Store all contact files retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const contactFileId = params['id'];

            if (!contactFileId) {
              this.router.navigate(['/error/404']);
              return;
            }

            this.contactFile = this.contactFiles.find(cF => cF.id === +contactFileId); // - Store the selected contactFile

            // Redirect to 404 page if the selected contactFile could not be found
            if (!this.contactFile) {
              this.router.navigate(['/error/404']);
              return;
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact files", error);
      }
    );
  }

  initForm() {
    this.contactFileForm = this.formBuilder.group({
      displayFileName: this.formBuilder.control(this.contactFile.displayFileName, [Validators.required]),
      description: this.formBuilder.control(this.contactFile.description)
    });
    this.isFormInit = true;
  }

  onUpdateContactFile(newContactFile: ContactFile) {
    this.contactFileService.updateContactFile(newContactFile).subscribe(
      (response: Response) => {
        this.contactFileService.contactFilesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The contact file " + newContactFile.displayFileName + " has been updated.");
        this.router.navigate(['/contacts', this.contactFile.contactId, 'files']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating contact file", error);
      }
    );
  }

  onSubmit() {
    let newContactFile = this.formBuilder.sanitizeFormValues(this.contactFileForm).value;

    newContactFile.id = this.contactFile.id; // - Set id of edited contactFile to its original id
    newContactFile.updatedByUserAccountId = this.authService.applicationProfileUser().id;
    this.onUpdateContactFile(newContactFile);
  }
}
