import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { TaskTypesRoutingModule } from "./task-types-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { TaskTypesComponent } from './task-types.component';
import { TaskTypeListComponent } from './task-type-list/task-type-list.component';
import { TaskTypeManageComponent } from './task-type-manage/task-type-manage.component';

@NgModule({
  declarations: [
    TaskTypesComponent,
    TaskTypeListComponent,
    TaskTypeManageComponent
  ],
  imports: [
    SharedModule,
    TaskTypesRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class TaskTypesModule { }
