import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../../auth/auth.service";
import { ContactSourceService } from "../../../shared/services/contact-source.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { ContactSource } from "../../../shared/models/contact-source.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-contact-source-list',
  templateUrl: './contact-source-list.component.html',
  styleUrls: ['./contact-source-list.component.scss']
})
export class ContactSourceListComponent implements OnInit {
  contactSources: ContactSource[] = [];
  isAnim: boolean = true;

  systemCreatedById = PrepopulatedEntities.SystemContactSourceCreatedById; // - Id of System used to check if contact source was prepopulated (Added by system)

  constructor(
    public authService: AuthService,
    private contactSourceService: ContactSourceService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetContactSources();

    // Listen to changes made to contactSources
    this.contactSourceService.sourcesChanged.subscribe(
      () => {
        this.onGetContactSources();
      }
    );
  }

  onGetContactSources() {
    this.contactSourceService.getContactSources().subscribe(
      (contactSources: ContactSource[]) => {
        this.contactSources = contactSources;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact sources", error);
      }
    );
  }

  onDeleteContactSource(contactSource: ContactSource) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the contact source ' + contactSource.name + '?',
      accept: () => {
        contactSource.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactSourceService.deleteContactSource(contactSource).subscribe(
          (response: Response) => {
            this.onGetContactSources();
            this.toastService.createSuccessMessage("Success", "The contact source " + contactSource.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting contact source", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for contact source " + contactSource.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
