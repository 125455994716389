import { CrmUser } from "./user.model";
import { Opportunity } from "app/views/shared/models/opportunity.model";

export class OpportunityFile {
    constructor(
        public id: number = 0,
        public opportunityId: number = null,
        public opportunity: Opportunity = new Opportunity(),
        public displayFileName: string = null,
        public originalFileName: string = null,
        public filePath: string = null,
        public urlPath: string = null,
        public fileType: string = null,
        public description: string = null,
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null
    ) { }
}