import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { TaskType } from "../models/task-type.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class TaskTypeService {
  private url: string = AppSettings.API_ENDPOINT + 'api/tasktypes/';

  public typesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getTaskTypes(): Observable<TaskType[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let taskTypes: TaskType[] = (<any>response);
        taskTypes = taskTypes.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return taskTypes;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getTaskType(id: number): Observable<TaskType> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let taskType: TaskType = (<any>response);
        // taskType.createdByUserAccount = this.userService.getUser(taskType.createdByUserAccountId);
        // taskType.updatedByUserAccount = this.userService.getUser(taskType.updatedByUserAccountId);
        return taskType;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addTaskType(taskType: TaskType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      taskType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateTaskType(taskType: TaskType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + taskType.id,
      taskType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteTaskType(taskType: TaskType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + taskType.id,
      taskType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}