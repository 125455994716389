import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { ContactSourcesRoutingModule } from "./contact-sources-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { ContactSourcesComponent } from './contact-sources.component';
import { ContactSourceListComponent } from './contact-source-list/contact-source-list.component';
import { ContactSourceManageComponent } from './contact-source-manage/contact-source-manage.component';

@NgModule({
  declarations: [
    ContactSourcesComponent,
    ContactSourceListComponent,
    ContactSourceManageComponent
  ],
  imports: [
    SharedModule,
    ContactSourcesRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class ContactSourcesModule { }
