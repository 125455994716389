
export class CompanyDetails {
  constructor(
    public id: number = 0,
    public name : string = null,
    public email : string = null,
    public website : string = null,
    public mobilePhoneNumber : string = null,
    public officePhoneNumber : string = null,
    public vatNumber : string = null,
    public addressLine1 : string = null,
    public addressLine2  : string = null,
    public addressLine3 : string = null,
    public addressLine4  : string = null,
    public postCode : string = null,
    public country  : string = null,
    public locationLat : number = null,
    public locationLng : number = null,
    public createdOn: string = null,
    public updatedOn: string = null,
    public notes: string = null,
  )
  {}
}
