import { NgModule } from '@angular/core';

import { SharedModule } from "../shared/shared.module";

import { LeadDeskComponent } from './lead-desk.component';
import { LeadDeskListComponent } from './lead-desk-list/lead-desk-list.component';

import { LeadDeskRoutingModule } from './lead-desk-routing.module';
import { LeadDeskConfigComponent } from './lead-desk-list/lead-desk-config/lead-desk-config.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    LeadDeskComponent,
    LeadDeskListComponent,
    LeadDeskConfigComponent
  ],
  imports: [
    SharedModule,
    LeadDeskRoutingModule,
    ModalModule.forRoot(),
    ReactiveFormsModule
  ]
})
export class LeadDeskModule { }
