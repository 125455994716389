import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { ContactSavedSearchesComponent } from "../contact-saved-searches/contact-saved-searches.component";
import { ContactSavedSearchListComponent } from "../contact-saved-searches/contact-saved-search-list/contact-saved-search-list.component";
import { ContactSavedSearchManageComponent } from "../contact-saved-searches/contact-saved-search-manage/contact-saved-search-manage.component";

const savedSearchesRoutes: Routes = [
    {
        path: 'contacts',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'saved-searches', component: ContactSavedSearchesComponent,
                children: [
                    {
                        path: '', component: ContactSavedSearchListComponent,
                        children: [
                            { path: ':id/edit', component: ContactSavedSearchManageComponent },
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(savedSearchesRoutes)],
    exports: [RouterModule]
})
export class ContactSavedSearchesRoutingModule { }