import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})

export class AllOpportunityCategoryTypesQueryGQL extends Query {
  document = OPP_CATEGORY_TYPES_QUERY;
}

const OPP_CATEGORY_TYPES_QUERY =
gql`
  query {
    opportunityCategoryTypes {
      data {
        id
        name
      }
    }
  }
`;
