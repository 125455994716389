import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { Task } from "../models/task.model";
import { ContactTask } from "../models/contact-task.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { AuthHttp } from "angular2-jwt/angular2-jwt";
import { UserService } from "./user.service";
import { ContactService } from "./contact.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";
import { EmailService } from "./email.service";
import { CrmUser } from "../models/user.model";
import { Contact } from "../models/contact.model";
import { Email } from "../models/email.model";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';
import { PropertyCodes } from '../models/property-codes.model';
import { PropertyIdCode } from '../models/property-id-codes.model';
import { Property } from '../models/property.model';
import { PrepopulatedTaskTypes } from 'app.constant';


@Injectable()
export class TaskService {
  private url: string = AppSettings.API_ENDPOINT + 'api/Tasks/';

  public tasksChanged = new Subject();
  public taskCounterChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private contactService: ContactService,
    private loadingService: LoadingSpinnerService,
    private emailService: EmailService
  ) { }

  getTasks(): Observable<Task[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let tasks: Task[] = (<any>response);
        tasks = tasks.map(t => this.populateTaskUsers(t));
        return tasks;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getIncompleteTasks() {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url + "Incomplete/Tasks").map(
      (response: Response) => {
        let incompleteTasks: Task[] = (<any>response); // - Don't need users here
        return incompleteTasks;
      }
    );
  }

  getIncompleteTasksByUserId(userId: number) {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url + "Incomplete/Tasks/UserId/" + userId).map(
      (response: Response) => {
        let incompleteTasks: Task[] = (<any>response); // - Don't need users here
        return incompleteTasks;
      }
    );
  }

  getTasksByUserId(userId: number): Observable<Task[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "UserId/" + userId).map(
      (response: Response) => {
        let tasks: Task[] = (<any>response);
        tasks = tasks.map(t => this.populateTaskUsers(t));
        return tasks;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getTasksByOpportunityId(opportunityId: number): Observable<Task[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "opportunityId/" + opportunityId).map(
      (response: Response) => {
        let tasks: Task[] = (<any>response);
        tasks = tasks.map(t => this.populateTaskUsers(t));
        return tasks;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getTask(id: number): Observable<Task> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let task: Task = (<any>response);
        task = this.populateTaskUsers(task);

        return task;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getPropertyCodes(): Observable<PropertyIdCode[]> {
    return this.http.get(this.url+'getpropertycodes').map(
      (response: Response) => {
        const propCodes: PropertyIdCode[] = (<any>response);
        return propCodes;
      }
    );
  }

  getProperty(id: number): Observable<Property> {
    return this.http.get(this.url+'getproperty/'+id).map(
      (response: Response) => {
        const prop: Property = (<any>response);
        return prop;
      }
    );
  }

  getPropertiesSearch(searchTerm: string, showLoading: boolean = true): Observable<PropertyIdCode[]> {
    // searchTerm = searchTerm.replace("/", " "); // - Replace '/' chars as route will change and cause issues!!

    let getTasksUrl = this.url + "propertysearch/"+ searchTerm;

    if (showLoading)
      this.loadingService.displayLoadingSpinner();

    return this.http.get(getTasksUrl).map(
      (response: Response) => {
        let property: PropertyIdCode[] = (<any>response);
        return property;
      }
    ).finally(() => {
      if (showLoading)
        this.loadingService.hideLoadingSpinner();
    });
  }

  addTask(task: Task, sendEmailToUsers: boolean, sendEmailToContacts: boolean): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      task
    ).map((response: Response) => {

     if (task.propertyId != null)
     {
      this.getProperty(task.propertyId).subscribe(
        (property: Property) => {
          this.onSendEmail((<any>response).id, sendEmailToUsers, sendEmailToContacts, task.calendarDuration,property);
         }
       );
      }
     else {
      this.onSendEmail((<any>response).id, sendEmailToUsers, sendEmailToContacts, task.calendarDuration, null);
     }

      return response;
    }).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateTask(task: Task, sendEmailToUsers: boolean, sendEmailToContacts: boolean): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();
debugger;
    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + task.id,
      task
    ).map((response: Response) => {
      //this.onSendEmail((<any>response).id, sendEmailToUsers, sendEmailToContacts, task.calendarDuration);
    
      this.getProperty(task.propertyId).subscribe(
        (property: Property) => {
          this.onSendEmail((<any>response).id, sendEmailToUsers, sendEmailToContacts, task.calendarDuration,property);
         }
       );
      

      return response;
    }).finally(() => this.loadingService.hideLoadingSpinner());
  }

  toggleTaskCompletion(task: Task) {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "ToggleCompletion/" + task.id,
      task
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteTask(task: Task): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + task.id,
      task
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  onSendEmail(id: number, sendEmailToUsers: boolean, sendEmailToContacts: boolean, calendarDuration: number, property: Property) {

    if (!sendEmailToUsers && !sendEmailToContacts)
      return; // - Return if both values are set to false

    // Retrieve task from db so we can get the contacts of the task's ContactTasks to display all contact's names in email
    this.getTask(id).subscribe(
      (task: Task) => {
        // Don't send any emails if the task is already marked as complete
        if (task.isTaskComplete)
          return;

        if (sendEmailToUsers) {
          let observableList: Observable<Response>[] = [];

          // Iterate through each agent assigned to this task
          for (let userTask of task.userTasks) {

            // Set current agent as receiver
            let receiver = this.userService.getUser(userTask.userId);
            let mail: Email = new Email();

            if (task.updatedByUserAccountId) {
              // Set updater as sender if task was edited
              let sender = this.userService.getUser(task.updatedByUserAccountId);
              mail = this.emailService.createUserTaskEmail(task, sender, receiver, calendarDuration,property);
            }
            else {
              // Otherwise set creator as sender
              let sender = this.userService.getUser(task.createdByUserAccountId);
              mail = this.emailService.createUserTaskEmail(task, sender, receiver, calendarDuration,property);
            }

            observableList.push(this.emailService.sendEmail(mail));
          }

          Observable.forkJoin(observableList).subscribe(
            (response) => console.log("Email sent."),
            (error) => console.log("Error sending email: ", error)
          );
        }

        if (sendEmailToContacts) {
          // Iterate through each contact assigned to this task
          for (let contactTask of task.contactTasks) {

            this.contactService.getContact(contactTask.contactId).subscribe(
              (contact: Contact) => {
                // Check if the contact has an email address
                if (!contact.email || contact.email.length === 0)
                  return;

                // Set current contact as receiver
                let receiver = contact;
                let mail: Email = new Email();

                if (task.updatedByUserAccountId) {
                  // Set updater as sender if task was edited
                  let sender = this.userService.getUser(task.updatedByUserAccountId);
                  mail = this.emailService.createContactTaskEmail(task, sender, receiver, calendarDuration,property);
                }
                else {
                  // Otherwise set creator as sender
                  let sender = this.userService.getUser(task.createdByUserAccountId);
                  mail = this.emailService.createContactTaskEmail(task, sender, receiver, calendarDuration,property);
                }

                this.emailService.sendEmail(mail).subscribe(
                  (response: Response) => {
                    console.log("Mail sent to contact");
                  },
                  (error: Response) => {
                    console.log("Error sending mail to contact");
                  }
                );
              }
            );
          }
        }
      }
    );
  }

  private populateTaskUsers(task: Task): Task {
   // task.createdByUserAccount = this.userService.getUser(task.createdByUserAccountId);
   // task.updatedByUserAccount = this.userService.getUser(task.updatedByUserAccountId);
    task.userTasks = task.userTasks.map(
      uT => {
       // uT.createdByUserAccount = this.userService.getUser(uT.createdByUserAccountId);
       // uT.updatedByUserAccount = this.userService.getUser(uT.updatedByUserAccountId);
       // uT.user = this.userService.getUser(uT.userId);
        return uT;
      }
    );

    return task;
  }


  /**
   *
   * @param task
   * @param checkCompleted - true if you want list without complete
   */
  isTaskLate(task: Task, checkCompleted: boolean = true) {
    let reminderDate = new Date(task.reminderDate);
    let now = new Date();

    if (checkCompleted) {
      if (task.isTaskComplete)
        return false;
    }

    // If the task is not completed and the reminder date has already passed
    if (now > reminderDate)
      return true;

    return false;
  }

  isTaskForToday(task: Task, checkCompleted: boolean = true) {
    let reminderDate = new Date(task.reminderDate).setHours(0, 0, 0, 0);
    let todayDate = new Date().setHours(0, 0, 0, 0);

    let reminderDateTime = new Date(task.reminderDate);
    let todayDateTime = new Date();

    // // If the task is not completed and the reminder date is set for today (but time has not yet passed)
    // if (!task.isTaskComplete && (todayDate === reminderDate && todayDateTime <= reminderDateTime))
    //   return true;

    if (checkCompleted) {
      if (task.isTaskComplete)
        return false;
    }

    if (todayDate === reminderDate && todayDateTime <= reminderDateTime)
      return true;

    return false;
  }
}
