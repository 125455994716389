import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { OpportunityLostType } from "../models/opportunity-lost-type.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from 'rxjs/Subject';

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class OpportunityLostTypeService {
  private url: string = AppSettings.API_ENDPOINT + 'api/opportunitylosttypes/';

  public typesChanged = new Subject();
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getOpportunityLostTypes(): Observable<OpportunityLostType[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let opportunityLostTypes: OpportunityLostType[] = (<any>response);
        opportunityLostTypes = opportunityLostTypes.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return opportunityLostTypes;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getOpportunityLostType(id: number): Observable<OpportunityLostType> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let opportunityLostType: OpportunityLostType = (<any>response);
        // opportunityLostType.createdByUserAccount = this.userService.getUser(opportunityLostType.createdByUserAccountId);
        // opportunityLostType.updatedByUserAccount = this.userService.getUser(opportunityLostType.updatedByUserAccountId);
        return opportunityLostType;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addOpportunityLostType(opportunityLostType: OpportunityLostType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      opportunityLostType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateOpportunityLostType(opportunityLostType: OpportunityLostType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + opportunityLostType.id,
      opportunityLostType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteOpportunityLostType(opportunityLostType: OpportunityLostType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + opportunityLostType.id,
      opportunityLostType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
