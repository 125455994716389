import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";

import { ContactsRoutingModule } from "./contacts-routing.module";

import { SharedModule } from "../shared/shared.module";
import { ContactTypesModule } from "./contact-types/contact-types.module";
import { ContactSourcesModule } from "./contact-sources/contact-sources.module";
import { CommunicationHistoryModule } from "./communication-history/communication-history.module";
import { ContactFilesModule } from "./contact-files/contact-files.module";
import { ContactGroupsModule } from './contact-groups/contact-groups.module';
import { ContactSavedSearchesModule } from './contact-saved-searches/contact-saved-searches.module';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CalendarModule, DropdownModule, GMapModule, MultiSelectModule } from 'primeng/primeng';

import { ContactsComponent } from "./contacts.component";
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactManageComponent } from './contact-manage/contact-manage.component';
import { ContactImportComponent } from './contact-import/contact-import.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';


@NgModule({
  declarations: [
    ContactsComponent,
    ContactListComponent,
    ContactManageComponent,
    ContactImportComponent
  ],
  imports: [
    SharedModule,
    ContactsRoutingModule,
    ContactTypesModule,
    ContactSourcesModule,
    ContactFilesModule,
    ContactGroupsModule,
    ContactSavedSearchesModule,
    CommunicationHistoryModule,
    ReactiveFormsModule,
    CollapseModule,
    CalendarModule,
    BrowserAnimationsModule,
    DropdownModule,
    DropzoneModule,
    GMapModule   
  ]
})
export class ContactsModule { }
