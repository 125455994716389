import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { CommunicationHistoryComponent } from "./communication-history.component";
import { CommunicationHistoryManageComponent } from './communication-history-manage/communication-history-manage.component';

const communicationHistoryRoutes: Routes = [
  {
    path: 'contacts',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':contactId/communication-history', component: CommunicationHistoryComponent,
        children: [
          { path: 'new', component: CommunicationHistoryManageComponent },
          { path: ':communicationLogId/details', component: CommunicationHistoryManageComponent }
        ]
      },
      {
        path: ':contactId/opportunity/:opportunityId/communication-history', component: CommunicationHistoryComponent,
        children: [
          { path: 'new', component: CommunicationHistoryManageComponent },
          { path: ':communicationLogId/details', component: CommunicationHistoryManageComponent }
        ]
      },
      {
        path: ':contactId/complaint/:complaintId/communication-history', component: CommunicationHistoryComponent,
        children: [
          { path: 'new', component: CommunicationHistoryManageComponent },
          { path: ':communicationLogId/details', component: CommunicationHistoryManageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(communicationHistoryRoutes)],
  exports: [RouterModule]
})
export class CommunicationHistoryRoutingModule { }