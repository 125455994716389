import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { CallInfo } from '../../shared/models/lead-desk/call-info.model';
import { LeadDeskConfig, CallingList } from '../../shared/models/lead-desk/lead-desk-config.model';

import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { ApiEndPoints } from 'app.constant';
import { Subject } from 'rxjs/Subject';
import { AppSettings } from 'app.settings';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class LeadDeskService {
  private url: string = AppSettings.API_ENDPOINT + 'api/LeadDesk/';

  public configChanged = new Subject();
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingSpinnerService
  ) { }

  getLeadDeskConfig(): Observable<LeadDeskConfig> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Config").map(
      (response: Response) => {
        let leadDeskConfig: LeadDeskConfig = (<any>response);

        return leadDeskConfig;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addLeadDeskConfig(leadDeskConfig: LeadDeskConfig): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url + "Config",
      leadDeskConfig
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateLeadDeskConfig(leadDeskConfig: LeadDeskConfig): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Config/" + leadDeskConfig.id,
      leadDeskConfig
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCallingLists() {
    //this.authService.reauthenticateIfTokenExpired();
    this.loadingService.displayLoadingSpinner();
    
    let url = this.url + 'CallingLists';

    return this.http.get(url).map(
      (response: Response) => {
        let callingLists: CallingList[] = (<any>response);
        return callingLists;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCallInfoByPhoneNumber(phoneNumber: string) {
    //this.authService.reauthenticateIfTokenExpired();
    this.loadingService.displayLoadingSpinner();
    
    let url = this.url + 'CallInfoByPhoneNumber?phoneNumber=' + encodeURIComponent(phoneNumber);

    return this.http.get(url).map(
      (response: Response) => {
        let callInfo: CallInfo = (<any>response);
        return callInfo;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCallInfoByCallRefId(callRefId: string) {
    //this.authService.reauthenticateIfTokenExpired();
    this.loadingService.displayLoadingSpinner();
    
    let url = this.url + 'CallInfoByCallRefId/' + callRefId;

    return this.http.get(url).map(
      (response: Response) => {
        let callInfo: CallInfo = (<any>response);
        return callInfo;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getLeadDeskContactById(contactId: string) {
    //this.authService.reauthenticateIfTokenExpired();
    this.loadingService.displayLoadingSpinner();
    
    let url = this.url + 'LeadDeskContactById/' + contactId;

    return this.http.get(url).map(
      (response: Response) => {
        let callInfo: CallInfo = (<any>response);
        return callInfo;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  mockLeadDeskPostCallEvent(callRefId: string) {
    //this.authService.reauthenticateIfTokenExpired();
    this.loadingService.displayLoadingSpinner();
    
    let url = this.url + 'CallCompletedEvent?other_i_name_1=Type of Company&fname=Joanna&phone=0733309218&last_call_id=73590&other_i_name_2=CID Number&reason_id=3&updated_at=2018-04-13 16:28:22&email=joaboq@gmail.com&last_reason=3&other_info_ft=Starta Aktiebolag&lname=Boquist&id=41582&client_id=2284&call_logs_id=73590&other_i_name_6=Serviceavtal&other_i_name_4=Antalet anställda&other_i_name_5=Övriga kommentarer&api_key=TEMP_KEY885532&other_i_name_3=Beräknad omsättning&campaign_id=1397&created_at=2018-04-13 16:24:21';

    return this.http.get(url).map(
      (response: Response) => {
        let callInfo: CallInfo = (<any>response);
        return callInfo;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
