import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { CommunicationLogType } from "../models/communication-log-type.model";

import { AuthService } from "../../auth/auth.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CommunicationHistoryTypeService {
  private url: string = AppSettings.API_ENDPOINT + 'api/communicationlogtypes/';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getCommunicationLogTypes(): Observable<CommunicationLogType[]> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url).map(
      (response: Response) => {
        const logTypes: CommunicationLogType[] = (<any>response);
        return logTypes;
      }
    );
  }

  getCommunicationLogType(id: number): Observable<CommunicationLogType> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url + id).map(
      (response: Response) => {
        const logType: CommunicationLogType = (<any>response);
        return logType;
      }
    );
  }
}
