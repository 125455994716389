export class QuoteItem {
    constructor(


        public id: number = 0,
        public description: string = null,
        public quantity: number = 0,
        public unitPrice: number=0,
        public total: number=0,
        public quoteId: number =0
    ) { }
}
