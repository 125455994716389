import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { MessageTemplate } from '../models/message-template.model';

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Rx";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class MessageTemplateService {
  private url: string = AppSettings.API_ENDPOINT + 'api/messagetemplates/';

  public messageTemplatesChanged = new Subject();
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getMessageTemplates(): Observable<MessageTemplate[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let messageTemplates: MessageTemplate[] = (<any>response);
        messageTemplates = messageTemplates.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return messageTemplates;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getMessageTemplate(id: number): Observable<MessageTemplate> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let messageTemplate: MessageTemplate = (<any>response);
        // messageTemplate.createdByUserAccount = this.userService.getUser(messageTemplate.createdByUserAccountId);
        // messageTemplate.updatedByUserAccount = this.userService.getUser(messageTemplate.updatedByUserAccountId);
        return messageTemplate;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addMessageTemplate(messageTemplate: MessageTemplate): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      messageTemplate
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateMessageTemplate(messageTemplate: MessageTemplate): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + messageTemplate.id,
      messageTemplate
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteMessageTemplate(messageTemplate: MessageTemplate): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + messageTemplate.id,
      messageTemplate
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

}
