import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'labelEmptyField'
})
export class LabelEmptyFieldPipe implements PipeTransform {
    transform(value: any, label: string) {
        if (!value)
            return label;

        return value;
    }
}