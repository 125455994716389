import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../../shared/shared.module";

import { CommunicationHistoryRoutingModule } from "./communication-history-routing.module";

import { CalendarModule, DropdownModule, EditorModule } from 'primeng/primeng';

import { CommunicationHistoryComponent } from './communication-history.component';
import { CommunicationHistoryHeaderComponent } from './communication-history-header/communication-history-header.component';
import { CommunicationHistoryListComponent } from './communication-history-list/communication-history-list.component';
import { CommunicationHistoryManageComponent } from './communication-history-manage/communication-history-manage.component';
import { CommunicationHistoryDetailComponent } from './communication-history-detail/communication-history-detail.component';

@NgModule({
  declarations: [
    CommunicationHistoryComponent,
    CommunicationHistoryHeaderComponent,
    CommunicationHistoryListComponent,
    CommunicationHistoryManageComponent,
    CommunicationHistoryDetailComponent
  ],
  imports: [
    SharedModule,
    CommunicationHistoryRoutingModule,
    DropdownModule,
    ReactiveFormsModule,
    CalendarModule,
    BrowserAnimationsModule,
    EditorModule    
  ]
})
export class CommunicationHistoryModule { }
