import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { OpportunityType } from "../models/opportunity-type.model";

import { AuthService } from "../../auth/auth.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class OpportunityTypeService {
  private url: string = AppSettings.API_ENDPOINT + 'api/opportunitytypes/';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getOpportunityTypes(): Observable<OpportunityType[]> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url).map(
      (response: Response) => {
        const opportunities: OpportunityType[] = (<any>response);
        return opportunities;
      }
    );
  }

  getOpportunityType(id: number): Observable<OpportunityType> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url + id).map(
      (response: Response) => {
        const opportunity: OpportunityType = (<any>response);
        return opportunity;
      }
    );
  }
}
