import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { OpportunityLostTypesRoutingModule } from "./opportunity-lost-types-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { OpportunityLostTypesComponent } from './opportunity-lost-types.component';
import { OpportunityLostTypeListComponent } from './opportunity-lost-type-list/opportunity-lost-type-list.component';
import { OpportunityLostTypeManageComponent } from './opportunity-lost-type-manage/opportunity-lost-type-manage.component';

@NgModule({
  declarations: [
    OpportunityLostTypesComponent,
    OpportunityLostTypeListComponent,
    OpportunityLostTypeManageComponent
  ],
  imports: [
    SharedModule,
    OpportunityLostTypesRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class OpportunityLostTypesModule { }
