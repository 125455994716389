import { NgModule } from '@angular/core';

import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { MessageCentreRoutingModule } from "./message-centre-routing.module";

import { SharedModule } from "../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap';
import { DropdownModule, EditorModule } from 'primeng/primeng';

import { MessageCentreComponent } from './message-centre.component';
import { MessageCentreSmsComponent } from './message-centre-sms/message-centre-sms.component';
import { MessageCentreEmailsComponent } from './message-centre-emails/message-centre-emails.component';
import { MessageCentreTemplatesComponent } from './message-centre-templates/message-centre-templates.component';
import { MessageCentreTemplatesManageComponent } from './message-centre-templates/message-centre-templates-manage/message-centre-templates-manage.component';
import { MessageCentreConfigSmswarriorsComponent } from './message-centre-config-smswarriors/message-centre-config-smswarriors.component';
import { MessageCentreConfigSmswarriorsManageComponent } from './message-centre-config-smswarriors/message-centre-config-smswarriors-manage/message-centre-config-smswarriors-manage.component';
import { MessageCentreConfigEmailComponent } from './message-centre-config-email/message-centre-config-email.component';
import { MessageCentreConfigEmailManageComponent } from './message-centre-config-email/message-centre-config-email-manage/message-centre-config-email-manage.component';
import { MessageCentreSignaturesComponent } from './message-centre-signatures/message-centre-signatures.component';
import { MessageCentreSignaturesManageComponent } from './message-centre-signatures/message-centre-signatures-manage/message-centre-signatures-manage.component';


@NgModule({
  imports: [
    SharedModule,
    MessageCentreRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    ModalModule.forRoot(),
    EditorModule
  ],
  declarations: [
    MessageCentreComponent, 
    MessageCentreSmsComponent, 
    MessageCentreEmailsComponent, 
    MessageCentreTemplatesComponent, 
    MessageCentreTemplatesManageComponent,
    MessageCentreConfigSmswarriorsComponent,
    MessageCentreConfigSmswarriorsManageComponent,
    MessageCentreConfigEmailComponent,
    MessageCentreConfigEmailManageComponent,
    MessageCentreSignaturesComponent,
    MessageCentreSignaturesManageComponent
  ]
})
export class MessageCentreModule { }
