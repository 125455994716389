import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { PaymentTermsRoutingModule } from "./payment-terms-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { PaymentTermsComponent } from './payment-terms.component';
import { PaymentTermListComponent } from './payment-term-list/payment-term-list.component';
import { PaymentTermManageComponent } from './payment-term-manage/payment-term-manage.component';

@NgModule({
  declarations: [
    PaymentTermsComponent,
    PaymentTermListComponent,
    PaymentTermManageComponent
  ],
  imports: [
    SharedModule,
    PaymentTermsRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class PaymentTermsModule { }
