import { InMemoryCache } from 'apollo-cache-inmemory';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { DataProxyExtensions } from './apollo-proxy.extensions';
import { AppSettings } from 'app.settings';

@NgModule({
  declarations: [],
  imports: [
    ApolloModule,
    HttpLinkModule,
    HttpClientModule
  ],
  exports: [ApolloModule],
  providers: [  ],
  entryComponents: [  ]
})
export class ApolloApplicationModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ApolloApplicationModule,
      providers: [HttpLink, DataProxyExtensions]
    };
  }

  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
  ) {

    apollo.create({
      cache: new InMemoryCache({ }),
      defaultOptions: {

        // watchQuery: {
        //   fetchPolicy: 'cache-and-network',
        //   errorPolicy: 'all',
        // },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        // watchQuery: {
        //   fetchPolicy: 'network-only',
        //   errorPolicy: 'all',
        // },
        // query: {
        //   fetchPolicy: 'network-only',
        //   errorPolicy: 'all',
        // },
        // mutation: {
        //   errorPolicy: 'all',
        // }
      },
      link: httpLink.create({
        uri: AppSettings.API_GRAPHQL,
        method: "POST",
        includeQuery: true,
      })
    });
  }
}
