import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteLandPurchaseOpportunityMutation extends Mutation {
  document = DELETE_LAND_PURCHASE_MUTATION;
}

export const DELETE_LAND_PURCHASE_MUTATION =
gql`
  mutation ($id: Int!) {
    deleteLandPurchaseOpportunity (id: $id) {
      data {
        id
        code
      }
    }
  }
`;
