import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../../auth/auth.service";
import { ComplaintTypeService } from "../../../shared/services/complaint-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { ComplaintType } from "../../../shared/models/complaint-type.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-complaint-type-list',
  templateUrl: './complaint-type-list.component.html',
  styleUrls: ['./complaint-type-list.component.scss']
})
export class ComplaintTypeListComponent implements OnInit {
  complaintTypes: ComplaintType[] = [];
  isAnim: boolean = true;

  constructor(
    public authService: AuthService,
    private complaintTypeService: ComplaintTypeService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetComplaintTypes();

    // Listen to changes made to complaintTypes
    this.complaintTypeService.typesChanged.subscribe(
      () => {
        this.onGetComplaintTypes();
      }
    );
  }

  onGetComplaintTypes() {
    this.complaintTypeService.getComplaintTypes().subscribe(
      (complaintTypes: ComplaintType[]) => {
        this.complaintTypes = complaintTypes;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving complaint types", error);
      }
    );
  }

  onDeleteComplaintType(complaintType: ComplaintType) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the complaint type ' + complaintType.name + '?',
      accept: () => {
        complaintType.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.complaintTypeService.deleteComplaintType(complaintType).subscribe(
          (response: Response) => {
            this.onGetComplaintTypes();
            this.toastService.createSuccessMessage("Success", "The complaint type " + complaintType.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting complaint type", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for complaint type " + complaintType.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
