import { Injectable } from '@angular/core';
import { Subject } from "rxjs/Rx";

@Injectable()
export class LoadingSpinnerService {
  isLoading: number = 0;

  displayLoadingSpinner() {
    this.isLoading++;
  }

  hideLoadingSpinner() {
    this.isLoading--;
  }
}
