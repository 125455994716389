import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { LeadDeskService } from '../../../shared/services/lead-desk.service';
import { AuthService } from '../../../auth/auth.service';
import { ToastService } from '../../../shared/services/toast.service';

import { LeadDeskConfig } from '../../../shared/models/lead-desk/lead-desk-config.model';

@Component({
  selector: 'app-lead-desk-config',
  templateUrl: './lead-desk-config.component.html',
  styleUrls: ['./lead-desk-config.component.scss']
})
export class LeadDeskConfigComponent implements OnInit {
  leadDeskConfigForm: FormGroup;
  isFormInit: boolean = false;
  isEdit: boolean = false;

  leadDeskConfig: LeadDeskConfig = new LeadDeskConfig();

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private leadDeskService: LeadDeskService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.leadDeskService.getLeadDeskConfig().subscribe(
      (leadDeskConfig: LeadDeskConfig) => {
        // - If API key was already submitted, update that API key
        if (leadDeskConfig) {
          this.isEdit = true;
          this.leadDeskConfig = leadDeskConfig;
        }

        this.initForm();
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lead desk config", error);
      }
    );
  }

  initForm() {
    this.leadDeskConfigForm = this.formBuilder.group({
      apiKey: this.formBuilder.control(null, [Validators.required])
    });

    this.isFormInit = true;
  }

  onAddLeadDeskConfig(newLeadDeskConfig: LeadDeskConfig) {
    this.leadDeskService.addLeadDeskConfig(newLeadDeskConfig).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "Your LeadDesk API Key has been saved.");
        this.leadDeskService.configChanged.next(); // Let list component know of changes so it know when to re-init
        
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding API key", error);
        this.leadDeskService.configChanged.next(); // Let list component know of changes so it know when to re-init        
      }
    );
  }

  onUpdateLeadDeskConfig(newLeadDeskConfig: LeadDeskConfig) {
    this.leadDeskService.updateLeadDeskConfig(newLeadDeskConfig).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The LeadDesk API Key has been updated.");
        this.leadDeskService.configChanged.next(); // Let list component know of changes so it know when to re-init
        
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating API key", error);
        this.leadDeskService.configChanged.next(); // Let list component know of changes so it know when to re-init        
      }
    );
  }

  onSubmit() {
    let newLeadDeskConfig = this.formBuilder.sanitizeFormValues(this.leadDeskConfigForm).value;

    if (this.isEdit) {
      newLeadDeskConfig.id = this.leadDeskConfig.id; // - Set id of edited leadDeskConfig to its original id
      newLeadDeskConfig.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateLeadDeskConfig(newLeadDeskConfig);
    }
    else {
      newLeadDeskConfig.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddLeadDeskConfig(newLeadDeskConfig);
    }
  }
}
