import { Component, OnInit } from '@angular/core';
import { Response } from "@angular/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService } from "../auth.service";
import { ToastService } from "../../shared/services/toast.service";

import { CrmUser } from "../../shared/models/user.model";
import { LoginModel } from "../../shared/models/login.model";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  isFormInit: boolean = false;

  loginModel: LoginModel = new LoginModel();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.signinForm = this.formBuilder.group({
      email: this.formBuilder.control(this.loginModel.Email, [Validators.required, Validators.email]),
      password: this.formBuilder.control(this.loginModel.Password, [Validators.required])
    });

    this.isFormInit = true;
  }

  onSubmit() {
    this.loginModel.Email = this.signinForm.value.email;
    this.loginModel.Password = this.signinForm.value.password;
    
    this.authService.onLogin(this.loginModel).subscribe(
      (loginSuccess: boolean) => {
        if (loginSuccess) {
          const redirectToUrl = this.route.snapshot.queryParams['returnUrl'];
          
          if (redirectToUrl)
            this.router.navigate([redirectToUrl]);
          else
            this.router.navigate(["/dashboard"]);            
        }
        else
          console.log("The access token could not be generated.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Login Failed", error);
      }
    );
  }
}
