import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { MessageSignature } from '../models/message-signature.model';

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Rx";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class MessageSignatureService {
  private url: string = AppSettings.API_ENDPOINT + 'api/messagesignatures/';

  public messageSignaturesChanged = new Subject();
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getMessageSignatures(): Observable<MessageSignature[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let messageSignatures: MessageSignature[] = (<any>response);
        messageSignatures = messageSignatures.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return messageSignatures;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getMessageSignature(id: number): Observable<MessageSignature> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let messageSignature: MessageSignature = (<any>response);
        // messageSignature.createdByUserAccount = this.userService.getUser(messageSignature.createdByUserAccountId);
        // messageSignature.updatedByUserAccount = this.userService.getUser(messageSignature.updatedByUserAccountId);
        return messageSignature;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addMessageSignature(messageSignature: MessageSignature): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      messageSignature
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateMessageSignature(messageSignature: MessageSignature): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + messageSignature.id,
      messageSignature
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteMessageSignature(messageSignature: MessageSignature): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + messageSignature.id,
      messageSignature
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

}
