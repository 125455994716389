import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { CollapseModule } from 'ngx-bootstrap/collapse';

import { OrganizationsComponent } from './organizations.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { OrganizationManageComponent } from './organization-manage/organization-manage.component';
import { OrganizationsRoutingModule } from './organizations-routing.module';
import { PaymentTermsModule } from './payment-terms/payment-terms.module';
import { DropdownModule } from 'primeng/primeng';

@NgModule({
  declarations: [
    OrganizationsComponent,
    OrganizationListComponent,
    OrganizationManageComponent
  ],
  imports: [
    SharedModule,
    OrganizationsRoutingModule,
    PaymentTermsModule,
    ReactiveFormsModule,
    DropdownModule,
    CollapseModule,
  ]
})
export class OrganizationsModule { }
