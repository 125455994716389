import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { ComplaintTypeService } from "../../../shared/services/complaint-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { ComplaintType } from "../../../shared/models/complaint-type.model";

import { valueExistsValidator } from "../../../shared/validators/value-exists.validator";

@Component({
  selector: 'app-complaint-type-manage',
  templateUrl: './complaint-type-manage.component.html',
  styleUrls: ['./complaint-type-manage.component.scss']
})
export class ComplaintTypeManageComponent implements OnInit {
  complaintTypeForm: FormGroup;       // - Stores the complaintType form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a complaintType if true and add a new complaintType if false

  complaintTypes: ComplaintType[] = [];             // - Stores all complaintTypes retrieved from db
  complaintType: ComplaintType = new ComplaintType(); // - Stores ComplaintType to populate form with a new / existing ComplaintType's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private complaintTypeService: ComplaintTypeService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.complaintTypeService.getComplaintTypes().subscribe(
      (complaintTypes: ComplaintType[]) => {
        this.complaintTypes = complaintTypes; // - Store all complaint types retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const complaintTypeId = params['id'];
            this.isEdit = complaintTypeId != null;

            if (this.isEdit) {
              this.complaintType = this.complaintTypes.find(cType => cType.id === +complaintTypeId); // - Store the selected complaintType

              // Redirect to 404 page if the selected complaintType could not be found
              if (!this.complaintType) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving complaint types", error);
      }
    );
  }

  initForm() {
    // Get all complaintType names to know if it already exists
    const complaintTypeNames = this.complaintTypes.map(cType => cType.name);

    this.complaintTypeForm = this.formBuilder.group({
      name: this.formBuilder.control(this.complaintType.name, [Validators.required, valueExistsValidator(complaintTypeNames)])
    });
    this.isFormInit = true;
  }

  onAddComplaintType(newComplaintType: ComplaintType) {
    this.complaintTypeService.addComplaintType(newComplaintType).subscribe(
      (response: Response) => {
        this.complaintTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The complaint type " + newComplaintType.name + " has been created.");
        this.router.navigate(['/complaints/types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding complaint type", error);
      }
    );
  }

  onUpdateComplaintType(newComplaintType: ComplaintType) {
    this.complaintTypeService.updateComplaintType(newComplaintType).subscribe(
      (response: Response) => {
        this.complaintTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The complaint type " + newComplaintType.name + " has been updated.");
        this.router.navigate(['/complaints/types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating complaint type", error);
      }
    );
  }

  onSubmit() {
    let newComplaintType = this.formBuilder.sanitizeFormValues(this.complaintTypeForm).value;

    if (this.isEdit) {
      newComplaintType.id = this.complaintType.id; // - Set id of edited complaintType to its original id
      newComplaintType.createdByUserAccountId = this.complaintType.createdByUserAccountId; // - Need createdById of original complaint type to check if it is a prepopulated type
      newComplaintType.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateComplaintType(newComplaintType);
    }
    else {
      newComplaintType.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddComplaintType(newComplaintType);
    }
  }
}
