import { Pipe, PipeTransform } from '@angular/core';
import { ContactTask } from "../models/contact-task.model";
@Pipe({
    name: 'assignedContacts'
})
export class AssignedContactsPipe implements PipeTransform {
    output: string = "";
    list: string[] = [];
    transform(value: ContactTask[]) {

        this.list = value.map(v => v.contact.fullName);
        this.output = "";

        this.list.forEach(
            (item, index) => {
                this.output += item;
                if (index < (this.list.length - 1)) {
                    this.output += ", ";
                }
            }
        );

        return this.output;
    }
}