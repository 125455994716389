import { Component } from '@angular/core';
import { detectBody } from '../../../app.helpers';
import { ToastService } from "../../../views/shared/services/toast.service";
import { LoadingSpinnerService } from "../../../views/shared/services/loading-spinner.service";
import { Message } from "primeng/primeng";

declare var jQuery:any;

@Component({
  selector: 'basic',
  templateUrl: 'basicLayout.template.html',
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class BasicLayoutComponent {
  constructor(
    private toastService: ToastService,
    private loadingService: LoadingSpinnerService
  ) { }

  public ngOnInit():any {
    detectBody();
  }

  public onResize(){
    detectBody();
  }

  public getToastMsgs(): Message[] {
    return this.toastService.getMessages();
  }

  public isLoading() {
    return this.loadingService.isLoading > 0;
  }
}
