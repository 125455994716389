import { CrmUser } from "./user.model";

export class EmailProfile {
  constructor(
    public id: number = 0,
    public userPartyId: number = null,
    public userParty: CrmUser = new CrmUser(),
    public name: string = null,
    public outgoingHostName: string = null,
    public outgoingPort: string = null,
    public outgoingUseSsl: string = null,
    // public incomingHostName: string = null,
    // public incomingPort: string = null,
    // public incomingUseSsl: string = null,
    public username: string = null,
    public password: string = null,
    public fromName: string = null,
    public fromEmail: string = null,
    public createdByUserAccount: CrmUser = null,
    public createdByUserAccountId: number= null,
    public createdOn: string = null,
    public updatedByUserAccount: CrmUser = null,
    public updatedByUserAccountId: number = null,
    public updatedOn: string = null,
    public status: string = null
  ) { }
}
