import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { TasksComponent } from './tasks/tasks.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ConversionsComponent } from './conversions/conversions.component';

import { ReportsRoutingModule } from "./reports-routing.module";
import { SharedModule } from "../shared/shared.module";
import { DropdownModule, CalendarModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { ComplaintsComponent } from './complaints/complaints.component';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReportsRoutingModule,
    DropdownModule,
    CalendarModule,
    BrowserAnimationsModule
  ],
  declarations: [ReportsComponent, TasksComponent, ContactsComponent, ConversionsComponent, OpportunitiesComponent,ComplaintsComponent]
})
export class ReportsModule { }
