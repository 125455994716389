import { Pipe, PipeTransform } from '@angular/core';
import { UserTask } from "../models/user-task.model";
@Pipe({
    name: 'assignedUsers'
})
export class AssignedUsersPipe implements PipeTransform {
    output: string = "";
    list: string[] = [];
    transform(value: UserTask[]) {

        this.list = value.map(v => v.user? v.user.fullName : '');
        this.output = "";

        this.list.forEach(
            (item, index) => {
                this.output += item;
                if (index < (this.list.length - 1)) {
                    this.output += ", ";
                }
            }
        );

        return this.output;
    }
}