import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { IssuesComponent } from "./issues.component";
import { IssueListComponent } from "./issue-list/issue-list.component";
import { IssueManageComponent } from "./issue-manage/issue-manage.component";

const issuesRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'issues', component: IssuesComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: IssueListComponent },
          { path: ':id/edit', component: IssueManageComponent },
          { path: 'new', component: IssueManageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(issuesRoutes)],
  exports: [RouterModule]
})
export class IssuesRoutingModule { }