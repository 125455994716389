import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { ContactSource } from "../models/contact-source.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ContactSourceService {
  private url: string = AppSettings.API_ENDPOINT + 'api/contactsources/';

  public sourcesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getContactSources(): Observable<ContactSource[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let contactSources: ContactSource[] = (<any>response);
        contactSources = contactSources.map(
          cS => {
            // cS.createdByUserAccount = this.userService.getUser(cS.createdByUserAccountId);
            // cS.updatedByUserAccount = this.userService.getUser(cS.updatedByUserAccountId);
            return cS;
          }
        );
        return contactSources;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getContactSource(id: number): Observable<ContactSource> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let contactSource: ContactSource = (<any>response);
        // contactSource.createdByUserAccount = this.userService.getUser(contactSource.createdByUserAccountId);
        // contactSource.updatedByUserAccount = this.userService.getUser(contactSource.updatedByUserAccountId);
        return contactSource;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addContactSource(contactSource: ContactSource): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      contactSource
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateContactSource(contactSource: ContactSource): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + contactSource.id,
      contactSource
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteContactSource(contactSource: ContactSource): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + contactSource.id,
      contactSource
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}