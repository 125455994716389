import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { QuoteService } from "../../../shared/services/quote.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { Quote} from "../../../shared/models/quote.model";

import { QuoteItem } from 'app/views/shared/models/quote-item.model';
import { QuoteItemFormControls } from './quote-item-form-control';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { OpportunityService } from 'app/views/shared/services/opportunity.service';
import { Opportunity } from 'app/views/shared/models/opportunity.model';
import { forEach } from '@angular/router/src/utils/collection';
import { element } from '@angular/core/src/render3';
import { FormUtilityService } from 'app/shared/form-utility.service';
import { Organization } from 'app/views/shared/models/organization.model';

@Component({
  selector: 'app-opportunity-quote-manage',
  templateUrl: './opportunity-quote-manage.component.html',
  styleUrls: ['./opportunity-quote-manage.component.scss']
})
export class OpportunityQuoteManageComponent implements OnInit {


  quoteForm: FormGroup;       // - Stores the quote form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a quote if true and add a new quote if false

  quotes: Quote[] = [];             // - Stores all quotes retrieved from db
  quote: Quote = new Quote(); // - Stores Quote to populate form with a new / existing Quote's values
  selectedPrincipal: string;       //- Stores the current Principal Before/After to detect any changes and revert if needed

  isModalShown: boolean = false;
  opportunityId: number;

  organization: Organization = new Organization(); // used to display organization details in quote

  public voyageTypeOptions: SelectItem[] = [];
  public deliveryTermOptions: SelectItem[] = [];
  public destinationOptions: SelectItem[]=[];
  public chargeOptions: SelectItem[] = [];
  public principalOptions: SelectItem[] = [];
  public hubOptions: SelectItem[] = [];

    //** DatePicker */
    minDate: Date = new Date(); // - Can't select a date before the current day
    dateRange: string = this.minDate.getFullYear() + ':' + (this.minDate.getFullYear() + 10); // - 10 Year Range

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private quoteService: QuoteService,
    private opportunityService: OpportunityService,
    private authService: AuthService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private _formUtil: FormUtilityService,
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  get quoteItems(): FormArray { return this.quoteForm.get('quoteItems') as FormArray; };
  get quoteDeliveryAddresses(): FormArray { return this.quoteForm.get('quoteDeliveryAddresses') as FormArray; };


  addItem() {

    var newQuoteItem = new QuoteItem(0, null, 0,0,0,this.quote.id);
    this.quote.quoteItems.push(newQuoteItem);
    this.quoteItems.push(this.formBuilder.group(this.populateQuoteItem(newQuoteItem)));
  }


  deleteItemByIndex(index: number) {
    this.quote.quoteItems.splice(index, 1);
    this.quoteItems.removeAt(index);
  }

  setItems(quoteItems: QuoteItemFormControls[]) {
    const quoteItemsFormGroups = quoteItems.map(r => this.formBuilder.group(r));
    const quoteItemFormArray = this.formBuilder.array(quoteItemsFormGroups, [Validators.required]);
    this.quoteForm.setControl('quoteItems', quoteItemFormArray);
  }

  populateQuoteItems(quoteItems: QuoteItem[]) {
    let quoteItemsFormControls: QuoteItemFormControls[] = [];

    quoteItems.map(
        r => {
            quoteItemsFormControls.push(this.populateQuoteItem(r));
        }
    );

    return quoteItemsFormControls;
  }

  populateQuoteItem(r: QuoteItem){
    let quoteItemFormControl = new QuoteItemFormControls();
    quoteItemFormControl.id.setValue(r.id);
    quoteItemFormControl.quoteId.setValue(r.quoteId? r.quoteId:null);

    quoteItemFormControl.unitPrice.setValue(r.unitPrice ? r.unitPrice : null);
    quoteItemFormControl.quantity.setValue(r.quantity ? r.quantity : null);
    quoteItemFormControl.description.setValue(r.description ? r.description : null);
    quoteItemFormControl.total.setValue(r.total ? r.total : null);

    return quoteItemFormControl;
  }


  removeItem(index: number) {
    this.deleteItemByIndex(index);
    this.onItemsAmountChange();
    this.onGrandTotalChange();

    if(this.quoteItems.length == 0){
      //Clean up values
      this.quoteForm.patchValue({
        subTotal: 0,
        discount:0,
        subTotalLessDiscount:0,
        totalVat:0,
        grandTotal:0
      });
    }

  }


  onValueChange(type,value){

    switch (type) {
      case 'item':
        this.onItemsAmountChange();
        break;

      case 'discount':
        this.onDiscountAmountChange(value);
        break;

      case 'vat':
        this.onVatAmountChange(value);
        break;

      default:
        break;
    }

    this.onGrandTotalChange();

  }

  onItemsAmountChange(){

  var subTotal = 0;

    this.quoteItems.controls.forEach(element => {

      let elFormGroup = element as FormGroup;

      let quantity = elFormGroup.get('quantity').value;
      let unitPrice = elFormGroup.get('unitPrice').value;


      let total = +(quantity * unitPrice).toFixed(2);

      elFormGroup.controls['total'].setValue(total);


      subTotal = subTotal + total;

    });

    subTotal = +subTotal.toFixed(2);

    this.quoteForm.patchValue({
      subTotal: subTotal
    });

    //this.onGrandTotalChange();


  }

  onDiscountAmountChange(discount: number){

    let subtotal = this.quoteForm.get('subTotal').value;
    let subTotalLessDiscount = subtotal - discount;

    this.quoteForm.patchValue({
      subTotalLessDiscount: subTotalLessDiscount
    });
    return subTotalLessDiscount;
  }


  onVatAmountChange(vat : number){

    let subTotalLessDiscount = this.quoteForm.get('subTotalLessDiscount').value;
    let totalVat =+((vat/100) * subTotalLessDiscount).toFixed(2);

    this.quoteForm.patchValue({
      totalVat: totalVat
    });

    return totalVat;

  }

  onGrandTotalChange(){


    let subTotalLessDiscount = this.quoteForm.get('subTotalLessDiscount').value;
      subTotalLessDiscount = this.onDiscountAmountChange(this.quoteForm.get('discount').value);


    let totalVat = this.quoteForm.get('totalVat').value;
      totalVat = this.onVatAmountChange(this.quoteForm.get('vat').value)


    this.quoteForm.patchValue({
      grandTotal: (totalVat + subTotalLessDiscount).toFixed(2)
    });
  }



  setupForm() {

        this.route.params.subscribe(
          (params: Params) => {
            const opportunityId = params['opportunityId'];
            const quoteId = params['id'];
            this.isEdit = quoteId != null;

            if (this.isEdit) {

              this.quoteService.getQuote(quoteId).subscribe(
                (quote: Quote) => {
                  this.quote = quote;
                  this.initForm(this.quote.opportunityId);
                  this.setItems(this.populateQuoteItems(this.quote.quoteItems));

                  if(this.quote.isSelectedInvoice || this.quote.status == "D")
                  {

                    setTimeout(()=> {
                        this.quoteForm.disable();
                    })

                  }

                },
                (error: Response) => {
                  this.toastService.createErrorMessage("Error retrieving quote", error);
                }
              )



              // Redirect to 404 page if the selected quote could not be found
              if (!this.quote) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            else{
              // Create new quote using the opportunityId passed
              this.initForm(opportunityId);
            }
          }
        );
  }

  initForm(opportunityId:number) {

    var today = new Date();
    var thisMonth = today.getMonth();
    var nextMonthDate = new Date(today.setMonth(thisMonth + 1));

    this.quoteForm = this.formBuilder.group({
      id:this.formBuilder.control(this.quote.id),
      opportunityId:this.formBuilder.control(opportunityId),
      reference: this.formBuilder.control(this.quote.reference),
      version: this.formBuilder.control(this.quote.version),
      validUntil: this.formBuilder.control( this.quote.validUntil ? new Date(this.quote.validUntil): nextMonthDate),
      quoteItems: this.formBuilder.array([], [Validators.required]),
      isSelectedInvoice: this.formBuilder.control(this.quote.isSelectedInvoice),
      subTotal: this.formBuilder.control(this.quote.subTotal),
      discount: this.formBuilder.control(this.quote.discount),
      subTotalLessDiscount: this.formBuilder.control(this.quote.subTotalLessDiscount),
      vat: this.formBuilder.control(this.quote.vat? this.quote.vat :18),
      totalVat: this.formBuilder.control(this.quote.totalVat),
      grandTotal: this.formBuilder.control(this.quote.grandTotal),
    });
    this.isFormInit = true;

    this.onGetClientDetails(opportunityId);

    return true;
  }

  // onGetQuoteItems(agentItem: string){
  //   if(agentItem != null){
  //     this.quoteService.getItems(agentItem).subscribe(
  //       (charges: Item[]) => {

  //         this.chargeOptions = []; // empty Item Items before populating new ones
  //         charges.map(cT => this.chargeOptions.push({ label: "(" + cT.code + ") " + cT.name , value: cT.code }));

  //         this.quoteForm.patchValue({
  //           quoteItems: this.quote.quoteItems
  //         });

  //         },
  //       (error: Response) => {
  //         this.toastService.createErrorMessage("Error retrieving Quote Items", error);
  //       }
  //     );
  //   }
  // }


  onGetClientDetails(opportunityId: number){

    this.opportunityService.getOpportunity(opportunityId).subscribe(
      (opportunity: any) => {



        this.organization = opportunity? opportunity.assignedContact? opportunity.assignedContact ? opportunity.assignedContact.organization: "" : "" : "";

      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving quote", error);
      }
    )
  }

  onAddQuote(newQuote: Quote) {
    this.quoteService.addQuote(newQuote).subscribe(
      (response: Response) => {
        this.quoteService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The quote " + newQuote.reference + " has been created.");
        this.router.navigate(['/opportunities','quote',(<any>response).id, 'edit']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding quote", error);
      }
    );
  }

  onUpdateQuote(newQuote: Quote) {
    this.quoteService.updateQuote(newQuote).subscribe(
      (response: Response) => {
        this.quoteService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The quote " + newQuote.reference + " has been updated.");
        this.router.navigate(['/opportunities','quote',(<any>response).id, 'edit']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating quote", error);
      }
    );
  }

  onSaveInvoice(quote: Quote) {
    this.quoteService.quoteToInvoice(quote).subscribe(
      (response: Response) => {
        this.quoteService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The quote " + quote.reference + " has been converted to invoice.");
        this.router.navigate(['/opportunities',(<any>response).opportunityId, 'edit']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating quote", error);
      }
    );
  }



  onSubmit() {
    if (this.quoteForm.valid) {
      let newQuote = this.formBuilder.sanitizeFormValues(this.quoteForm).value;

      if (this.isEdit) {
        newQuote.id = this.quote.id; // - Set id of edited quote to its original id
        newQuote.createdByUserAccountId = this.quote.createdByUserAccountId; // - Need createdById of original quote to check if it is a prepopulated type
        newQuote.updatedByUserAccountId = this.authService.applicationProfileUser().id;
        this.onUpdateQuote(newQuote);
      }
      else {
        newQuote.createdByUserAccountId = this.authService.applicationProfileUser().id;
        this.onAddQuote(newQuote);
      }
    } else {
      this._formUtil.markFormControlsAsTouched(this.quoteForm);
    }
  }

  onQuoteToInvoice() {
    this.confirmationService.confirm({
      header: 'Quote To Invoice',
      message: 'Are you sure you want to save changes and convert this quote to invoice',
      accept: () => {

        let newQuote = this.formBuilder.sanitizeFormValues(this.quoteForm).value;
        newQuote.id = this.quote.id; // - Set id of edited quote to its original id
        newQuote.createdByUserAccountId = this.quote.createdByUserAccountId; // - Need createdById of original quote to check if it is a prepopulated type
        newQuote.updatedByUserAccountId = this.authService.applicationProfileUser().id;
        this.onSaveInvoice(newQuote);

      },
      reject: () => {
        //this.router.navigate(['/contacts/']);
      }
    });
  }

  onGeneratePdf() {
    this.confirmationService.confirm({
      header: 'Generate PDF?',
      message: 'Are you sure you want to create the PDF for this Quote?',
      accept: () => {

        let newQuote = this.formBuilder.sanitizeFormValues(this.quoteForm).value;
        newQuote.id = this.quote.id; // - Set id of edited quote to its original id
        newQuote.createdByUserAccountId = this.quote.createdByUserAccountId; // - Need createdById of original quote to check if it is a prepopulated type
        newQuote.updatedByUserAccountId = this.authService.applicationProfileUser().id;
        this.onSubmitGeneratePdf(newQuote);

      },
      reject: () => {
        //this.router.navigate(['/contacts/']);
      }
    });
  }

  quotePdfUrl() {
    return `${this.quoteService.url}${this.quote.id}/pdf?access_token=${this.authService.getUser().access_token}`;
  }

  onSubmitGeneratePdf(quote: Quote) {
    this.quoteService.generatePdf(quote.id).subscribe(
      (response: Response) => {

        //this.quoteService.typesChanged.next(); // Let list component know of changes
        //this.toastService.createSuccessMessage("Success", "The quote " + quote.reference + " has been converted to booking.");
        //this.router.navigate(['/opportunities',(<any>response).opportunityId, 'edit']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating quote", error);
      }
    );
  }

  // onSubmitGeneratePdf(quote: Quote) {
  //   this.quoteService.quoteToBooking(quote).subscribe(
  //     (response: Response) => {
  //       this.quoteService.typesChanged.next(); // Let list component know of changes
  //       this.toastService.createSuccessMessage("Success", "The quote " + quote.reference + " has been converted to booking.");
  //       this.router.navigate(['/opportunities',(<any>response).opportunityId, 'edit']);
  //     },
  //     (error: Response) => {
  //       this.toastService.createErrorMessage("Error updating quote", error);
  //     }
  //   );
  // }

  cloneQuote() {
    this.confirmationService.confirm({
      header: 'Cloning Quote',
      message: 'Are you sure you want to save and clone this quote? Please make sure to change reference number after cloning.',
      accept: () => {

        let quoteToClone = this.formBuilder.sanitizeFormValues(this.quoteForm).value;
        this.onUpdateQuote(quoteToClone);

        setTimeout(()=> {
          this.quoteService.cloneQuote(quoteToClone).subscribe(
            (response: Response) => {
              this.quoteService.typesChanged.next(); // Let list component know of changes
              this.toastService.createSuccessMessage("Success", "The quote " + this.quote.reference + " has been cloned.");
              this.router.navigate(['/opportunities','quote',(<any>response).id, 'edit']);
            },
            (error: Response) => {
              this.toastService.createErrorMessage("Error adding quote", error);
            }
          )
        },200);

      },
      reject: () => {
        //this.router.navigate(['/contacts/']);
      }
    });
  }


}

