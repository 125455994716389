import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { BasicLayoutComponent } from "./components/common/layouts/basicLayout.component";
import { NotFoundComponent } from "./views/errors/not-found/not-found.component";
import { HomeComponent } from "./views/home/home.component";

import { AuthGuard } from "./views/auth/auth-guard.service";
import { ContactListComponent } from "./views/contacts/contact-list/contact-list.component";
import { ContactsComponent } from "./views/contacts/contacts.component";
import { CommunicationHistoryComponent } from "./views/contacts/communication-history/communication-history.component";

export const appRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'contacts/:contactId/communication-history', component: CommunicationHistoryComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }