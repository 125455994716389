import { FormControl, Validators, FormGroup } from "@angular/forms";

export class QuoteItemFormControls {
  constructor(
    public id: FormControl = new FormControl(null),
    public description: FormControl = new FormControl(null),
    public unitPrice: FormControl = new FormControl(null),
    public quantity: FormControl = new FormControl(null),
    public total: FormControl = new FormControl(null),
    public quoteId: FormControl = new FormControl()
    ) { }
}
