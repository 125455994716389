import { CrmUser } from "./user.model";
import { PropertyCategory } from './property-category.model';
import { PropertyRegion } from './property-region.model';
import { PropertyLocality } from './property-locality.model';
import { PropertyDealType } from './property-dealtype';
import { PropertyAttribute } from './property-attribute';

export class PropertyDetails {
    constructor(
        public mainCatIds: number[] = [],
        public mainCatId: PropertyCategory[] = null,
        public subCatIds: number[] = [],
        public subCatId: PropertyCategory[] = null,
        public regionIds: number[] = [],
        public regionId: PropertyRegion[] = null,
        public localityIds: number[] = [],
        public localityId: PropertyLocality[] = null,
        public dealTypeIds: number[] = [],
        public dealTypeId: PropertyDealType[] = null,
        public startingBudget: number = 0,
        public maximumBudget: number = 0,
        public minimumBedrooms: number = 0,
        public minimumGarages: number = 0,
        public minimumBathrooms: number = 0,
        public roomsIds: number[] = [],
        public roomId: PropertyAttribute[] = null,
        public featureIds: number[] = [],
        public featureId: PropertyAttribute[] = null,
        public propertyNotes: string = null,
        public propertyReference: string = null,
    ) { }
}