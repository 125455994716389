import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from '../api.service';
import { Profile } from './_models/profile.model';

@Injectable()
export class ProfileService {

  constructor(
    private _apiService: ApiService
  ) { }

  public getProfileByIdQuery(identifier: string,): Observable<Profile> {
    return this._apiService.get<Profile>(`api/profile/id/${identifier}`);
  }

  public getProfileBySubjectIdQuery(identifier: string): Observable<Profile> {
    return this._apiService.get<Profile>(`api/profile/subjectId/${identifier}`);
  }

  public createProfile<T>(object: T): Observable<T> {

    return this._apiService.postWithoutRequestMessage('api/profile/create', object);
  }

  public updateProfile<T>(object: T): Observable<T> {
    return this._apiService.postWithoutRequestMessage('api/profile/update', object);
  }
}
