import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { EmailProfileService } from "../../../shared/services/email-profile.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { EmailProfile } from '../../../shared/models/email-profile.model';
import { valueExistsValidator } from 'app/views/shared/validators/value-exists.validator';

@Component({
  selector: 'app-message-centre-config-email-manage',
  templateUrl: './message-centre-config-email-manage.component.html',
  styleUrls: ['./message-centre-config-email-manage.component.scss']
})
export class MessageCentreConfigEmailManageComponent implements OnInit {
  profileForm: FormGroup;          // - Stores the profile form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a message profile if true and add a new message profile if false

  emailProfiles: EmailProfile[] = [];           // - Stores all EmailProfiles retrieved from db
  profile: EmailProfile = new EmailProfile();  // - Stores EmailProfile to populate form with an existing EmailProfile's values

  messageMaxLength: number = 720;

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private emailProfileService: EmailProfileService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.emailProfileService.getEmailProfiles().subscribe(
      (emailProfiles: EmailProfile[]) => {
        this.emailProfiles = emailProfiles; // - Store all profiles retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const emailProfileId = params['id'];
            this.isEdit = emailProfileId != null;

            if (this.isEdit) {
              this.profile = this.emailProfiles.find(mT => mT.id === +emailProfileId); // - Store the selected profile

              // Redirect to 404 page if the selected profile could not be found
              if (!this.profile) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving profiles", error);
      }
    );
  }

  initForm() {
    // Get all profile names to know if it already exists
    const profileNames = this.emailProfiles.map(mT => mT.name);

    // Remove the current profile name if being edited so the name can remain unchanged
    if (this.isEdit)
      profileNames.splice(profileNames.indexOf(this.profile.name), 1);

    this.profileForm = this.formBuilder.group({
      name: this.formBuilder.control(this.profile.name, [Validators.required, valueExistsValidator(profileNames)]),
      outgoingHostName: this.formBuilder.control(this.profile.outgoingHostName, [Validators.required]),
      outgoingPort: this.formBuilder.control(this.profile.outgoingPort, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      outgoingUseSsl: this.formBuilder.control(this.profile.outgoingUseSsl ? this.profile.outgoingUseSsl : false, [Validators.required]),
      username: this.formBuilder.control(this.profile.username, [Validators.required]),
      password: this.formBuilder.control(null, this.isEdit ? null : [Validators.required]),
      fromName: this.formBuilder.control(this.profile.fromName, [Validators.required]),
      fromEmail: this.formBuilder.control(this.profile.fromEmail, [Validators.required, Validators.email]),
    });

    this.isFormInit = true;
  }

  onAddEmailProfile(newEmailProfile: EmailProfile) {
    this.emailProfileService.addEmailProfile(newEmailProfile).subscribe(
      (response: Response) => {
        this.emailProfileService.emailProfilesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The profile " + newEmailProfile.name + " has been created.");
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding profile", error);
      }
    );
  }

  onUpdateEmailProfile(newEmailProfile: EmailProfile) {
    this.emailProfileService.updateEmailProfile(newEmailProfile).subscribe(
      (response: Response) => {
        this.emailProfileService.emailProfilesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The profile " + newEmailProfile.name + " has been updated.");
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating profile", error);
      }
    );
  }

  onSubmit() {
    let newEmailProfile = this.formBuilder.sanitizeFormValues(this.profileForm).value;

    newEmailProfile.userPartyId = this.authService.applicationProfileUser().id;
    
    if (this.isEdit) {
      newEmailProfile.id = this.profile.id; // - Set id of edited emailProfile to its original id
      newEmailProfile.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateEmailProfile(newEmailProfile);
    }
    else {
      newEmailProfile.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddEmailProfile(newEmailProfile);
    }
  }
}

