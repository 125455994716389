import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { TaskTypesComponent } from "../task-types/task-types.component";
import { TaskTypeListComponent } from "../task-types/task-type-list/task-type-list.component";
import { TaskTypeManageComponent } from "../task-types/task-type-manage/task-type-manage.component";

const typesRoutes: Routes = [
    {
        path: 'tasks',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'types', component: TaskTypesComponent,
                children: [
                    {
                        path: '', component: TaskTypeListComponent,
                        children: [
                            { path: ':id/edit', component: TaskTypeManageComponent, canActivate: [RoleGuard] },
                            { path: 'new', component: TaskTypeManageComponent, canActivate: [RoleGuard] }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(typesRoutes)],
    exports: [RouterModule]
})
export class TaskTypesRoutingModule { }