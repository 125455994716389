import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})

export class AllAccountPRofilesQueryGQL extends Query {
  document = ALL_ACCOUNT_PROFILES_QUERY;
}

const ALL_ACCOUNT_PROFILES_QUERY =
// gql`
//   query {
//     accountProfiles {
//       data {
//         id
//         firstName
//         lastName
//         subjectId
//         email
//       }
//     }
//   }
// `;


gql`
  query {
    systemAccountProfiles {
      data {
        id
        firstName
        lastName
        subjectId
        email
      }
    }
  }
`;
