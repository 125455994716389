export const environment = {
  production: true,

  PORTAL_URI: `https://acquisitions.malitamalta.com/`,
  API_ENDPOINT: 'https://acquisitions-system-api.malitamalta.com/',
  API_GRAPHQL: `https://acquisitions-system-api.malitamalta.com/api/graphql`,

  AUTHORITY_ENDPOINT: 'https://authentication.malitamalta.com',

  POST_LOGOUT_REDIRECT_URI: 'https://acquisitions.malitamalta.com/',
  REDIRECT_URI: 'https://acquisitions.malitamalta.com/auth',
  SILENT_REDIRECT_URI: 'https://acquisitions.malitamalta.com/silent',

  REAP_URI: 'https://projects-api.malitamalta.com',

  CLIENT_ID: "crmacquisitionsweb",
  SCOPE: "openid profile crmacquisitionsapi.web role orchestrate.web",

  CENTRAL : `https://scenic.malitamalta.com/`,
};
