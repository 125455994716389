import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from 'app/views/shared/shared.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CalendarModule, DropdownModule, GMapModule, PanelModule, TooltipModule } from 'primeng/primeng';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { EventsListModule } from './list/events-list.module';
import { EventsManageModule } from './manage/events-manage.module';
import { EventsRoutingModule } from './events-routing.module';
import { EventsComponent } from './events.component';


@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    CollapseModule,
    CalendarModule,
    BrowserAnimationsModule,
    DropdownModule,
    DropzoneModule,
    GMapModule,
    EventsListModule,
    EventsManageModule,
    EventsRoutingModule,
    PanelModule,
    TooltipModule
  ],
  declarations: [
    EventsComponent,
    // ContactListComponent,
    // ContactManageComponent,
    // ContactImportComponent
  ]
})
export class EventsModule { }
