import { Injectable } from '@angular/core';
import { Response, URLSearchParams, Http } from "@angular/http";

import { Opportunity } from "../models/opportunity.model";
import { OpportunityType } from "../models/opportunity-type.model";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { ApiEndPoints, CustomConfigurations } from "app.constant";
import { OpportunitiesSegment } from '../models/opportunities-segment.model';
import { AppSettings } from 'app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OpportunityPropertiesNotInterested } from '../models/opportunity-properties-not-interested.model';


@Injectable()
export class OpportunityService {
  private opportunitiesUrl: string = AppSettings.API_ENDPOINT + 'api/opportunities/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getOpportunitiesSegment(params: HttpParams, showLoading: boolean = true): Observable<OpportunitiesSegment> {
    //this.authService.reauthenticateIfTokenExpired();

    let getOpportunitiesUrl = this.opportunitiesUrl;

    debugger;

    if (!CustomConfigurations.SharedOpportunities && !this.authService.isAdmin())
      // params =params.set("assignedUserAccountId", this.authService.applicationProfileUser().id.toString());

      if (showLoading)
      this.loadingService.displayLoadingSpinner();

    return this.http.get(getOpportunitiesUrl + 'GetOpportunitiesSegmented', { params }).map(
      (response: Response) => {
        
        let opportunities: Opportunity[] = (<any>response).opportunities;
        opportunities = opportunities.map(
          o => {
            // o.createdByUserAccount = this.userService.getUser(o.createdByUserAccountId);
            // o.updatedByUserAccount = this.userService.getUser(o.updatedByUserAccountId);
            // o.assignedUser = this.userService.getUser(o.assignedUserAccountId);
            return o;
          }
        );

        let opportunitySegment: OpportunitiesSegment = new OpportunitiesSegment();
        opportunitySegment.opportunities = opportunities;
        opportunitySegment.totalOpportunityCount = (<any>response).totalOpportunitiesCount;



        return opportunitySegment;
      }
    ).finally(() =>  {
      if (showLoading)
        this.loadingService.hideLoadingSpinner();
    });
  }


  getOpportunities(): Observable<Opportunity[]> {
    //this.authService.reauthenticateIfTokenExpired();

    let getOpportunitiesUrl = this.opportunitiesUrl;

    if (!CustomConfigurations.SharedOpportunities && !this.authService.isAdmin())
      getOpportunitiesUrl += "AssignedUser/" + this.authService.applicationProfileUser().id;

    this.loadingService.displayLoadingSpinner();
    return this.http.get(getOpportunitiesUrl).map(
      (response: Response) => {
        let opportunities: Opportunity[] = (<any>response);
        opportunities = opportunities.map(
          o => {
            // o.createdByUserAccount = this.userService.getUser(o.createdByUserAccountId);
            // o.updatedByUserAccount = this.userService.getUser(o.updatedByUserAccountId);
            // o.assignedUser = this.userService.getUser(o.assignedUserAccountId);
            return o;
          }
        );
        return opportunities;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getOpportunitiesForDashboard(): Observable<Opportunity[]> {
    //this.authService.reauthenticateIfTokenExpired();

    let getOpportunitiesUrl = this.opportunitiesUrl+'GetOpportunitiesForDashboard';


    this.loadingService.displayLoadingSpinner();
    return this.http.get(getOpportunitiesUrl).map(
      (response: Response) => {
        let opportunities: Opportunity[] = (<any>response);
        opportunities = opportunities.map(
          o => {
            return o;
          }
        );
        return opportunities;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  // getOpportunitiesForDashboard(): Observable<Opportunity[]> {
  //   //this.authService.reauthenticateIfTokenExpired();

  //   let getOpportunitiesUrl = this.opportunitiesUrl+'GetOpportunitiesForDashboard';


  //   this.loadingService.displayLoadingSpinner();
  //   return this.http.get(getOpportunitiesUrl).map(
  //     (response: Response) => {
  //       let opportunities: Opportunity[] = (<any>response);
  //       opportunities = opportunities.map(
  //         o => {
  //           return o;
  //         }
  //       );
  //       return opportunities;
  //     }
  //   ).finally(() => this.loadingService.hideLoadingSpinner());
  // }

  getOpportunitiesByAssignedContactId(contactId: number): Observable<Opportunity[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.opportunitiesUrl + "AssignedContact/" + contactId).map(
      (response: Response) => {
        let opportunities: Opportunity[] = (<any>response);
        opportunities = opportunities.map(
          o => {
            // o.createdByUserAccount = this.userService.getUser(o.createdByUserAccountId);
            // o.updatedByUserAccount = this.userService.getUser(o.updatedByUserAccountId);
            // o.assignedUser = this.userService.getUser(o.assignedUserAccountId);
            return o;
          }
        );
        return opportunities;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  // getOpportunitiesByTaskId(taskId: number): Observable<Opportunity[]> {
  //   //this.authService.reauthenticateIfTokenExpired();

  //   this.loadingService.displayLoadingSpinner();
  //   return this.http.get(this.opportunitiesUrl + "/Task/" + taskId).map(
  //     (response: Response) => {
  //       let opportunities: Opportunity[] = (<any>response);
  //       opportunities = opportunities.map(
  //         o => {
  //           o.createdByUserAccount = this.userService.getUser(o.createdByUserAccountId);
  //           o.updatedByUserAccount = this.userService.getUser(o.updatedByUserAccountId);
  //           o.assignedUser = this.userService.getUser(o.assignedUserAccountId);
  //           return o;
  //         }
  //       );
  //       return opportunities;
  //     }
  //   ).finally(() => this.loadingService.hideLoadingSpinner());
  // }

  getOpportunity(id: number): Observable<Opportunity> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.opportunitiesUrl + 'getopportunity/' + id).map(
      (response: Response) => {
        let opportunity: Opportunity = (<any>response);
        // opportunity.createdByUserAccount = this.userService.getUser(opportunity.createdByUserAccountId);
        // opportunity.updatedByUserAccount = this.userService.getUser(opportunity.updatedByUserAccountId);
        // opportunity.assignedUser = this.userService.getUser(opportunity.assignedUserAccountId);
        return opportunity;
      }
    );
  }

  addOpportunity(opportunity: Opportunity): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.opportunitiesUrl + "postopportunity",
      opportunity
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateOpportunity(opportunity: Opportunity): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.opportunitiesUrl + "updateopportunity/" + opportunity.id,
      opportunity
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteOpportunity(opportunity: Opportunity): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.opportunitiesUrl + "delete/" + opportunity.id,
      opportunity
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getViewingsByContactId(id: number): Observable<number[]> {

    return this.http.get(this.opportunitiesUrl + 'getviewingsbycontactId/' + id).map(
      (response: Response) => {
        let viewings: number[] = (<any>response);
        return viewings;
      }
    );
  }

  addPropertyNotInterested(oppPropNotInterested: OpportunityPropertiesNotInterested): Observable<Response> {

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.opportunitiesUrl + "addpropertynotinterested",
      oppPropNotInterested
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  undoPropertyNotInterested(oppPropNotInterested: OpportunityPropertiesNotInterested): Observable<Response> {

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.opportunitiesUrl + "undopropertynotinterested",
      oppPropNotInterested
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getPropertiesNotInterested(oppId: number): Observable<number[]> {
    return this.http.get(this.opportunitiesUrl + 'getopppropertynotinterested/' + oppId).map(
      (response: Response) => {
        let notInterestedProp: number[] = (<any>response);
        return notInterestedProp;
      }
    )
  }
}
