import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { CommunicationLog } from "../models/communication-log.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { AuthHttp } from "angular2-jwt/angular2-jwt";
import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";
import { CrmUser } from "../models/user.model";

import { ApiEndPoints, CustomConfigurations } from "app.constant";
import { AppSettings } from 'app.settings';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CommunicationHistoryService {
  private url: string = AppSettings.API_ENDPOINT + 'api/communicationlogs/';

  public logsChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getCommunicationLogs(): Observable<CommunicationLog[]> {
    //this.authService.reauthenticateIfTokenExpired();

    let getCommLogsUrl = this.url;

    if (!CustomConfigurations.SharedCommLogs && !this.authService.isAdmin())
      getCommLogsUrl += "?assignedUserAccountId=" + this.authService.applicationProfileUser().id;

    this.loadingService.displayLoadingSpinner();
    return this.http.get(getCommLogsUrl).map(
      (response: Response) => {
        let commLogs: CommunicationLog[] = (<any>response);
        commLogs = commLogs.map(
          cL => {
            // cL.assignedUser = this.userService.getUser(cL.assignedUserAccountId);
            // cL.createdByUserAccount = this.userService.getUser(cL.createdByUserAccountId);
            // cL.updatedByUserAccount = this.userService.getUser(cL.updatedByUserAccountId);
            return cL;
          }
        );
        return commLogs;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCommunicationLogsByContactId(contactId: number, includeOpportunities: boolean = true): Observable<CommunicationLog[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();

    let getCommLogUrl = this.url + "ContactId/" + contactId;

    if (!includeOpportunities)
      getCommLogUrl += "/ExcludeOpportunities";

    return this.http.get(getCommLogUrl).map(
      (response: Response) => {
        let commLogs: CommunicationLog[] = (<any>response);
        commLogs = commLogs.map(
          cL => {
            // cL.assignedUser = this.userService.getUser(cL.assignedUserAccountId);
            // cL.createdByUserAccount = this.userService.getUser(cL.createdByUserAccountId);
            // cL.updatedByUserAccount = this.userService.getUser(cL.updatedByUserAccountId);
            return cL;
          }
        );
        return commLogs;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCommunicationLogsByOpportunityId(opportunityId: number): Observable<CommunicationLog[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "OpportunityId/" + opportunityId).map(
      (response: Response) => {
        let commLogs: CommunicationLog[] = (<any>response);
        commLogs = commLogs.map(
          cL => {
            // cL.assignedUser = this.userService.getUser(cL.assignedUserAccountId);
            // cL.createdByUserAccount = this.userService.getUser(cL.createdByUserAccountId);
            // cL.updatedByUserAccount = this.userService.getUser(cL.updatedByUserAccountId);
            return cL;
          }
        );
        return commLogs;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCommunicationLogsByComplaintId(complaintId: number): Observable<CommunicationLog[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "ComplaintId/" + complaintId).map(
      (response: Response) => {
        let commLogs: CommunicationLog[] = (<any>response);
        commLogs = commLogs.map(
          cL => {
            // cL.assignedUser = this.userService.getUser(cL.assignedUserAccountId);
            // cL.createdByUserAccount = this.userService.getUser(cL.createdByUserAccountId);
            // cL.updatedByUserAccount = this.userService.getUser(cL.updatedByUserAccountId);
            return cL;
          }
        );
        return commLogs;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getCommunicationLog(id: number): Observable<CommunicationLog> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let commLog: CommunicationLog = (<any>response);
        // commLog.assignedUser = this.userService.getUser(commLog.assignedUserAccountId);
        // commLog.createdByUserAccount = this.userService.getUser(commLog.createdByUserAccountId);
        // commLog.updatedByUserAccount = this.userService.getUser(commLog.updatedByUserAccountId);
        return commLog;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addCommunicationLog(communicationLog: CommunicationLog): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      communicationLog
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateCommunicationLog(communicationLog: CommunicationLog): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + communicationLog.id,
      communicationLog
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteCommunicationLog(communicationLog: CommunicationLog): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + communicationLog.id,
      communicationLog
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}