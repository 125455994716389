import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { OpportunityLostTypeService } from "../../../shared/services/opportunity-lost-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { OpportunityLostType } from "../../../shared/models/opportunity-lost-type.model";

import { valueExistsValidator } from "../../../shared/validators/value-exists.validator";

@Component({
  selector: 'app-opportunity-lost-type-manage',
  templateUrl: './opportunity-lost-type-manage.component.html',
  styleUrls: ['./opportunity-lost-type-manage.component.scss']
})
export class OpportunityLostTypeManageComponent implements OnInit {
  opportunityLostTypeForm: FormGroup;       // - Stores the opportunityLostType form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a opportunityLostType if true and add a new opportunityLostType if false

  opportunityLostTypes: OpportunityLostType[] = [];             // - Stores all opportunityLostTypes retrieved from db
  opportunityLostType: OpportunityLostType = new OpportunityLostType(); // - Stores OpportunityLostType to populate form with a new / existing OpportunityLostType's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private opportunityLostTypeService: OpportunityLostTypeService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.opportunityLostTypeService.getOpportunityLostTypes().subscribe(
      (opportunityLostTypes: OpportunityLostType[]) => {
        this.opportunityLostTypes = opportunityLostTypes; // - Store all opportunity lost types retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const opportunityLostTypeId = params['id'];
            this.isEdit = opportunityLostTypeId != null;

            if (this.isEdit) {
              this.opportunityLostType = this.opportunityLostTypes.find(cType => cType.id === +opportunityLostTypeId); // - Store the selected opportunityLostType

              // Redirect to 404 page if the selected opportunityLostType could not be found
              if (!this.opportunityLostType) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunity lost types", error);
      }
    );
  }

  initForm() {
    // Get all opportunityLostType names to know if it already exists
    const opportunityLostTypeNames = this.opportunityLostTypes.map(cType => cType.name);

    this.opportunityLostTypeForm = this.formBuilder.group({
      name: this.formBuilder.control(this.opportunityLostType.name, [Validators.required, valueExistsValidator(opportunityLostTypeNames)])
    });
    this.isFormInit = true;
  }

  onAddOpportunityLostType(newOpportunityLostType: OpportunityLostType) {
    this.opportunityLostTypeService.addOpportunityLostType(newOpportunityLostType).subscribe(
      (response: Response) => {
        this.opportunityLostTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The opportunity lost type " + newOpportunityLostType.name + " has been created.");
        this.router.navigate(['/opportunities/lost-types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding opportunity lost type", error);
      }
    );
  }

  onUpdateOpportunityLostType(newOpportunityLostType: OpportunityLostType) {
    this.opportunityLostTypeService.updateOpportunityLostType(newOpportunityLostType).subscribe(
      (response: Response) => {
        this.opportunityLostTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The opportunity lost type " + newOpportunityLostType.name + " has been updated.");
        this.router.navigate(['/opportunities/lost-types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating opportunity lost type", error);
      }
    );
  }

  onSubmit() {
    let newOpportunityLostType = this.formBuilder.sanitizeFormValues(this.opportunityLostTypeForm).value;

    if (this.isEdit) {
      newOpportunityLostType.id = this.opportunityLostType.id; // - Set id of edited opportunityLostType to its original id
      newOpportunityLostType.createdByUserAccountId = this.opportunityLostType.createdByUserAccountId; // - Need createdById of original opportunity lost type to check if it is a prepopulated type
      newOpportunityLostType.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateOpportunityLostType(newOpportunityLostType);
    }
    else {
      newOpportunityLostType.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddOpportunityLostType(newOpportunityLostType);
    }
  }
}
