import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { CollapseModule } from 'ngx-bootstrap/collapse';

import { CompanyDetailsComponent } from './company-details.component';
import { CompanyDetailsManageComponent } from './company-details-manage/company-details-manage.component';
import { CompanyDetailsRoutingModule } from './company-details-routing.module';
import { DropdownModule } from 'primeng/primeng';


@NgModule({
  declarations: [
    CompanyDetailsComponent,
    CompanyDetailsManageComponent
  ],
  imports: [
    SharedModule,
    CompanyDetailsRoutingModule,
    ReactiveFormsModule,
    DropdownModule,
    CollapseModule,
  ]
})
export class CompanyDetailsModule { }
