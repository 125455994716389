import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager, PrepopulatedEntities, PrepopulatedContactTypes, PrepopulatedContactSources, PrepopulatedOpportunityTypes } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";
import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { UserService } from "../../shared/services/user.service";
import { ContactService } from "../../shared/services/contact.service";

import { SelectItem, ConfirmationService, AutoComplete } from "primeng/primeng";
import { Observable } from "rxjs/Rx";
import { CrmUser } from "../../shared/models/user.model";
import { Contact } from "../../shared/models/contact.model";


@Component({
  selector: 'app-issue-manage',
  templateUrl: './issue-manage.component.html',
  styleUrls: ['./issue-manage.component.scss']
})
export class IssueManageComponent implements OnInit {
  issueForm: FormGroup;                     // - Stores the opportunity form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update an opportunity if true and add a new opportunity if false

  assignedUserOptions: SelectItem[] = [];         // - Stores Users to select the Assigned User of this opportunity
  assignedContactOptions: SelectItem[] = [];      // - Stores Contacts to select the Assigned Contact of this opportunity
  statusOptions:SelectItem[] = [];

   //** DatePicker */
   minDate: Date = new Date(); // - Can't select a date before the current day
   dateRange: string = this.minDate.getFullYear() + ':' + (this.minDate.getFullYear() + 10); // - 10 Year Range
 
   //** Spinner (Calendar event duration) */
   defaultCalendarDuration: number = 30; // - Default Duration
   minCalendarDuration: number = 5;      // - Duration in Minutes
   maxCalendarDuration: number = 600;

  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;
  constructor(    
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private contactService: ContactService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private toastService: ToastService) { }

  ngOnInit() {    
    this.issueForm = this.formBuilder.group({});
    this.onGetStatuses();
    this.onGetClients();
    this.onGetAgents();
  }

  onGetStatuses(){
 
    this.statusOptions.push({ label: "New", value: "New" });
    this.statusOptions.push({ label: "In Progress", value: "In Progress" });
    this.statusOptions.push({ label: "Resolved", value: "Resolved" });
    this.statusOptions.push({ label: "Closed", value: "Closed" });
  }

  
  onGetClients(){
    this.assignedContactOptions.push({ label: "Joe Borg", value: "New" });
    this.assignedContactOptions.push({ label: "Peter Abela", value: "In Progress" });
    this.assignedContactOptions.push({ label: "Fred Smith", value: "Resolved" });
    this.assignedContactOptions.push({ label: "Paul Walker", value: "Closed" });

  }

  
  onGetAgents(){
    this.assignedUserOptions.push({ label: "Alan Darmanin", value: "New" });
    this.assignedUserOptions.push({ label: "Gilbert Faenza", value: "In Progress" });
  }

}
