import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { SmsWarriorsProfileService } from "../../../shared/services/sms-warriors-profile.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { SmsWarriorsProfile } from '../../../shared/models/sms-warriors-profile.model';
import { valueExistsValidator } from 'app/views/shared/validators/value-exists.validator';

@Component({
  selector: 'app-message-centre-config-smswarriors-manage',
  templateUrl: './message-centre-config-smswarriors-manage.component.html',
  styleUrls: ['./message-centre-config-smswarriors-manage.component.scss']
})
export class MessageCentreConfigSmswarriorsManageComponent implements OnInit {
  profileForm: FormGroup;          // - Stores the profile form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a message profile if true and add a new message profile if false

  smsWarriorsProfiles: SmsWarriorsProfile[] = [];           // - Stores all smsWarriorsProfiles retrieved from db
  profile: SmsWarriorsProfile = new SmsWarriorsProfile();  // - Stores SmsWarriorsProfile to populate form with an existing SmsWarriorsProfile's values

  messageMaxLength: number = 720;

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private smsWarriorsProfileService: SmsWarriorsProfileService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.smsWarriorsProfileService.getSmsWarriorProfiles().subscribe(
      (smsWarriorsProfiles: SmsWarriorsProfile[]) => {
        this.smsWarriorsProfiles = smsWarriorsProfiles; // - Store all profiles retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const smsWarriorsProfileId = params['id'];
            this.isEdit = smsWarriorsProfileId != null;

            if (this.isEdit) {
              this.profile = this.smsWarriorsProfiles.find(mT => mT.id === +smsWarriorsProfileId); // - Store the selected profile

              // Redirect to 404 page if the selected profile could not be found
              if (!this.profile) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving profiles", error);
      }
    );
  }

  initForm() {
    // Get all profile names to know if it already exists
    const profileNames = this.smsWarriorsProfiles.map(mT => mT.name);

    // Remove the current profile name if being edited so the name can remain unchanged
    if (this.isEdit)
      profileNames.splice(profileNames.indexOf(this.profile.name), 1);

    this.profileForm = this.formBuilder.group({
      name: this.formBuilder.control(this.profile.name, [Validators.required, valueExistsValidator(profileNames)]),
      companyName: this.formBuilder.control(this.profile.companyName, [Validators.required, Validators.maxLength(11)]),
      apiKey: this.formBuilder.control(null, this.isEdit ? null : [Validators.required])
    });

    this.isFormInit = true;
  }

  onAddSmsWarriorsProfile(newSmsWarriorsProfile: SmsWarriorsProfile) {
    this.smsWarriorsProfileService.addSmsWarriorProfile(newSmsWarriorsProfile).subscribe(
      (response: Response) => {
        this.smsWarriorsProfileService.smsWarriorsProfilesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The profile " + newSmsWarriorsProfile.name + " has been created.");
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding profile", error);
      }
    );
  }

  onUpdateSmsWarriorsProfile(newSmsWarriorsProfile: SmsWarriorsProfile) {
    this.smsWarriorsProfileService.updateSmsWarriorProfile(newSmsWarriorsProfile).subscribe(
      (response: Response) => {
        this.smsWarriorsProfileService.smsWarriorsProfilesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The profile " + newSmsWarriorsProfile.name + " has been updated.");
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating profile", error);
      }
    );
  }

  onSubmit() {
    let newSmsWarriorsProfile = this.formBuilder.sanitizeFormValues(this.profileForm).value;
    
    if (this.isEdit) {
      newSmsWarriorsProfile.id = this.profile.id; // - Set id of edited smsWarriorsProfile to its original id
      newSmsWarriorsProfile.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateSmsWarriorsProfile(newSmsWarriorsProfile);
    }
    else {
      newSmsWarriorsProfile.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddSmsWarriorsProfile(newSmsWarriorsProfile);
    }
  }
}
