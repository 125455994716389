import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";
import { RoleGuard } from '../auth/role-guard.service';

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";

import { MessageCentreComponent } from '../message-centre/message-centre.component';
import { MessageCentreSmsComponent } from '../message-centre/message-centre-sms/message-centre-sms.component';
import { MessageCentreEmailsComponent } from '../message-centre/message-centre-emails/message-centre-emails.component';
import { MessageCentreTemplatesComponent } from '../message-centre/message-centre-templates/message-centre-templates.component';
import { MessageCentreTemplatesManageComponent } from '../message-centre/message-centre-templates/message-centre-templates-manage/message-centre-templates-manage.component';
import { MessageCentreConfigSmswarriorsComponent } from '../message-centre/message-centre-config-smswarriors/message-centre-config-smswarriors.component';
import { MessageCentreConfigSmswarriorsManageComponent } from '../message-centre/message-centre-config-smswarriors/message-centre-config-smswarriors-manage/message-centre-config-smswarriors-manage.component';
import { MessageCentreConfigEmailComponent } from 'app/views/message-centre/message-centre-config-email/message-centre-config-email.component';
import { MessageCentreConfigEmailManageComponent } from 'app/views/message-centre/message-centre-config-email/message-centre-config-email-manage/message-centre-config-email-manage.component';
import { MessageCentreSignaturesComponent } from '../message-centre/message-centre-signatures/message-centre-signatures.component';
import { MessageCentreSignaturesManageComponent } from '../message-centre/message-centre-signatures/message-centre-signatures-manage/message-centre-signatures-manage.component';


const messageCentreRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'messaging-centre', component: MessageCentreComponent,
        children: [
          { path: '', redirectTo: 'sms', pathMatch: 'full' },
          { path: 'sms', component: MessageCentreSmsComponent },
          { path: 'email', component: MessageCentreEmailsComponent },
          {
            path: 'config/sms',
            component: MessageCentreConfigSmswarriorsComponent,
            canActivate: [RoleGuard],
            children: [
              { path: 'new', component: MessageCentreConfigSmswarriorsManageComponent },
              { path: ':id/edit', component: MessageCentreConfigSmswarriorsManageComponent }
            ]
          },
          {
            path: 'config/email',
            component: MessageCentreConfigEmailComponent,
            children: [
              { path: 'new', component: MessageCentreConfigEmailManageComponent },
              { path: ':id/edit', component: MessageCentreConfigEmailManageComponent }
            ]
          },
          {
            path: 'templates',
            component: MessageCentreTemplatesComponent,
            children: [
              { path: 'new', component: MessageCentreTemplatesManageComponent },
              { path: ':id/edit', component: MessageCentreTemplatesManageComponent }
            ]
          },
          {
            path: 'signatures',
            canActivate: [RoleGuard],
            component: MessageCentreSignaturesComponent,
            children: [
              { path: 'new', component: MessageCentreSignaturesManageComponent },
              { path: ':id/edit', component: MessageCentreSignaturesManageComponent }
            ]
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(messageCentreRoutes)],
  exports: [RouterModule]
})
export class MessageCentreRoutingModule { }
