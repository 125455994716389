import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { ContactTypesRoutingModule } from "./contact-types-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { ContactTypesComponent } from './contact-types.component';
import { ContactTypeListComponent } from './contact-type-list/contact-type-list.component';
import { ContactTypeManageComponent } from './contact-type-manage/contact-type-manage.component';

@NgModule({
  declarations: [
    ContactTypesComponent,
    ContactTypeListComponent,
    ContactTypeManageComponent
  ],
  imports: [
    SharedModule,
    ContactTypesRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class ContactTypesModule { }
