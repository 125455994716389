import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { SavedContactSearchService } from "../../../shared/services/saved-contact-search.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { SavedContactSearch } from "../../../shared/models/saved-contact-search.model";

@Component({
  selector: 'app-contact-saved-search-manage',
  templateUrl: './contact-saved-search-manage.component.html',
  styleUrls: ['./contact-saved-search-manage.component.scss']
})
export class ContactSavedSearchManageComponent implements OnInit {
  savedContactSearchForm: FormGroup;       // - Stores the savedContactSearch form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised

  savedContactSearch: SavedContactSearch = new SavedContactSearch(); // - Stores SavedContactSearch to populate form with a new / existing SavedContactSearch's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private savedContactSearchService: SavedContactSearchService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const savedContactSearchId = params['id'];

        this.savedContactSearchService.getSavedContactSearch(+savedContactSearchId).subscribe(
          (savedContactSearch: SavedContactSearch) => {
            this.savedContactSearch = savedContactSearch;

            // Redirect to 404 page if the selected savedContactSearch could not be found
            if (!this.savedContactSearch) {
              this.router.navigate(['/error/404']);
              return;
            }

            this.initForm();
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error retrieving saved contact search", error);
          }
        );
      }
    );
  }

  initForm() {
    this.savedContactSearchForm = this.formBuilder.group({
      name: this.formBuilder.control(this.savedContactSearch.name, [Validators.required])
    });
    this.isFormInit = true;
  }

  onUpdateSavedContactSearch(newSavedContactSearch: SavedContactSearch) {
    this.savedContactSearchService.updateSavedContactSearch(newSavedContactSearch).subscribe(
      (response: Response) => {
        this.savedContactSearchService.savedContactSearchesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The saved contact search " + newSavedContactSearch.name + " has been updated.");
        this.router.navigate(['/contacts/saved-searches']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating saved contact search", error);
      }
    );
  }

  onSubmit() {
    let newSavedContactSearch = this.formBuilder.sanitizeFormValues(this.savedContactSearchForm).value;

    newSavedContactSearch.id = this.savedContactSearch.id; // - Set id of edited savedContactSearch to its original id
    newSavedContactSearch.updatedByUserAccountId = this.authService.applicationProfileUser().id;
    this.onUpdateSavedContactSearch(newSavedContactSearch);
  }
}
