import { Component } from '@angular/core';
import { LoadingSpinnerService } from "../../../views/shared/services/loading-spinner.service";
import { ToastService } from "../../../views/shared/services/toast.service";
import { Message } from "primeng/primeng";

declare var jQuery: any;

@Component({
  selector: 'blank',
  templateUrl: 'blankLayout.template.html'
})
export class BlankLayoutComponent {

  constructor(
    private loadingService: LoadingSpinnerService,
    private toastService: ToastService
  )
  { }

  ngAfterViewInit() {
    jQuery('body').addClass('gray-bg');
  }

  ngOnDestroy() {
    jQuery('body').removeClass('gray-bg');
  }

  public getToastMsgs(): Message[] {
    return this.toastService.getMessages();
  }

  public isLoading() {
    return this.loadingService.isLoading > 0;
  }
}
