import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { CompanyDetailsComponent } from "../company-details/company-details.component";
import { CompanyDetailsManageComponent } from "../company-details/company-details-manage/company-details-manage.component";

const companyDetailsRoutes: Routes = [
    {
        path: '',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'settings', component: CompanyDetailsComponent,
            children: [
              { path: '', redirectTo: 'company-details', pathMatch: 'full' },
              { path: 'company-details', component: CompanyDetailsManageComponent},
              // { path: ':id/edit', component: CompanyDetailsManageComponent },
              // { path: 'new', component: CompanyDetailsManageComponent }
            ]
          }
        ]
      }
];

@NgModule({
    imports: [RouterModule.forChild(companyDetailsRoutes)],
    exports: [RouterModule]
})
export class CompanyDetailsRoutingModule { }
