import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { TaskService } from "../../shared/services/task.service";
import { ConfirmationService, SelectItem } from "primeng/components/common/api";
import { ToastService } from "../../shared/services/toast.service";
import { TableUtilityService } from '../../shared/services/table-utility.service';
import { CookieService } from 'ngx-cookie';

import { Task } from "../../shared/models/task.model";
import { CrmUser } from "../../shared/models/user.model";

import { Subject } from "rxjs/Rx";

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  tasks: Task[] = [];     // - List of tasks displayed in table
  allTasks: Task[] = [];  // - All tasks retrieved from db

  showPendingTasks: boolean = true; // - Completed tasks are shown instead when set to false
  isDashboard: boolean = this.router.url === '/dashboard'; // - Need to know if Dashboard to only show todays tasks

  /** Column Toggling */
  columnOptions: SelectItem[];
  selectedCols: any[];              // - Contains the table columns that should be visible
  allSelectableCols: any[] = [];    // - Contains all table columns that may be toggled on or off

  selectedColsDefault: any[] = [];  // - Contains the columns that are shown by default if no cookie is stored

  constructor(
    private router: Router,
    public authService: AuthService,
    private taskService: TaskService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cookieService: CookieService,
    private tableUtilService: TableUtilityService
  ) { }

  ngOnInit() {
    this.onGetTasks();
    this.initColumnOptions();

    // Listen to changes made to tasks
    this.taskService.tasksChanged.subscribe(
      () => {
        this.onGetTasks();
      }
    );
  }

  initColumnOptions() {
    this.allSelectableCols = this.tableUtilService.getAllSelectableTaskCols();
    this.selectedColsDefault = this.tableUtilService.getSelectedTaskColsDefault();
    this.columnOptions = this.tableUtilService.getColumnOptions(this.columnOptions, this.allSelectableCols);

    let selectedColsCookie = this.cookieService.getObject("crm_selectedTaskCols") as any[];
    if (selectedColsCookie)
      this.selectedCols = selectedColsCookie;
    else
      this.selectedCols = this.selectedColsDefault;
  }

  // - Saves options in a cookie whenever they are changed
  onColumnOptionsChange() {
    this.cookieService.putObject("crm_selectedTaskCols", this.selectedCols);
  }

  isColVisible(colName: string) {
    if (this.selectedCols.find(sC => sC.value === colName))
      return true;

    return false;
  }

  onGetTasks() {
    // Admins can see everyone's tasks
    if (this.authService.isAdmin()) {
      this.taskService.getTasks().subscribe(
        (tasks: Task[]) => {

          //tasks[0].userTasks[0].user.appUserRoles
          this.storeTasks(tasks);
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    }
    // Users may only see their own tasks
    else {
      this.taskService.getTasksByUserId(this.authService.applicationProfileUser().id).subscribe(
        (tasks: Task[]) => {
          this.storeTasks(tasks);
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    }
  }

  storeTasks(tasks: Task[]) {
    this.allTasks = tasks; // - Keep all tasks stored here so we can filter from this list repeatedly

    // - Dashboard shows task records that are for today (and also late tasks)
    if (this.isDashboard)
      this.allTasks = this.allTasks.filter(t =>
        this.taskService.isTaskForToday(t, false) ||
        this.taskService.isTaskLate(t, true));

    this.filterTasksShown();
  }

  onAskForCommunicationLog(task: Task) {
    let firstContact = task.contactTasks[0].contact;

    this.confirmationService.confirm({
      header: 'Communication Log',
      message: 'The task has been marked as complete. Would you now like to create a new communication log for ' + firstContact.fullName + '?',
      accept: () => {
        this.router.navigate(['/contacts', firstContact.id, 'communication-history', 'new']);
      }
    });
  }

  onCompleteTask(task: Task) {
    let completedTask = this.allTasks.find(t => t.id === task.id);
    task.updatedByUserAccountId = this.authService.applicationProfileUser().id; // Actual task

    this.taskService.toggleTaskCompletion(task).subscribe(
      (response: Response) => {
        // NOTE: The update is made in the API!!!
        // Here I am setting the task to complete because the update was successful, so we want the change to be shown to the user
        completedTask.isTaskComplete = !completedTask.isTaskComplete;
        this.taskService.taskCounterChanged.next();
        this.taskService.tasksChanged.next();

        // Only ask for new comm. log if task is complete and is assigned to at least one contact
        if (task.contactTasks.length > 0 && completedTask.isTaskComplete)
          this.onAskForCommunicationLog(completedTask);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error completing task", error);
      }
    );
  }

  onDeleteTask(task: Task) {
    this.confirmationService.confirm({
      header: 'Delete Task',
      message: 'Are you sure you want to delete this task?',
      accept: () => {
        task.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.taskService.deleteTask(task).subscribe(
          (response: Response) => {
            this.onGetTasks();
            // this.taskService.tasksChanged.next();
            this.taskService.taskCounterChanged.next();
            this.toastService.createSuccessMessage("Success", "The task has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting task", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for the task has been aborted.");
      }
    });
  }

  taskStatusSort(event) {
    let taskService = this.taskService;

    let comparer = function (a: Task, b: Task): number {
      let result: number = -1;

      // Late Tasks
      if (taskService.isTaskLate(a) && !taskService.isTaskLate(b))
        result = 1;
      else if (!taskService.isTaskLate(a) && taskService.isTaskLate(b))
        result = -1;
      else if (taskService.isTaskLate(a) && taskService.isTaskLate(b)) {
        if (a.reminderDate >= b.reminderDate)
          result = 1;
        else
          result = -1;
      }

      // Today Tasks
      else if (taskService.isTaskForToday(a) && !taskService.isTaskForToday(b))
        result = 1;
      else if (!taskService.isTaskForToday(a) && taskService.isTaskForToday(b))
        result = -1;
      else if (taskService.isTaskForToday(a) && taskService.isTaskForToday(b)) {
        if (a.reminderDate >= b.reminderDate)
          result = 1;
        else
          result = -1;
      }

      // No longer needed as completed tasks are in their own tab view now
      //// Completed Tasks
      // else if (a.isTaskComplete && !b.isTaskComplete)
      //   result = -1;
      // else if (!a.isTaskComplete && b.isTaskComplete)
      //   result = 1;
      // else if (a.isTaskComplete && b.isTaskComplete) {
      //   if (a.reminderDate >= b.reminderDate)
      //     result = -1;
      //   else
      //     result = 1;
      // }

      // Pending Tasks
      else
        if (a.reminderDate >= b.reminderDate)
          result = 1;
        else
          result = -1;

      return result * event.order;
    };

    let sortedTasks = this.tasks.sort(comparer).slice();
    // this.tasks = sortedTasks.map(a => { return { ...a } });
    this.tasks = [...sortedTasks];
  }

  onChangeTasksTabView() {
    this.showPendingTasks = !this.showPendingTasks;
    this.filterTasksShown();
  }

  filterTasksShown() {
    if (this.showPendingTasks)
      this.tasks = this.allTasks.filter(t => !t.isTaskComplete);
    else
      this.tasks = this.allTasks.filter(t => t.isTaskComplete);
  }

  rowStyle(task: Task, rowIndex: number) {
    if (task.isTaskComplete)
      return "bg-success";

    // Is Task Late?
    let reminderDate1 = new Date(task.reminderDate);
    let now = new Date();

    // If the task is not completed and the reminder date has already passed
    if (!task.isTaskComplete && now > reminderDate1)
      return "bg-danger";

    // Is Task For Today?
    let reminderDate = new Date(task.reminderDate).setHours(0, 0, 0, 0);
    let todayDate = new Date().setHours(0, 0, 0, 0);

    let reminderDateTime = new Date(task.reminderDate);
    let todayDateTime = new Date();

    // If the task is not completed and the reminder date is set for today (but time has not yet passed)
    if (!task.isTaskComplete && (todayDate === reminderDate && todayDateTime <= reminderDateTime))
      return "bg-warning";

    return "";
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
