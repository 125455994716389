import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninComponent } from "./signin/signin.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BlankLayoutComponent } from "../../components/common/layouts/blankLayout.component";
import { AuthGuard } from "./auth-guard.service";

const authRoutes: Routes = [
    {
        path: '', component: BlankLayoutComponent,
        children: [
            { path: 'login', component: SigninComponent },
            { path: 'forgotPassword', component: ForgotPasswordComponent },
            { path: 'resetPassword', component: ResetPasswordComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(authRoutes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }