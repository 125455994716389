import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";

import { ComplaintTypesRoutingModule } from "./complaint-types-routing.module";

import { SharedModule } from "../../shared/shared.module";

import { ModalModule } from 'ngx-bootstrap/modal';

import { ComplaintTypesComponent } from './complaint-types.component';
import { ComplaintTypeListComponent } from './complaint-type-list/complaint-type-list.component';
import { ComplaintTypeManageComponent } from './complaint-type-manage/complaint-type-manage.component';

@NgModule({
  declarations: [
    ComplaintTypesComponent,
    ComplaintTypeListComponent,
    ComplaintTypeManageComponent
  ],
  imports: [
    SharedModule,
    ComplaintTypesRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ]
})
export class ComplaintTypesModule { }
