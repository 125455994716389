import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';

import { CoreModule } from "./views/core/core.module";
import { AccountsModule } from "./views/accounts/accounts.module";
import { SharedModule } from "./views/shared/shared.module";
import { LayoutsModule } from "./components/common/layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./views/auth/auth.module";
import { ErrorsModule } from "./views/errors/errors.module";
import { HomeModule } from "./views/home/home.module";
import { ContactsModule } from "./views/contacts/contacts.module";
import { TasksModule } from "./views/tasks/tasks.module";
import { DashboardModule } from "./views/dashboard/dashboard.module";
import { ReportsModule } from "./views/reports/reports.module";
import { OpportunitiesModule } from "./views/opportunities/opportunities.module";
import { MessageCentreModule } from './views/message-centre/message-centre.module';
import { MailchimpModule } from './views/mailchimp/mailchimp.module';
import { LeadDeskModule } from './views/lead-desk/lead-desk.module';
import { AppComponent } from './app.component';

import { CookieModule } from 'ngx-cookie';
import { OrganizationsModule } from './views/organizations/organizations.module';
import { IssuesModule } from "./views/issues/issues.module";
import { ComplaintsModule } from "./views/complaints/complaints.module";
import { SilentComponent } from './shared/authentication/silent/silent.component';
import { UnauthorizedComponent } from './shared/authentication/unauthorized';
import { AuthComponent } from './shared/authentication';
import { AuthService } from './views/auth/auth.service';
import { CrmRoutingModule } from './crm-routing.module';
import { InterceptorsModule } from './shared/interceptors/interceptors.module';
import { HttpClientModule } from '@angular/common/http';
import { ProfileService } from './shared/profile/profile.service';
import { ApiService } from './shared/api.service';

import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { DeveloperModeHelper, DeveloperModeService, DeveloperConstants } from './views/shared/developer.mode.service';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ExternalUrlDirective } from './views/shared/directives/external-redirect-directive';
import { CompanyDetailsModule } from './views/company-details/company-details.module';

import { ApolloModule } from 'apollo-angular';
import { ApolloApplicationModule } from './apollo.application.module';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { EventsModule } from './views/events/events.module';
import { SystemConfigurationService, SystemModeHelper, SystemConfiguration } from './views/shared/system-config.service';

@NgModule({
  declarations: [
    ExternalUrlDirective,
    AuthComponent,
    UnauthorizedComponent,
    SilentComponent,
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CookieModule.forRoot(),
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    LayoutsModule,
    SharedModule,
    CoreModule,
    InterceptorsModule,
    AuthModule,
    NgxPermissionsModule.forRoot(),

    ApolloModule,
    ApolloApplicationModule.forRoot(),
    HomeModule,
    ContactsModule,
    TasksModule,
    AccountsModule,
    DashboardModule,
    ReportsModule,
    OpportunitiesModule,
    MessageCentreModule,
    MailchimpModule,
    LeadDeskModule,
    OrganizationsModule,
    IssuesModule,
    ComplaintsModule,
    CrmRoutingModule,
    CompanyDetailsModule,
    EventsModule,
    ErrorsModule // - Always import last as it handles unknown routes
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "en" },
    ExternalUrlDirective,
    AuthService,
    ProfileService,
    ApiService,
    NgxPermissionsService,
    {
      provide: APP_INITIALIZER,
      useFactory: ( ps: NgxPermissionsService, dev: DeveloperModeHelper ) => function() {
          var permissions = JSON.parse(localStorage.getItem('permissions'));
          if(!permissions) {
            permissions = [];
          }

          if(permissions.find(_=>_ === 'Developer')) {
            dev.isDeveloper.next(true);
            var isDeveloperMode = JSON.parse(localStorage.getItem(DeveloperConstants.IS_DEVELOPER_MODE));
            if(isDeveloperMode) {

             dev.next(isDeveloperMode);
            } else {
              dev.next(false);
            }
          } else {
            localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
          }

          let storageEvent$ = fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(evt=>evt.key === DeveloperConstants.IS_DEVELOPER_MODE),
            map(evt => evt)
          ).subscribe((evt) => {
            dev.next(JSON.parse(evt.newValue));
            console.log(evt);
          })

          return ps.loadPermissions(permissions);
      },
      deps: [NgxPermissionsService, DeveloperModeHelper],
      multi: true
    },

    {
      provide: APP_INITIALIZER,
      useFactory: ( sysConfigService: SystemConfigurationService, systemModeHelper: SystemModeHelper ) => function() {
        let sub$ = sysConfigService.getConfiguration()   
        .subscribe((configuration: SystemConfiguration)  =>{
          
          
          // let storageEvent$ = fromEvent<StorageEvent>(window, 'storage').pipe(
          //   filter(evt=>evt.key === 'system-configuration'),
          //   map(evt => evt)
          // ).subscribe((evt) => {
    
          //   debugger;
          //   systemModeHelper.next(evt.newValue);
          //   console.log(evt);
    
          // })
    
          
          localStorage.setItem("system-configuration", configuration.keyConfiguration);
          systemModeHelper.next(configuration.keyConfiguration);
        });
    
      },
      deps: [SystemConfigurationService, SystemModeHelper],
      multi: true
    },
    DeveloperModeHelper,
    DeveloperModeService,
    SystemConfigurationService,
    SystemModeHelper,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
