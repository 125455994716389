import { ValidatorFn, FormControl } from '@angular/forms';

export function valueExistsValidator(arrayOfValues: string[]): ValidatorFn {
    return (control: FormControl): { [s: string]: boolean } => {
        arrayOfValues = arrayOfValues.filter(v => v != null);
        if (control.value != null) {
            if (arrayOfValues.find(v => v.toLowerCase() === (String)(control.value).toLowerCase()))
                return { 'valueExists': true };
        }
        return null;
    };
}