import { CrmUser } from "./user.model";
import { Contact } from "./contact.model";
import { ComplaintType } from "./complaint-type.model";
import { ComplaintStatus } from "./complaint-status.model";

export class Complaint {
    constructor(
        public id: number = 0,
        public code : string = null,
        public reportedDate : string = null,
        public description : string = null,
        public complaintType: ComplaintType = new ComplaintType(),
        public complaintTypeId : number = null,
        public complaintStatus: ComplaintStatus = new ComplaintStatus(),
        public complaintStatusId : number = null,
        public assignedContactId: number = null,
        public assignedContact: Contact = new Contact(),
        public assignedUserAccountId: number = null,
        public assignedUser: CrmUser = new CrmUser(),
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null
    ) { }
}