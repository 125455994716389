import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuard } from "../../auth/role-guard.service";

import { BasicLayoutComponent } from "../../../components/common/layouts/basicLayout.component";
import { OpportunityQuotesComponent } from './opportunity-quotes.component';
import { OpportunityQuoteManageComponent } from './opportunity-quote-manage/opportunity-quote-manage.component';
import { OpportunityQuoteSendEmailManageComponent } from './opportunity-quote-send-email/opportunity-quote-send-email.component';

const quoteRoutes: Routes = [
    {
        path: 'opportunities',
        component: BasicLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'quote', component: OpportunityQuotesComponent,
                children: [
                    {
                        path: ':id/edit', component: OpportunityQuoteManageComponent,// canActivate: [RoleGuard],
                        children:[
                            {
                                path: 'send-email', component: OpportunityQuoteSendEmailManageComponent,// canActivate: [RoleGuard]
                            },
                        ]
                    },
                    {
                        path: ':opportunityId/new', component: OpportunityQuoteManageComponent,// canActivate: [RoleGuard]
                    },
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(quoteRoutes)],
    exports: [RouterModule]
})
export class OpportunityQuotesRoutingModule { }
