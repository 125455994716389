import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpportunityFilesComponent } from './opportunity-files.component';
import { OpportunityFileListComponent } from './opportunity-file-list/opportunity-file-list.component';
import { OpportunityFileManageComponent } from './opportunity-file-manage/opportunity-file-manage.component';

import { SharedModule } from "../../shared/shared.module";
import { OpportunityFilesRoutingModule } from "./opportunity-files-routing.module";

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ModalModule } from "ngx-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    OpportunityFilesComponent,
    OpportunityFileListComponent,
    OpportunityFileManageComponent
  ],
  imports: [
    SharedModule,
    ModalModule.forRoot(),
    OpportunityFilesRoutingModule,
    ReactiveFormsModule,
    DropzoneModule
  ]
})
export class OpportunityFilesModule { }
